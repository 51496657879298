import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container.tsx';
import SideBar from '@src/components/SideBar.tsx';
import Cookies from 'js-cookie';
import { useScrollToTop } from '@src/util/hooks.ts';
import { useEffect, useState } from 'react';
import LandingHeader from '@src/components/LandingHeader.tsx';

const ServiceTerms = () => {
  useScrollToTop();
  const hasToken = Cookies.get('accessToken') || Cookies.get('refreshToken');
  const [isFromChomchomTax, setIsFromChomchomTax] = useState<boolean>(false);

  useEffect(() => {
    setIsFromChomchomTax(
      /^https:\/\/.*\.chomchomtax\.co\.kr/.test(document.referrer),
    );
  }, []);

  return (
    <Container
      header={
        isFromChomchomTax ? (
          <LandingHeader />
        ) : (
          <DetailHeader title={'서비스 이용약관'} />
        )
      }
      sideBar={hasToken && <SideBar />}
    >
      <div className={'term-container'}>
        {isFromChomchomTax && (
          <>
            <h1
              className={'text-xl'}
              style={{ alignSelf: 'center', marginTop: '30px' }}
            >
              서비스 이용약관
            </h1>
            <br />
          </>
        )}
        <h1 className={'text-xl'} style={{ alignSelf: 'center' }}>
          제1절 총칙
        </h1>
        <h1 className={'text-md'}>제1조 목적</h1>
        <p className={'text-regular'}>
          이 약관은 정율 세무회계(이하 ‘회사’)가 고객(이하 ‘이용자’)에게
          제공하는 촘촘택스 서비스(이하 ‘서비스’)의 이용에 관한 기본적인 사항을
          정함으로써 상호간에 권리∙의무 관계를 명확히 하는 것을 목적으로 합니다.
        </p>
        <h1 className={'text-md'}>제2조 용어의 정의</h1>
        <p className={'text-regular'}>
          본 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br />
          1) ‘이용자’는 서비스를 통해 회원가입을 하고, 이 약관에 따라 회사와
          서비스 이용계약(이하 ‘이용계약’)을 체결하는 고객을 말합니다. 회사는
          이용자에게 이 약관에 따라서 서비스를 제공하되, 보다 구체적이고
          개별화된 권리∙의무 및 책임사항을 정하고 그에 따른 서비스를 제공하기
          위해 필요시 이 약관을 전제로 한 별도 위임계약 등을 이용자와 체결하고
          서비스를 제공할 수 있습니다. <br />
          <br />
          2) ‘서비스’는 모바일 및 PC 등 온라인 채널 등을 통해 회사가 이용자에게
          제공하는 용역의 집합을 말합니다. 구체적으로 기간별 손익 리포트, 증명서
          관리, 영수증 관리, 세금납부 현황 조회, 예상 세금 산출 등 이용자에게
          유의미한 재무 정보 및 이용자가 필요로 자료 등을 모바일 및 PC 등 온라인
          채널을 통해 이용자가 이용할 수 있도록 회사가 제공하는 서비스를
          의미합니다. 회사는 본 서비스의 명칭을 변경할 수 있습니다.
        </p>
        <h1 className={'text-md'}>제3조 약관의 효력</h1>
        <p className={'text-regular'}>
          1) 이용자가 서비스를 이용하기 위해 회사가 정한 소정의 절차를 거치면 본
          약관에 동의하는 것으로 간주합니다.
          <br />
          <br />
          2) 본 약관은 이용자에게 서비스 화면을 통해 공지하거나 기타의 방법으로
          이용자에게 공지합니다.
          <br />
          <br />
          3) 회사는 새로운 서비스의 적용, 보안체계의 향상 및 유지, 정부 등
          공공기관에 의한 시정명령 이행, 기타 회사의 필요에 의하여 약관을
          변경하여야 할 필요가 있다고 판단될 경우 관계법령을 위반 하지 않는 범위
          내에서 본 약관을 개정할 수 있으며, 그 내용은 서비스 제공자의
          웹사이트에 공지합니다.
          <br />
          <br />
          4) 이용자는 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에
          동의하지 않을 경우 서비스 이용이 중단될 수 있습니다. 이용자가 변경된
          약관의 시행일 7일 후까지 거부의 의사표시를 하지 않는 경우 이용자는
          변경된 약관에 동의한 것으로 간주됩니다. 회사가 본 약관을 변경할
          경우에는 적용일자, 변경사유를 명시하여 적용일자 7일 전부터 공지하거나,
          이용자에게 개별 통지합니다. 이용자에게 불리한 약관의 변경인 경우에는
          그 적용일자 30일 전부터 공지하거나, 이용자에게 개별 통지합니다.
          <br />
          <br />
          5) 회사는 필요한 경우 개별 서비스에 적용될 사항을 위해 운영 정책 등을
          정하여 운영할 수 있습니다. 운영 정책 등의 내용은 서비스 내 또는 이메일
          등을 통해 이용자에게 공지합니다. 본 약관 및 운영 정책 등에 규정되지
          않은 사항에 대해서는 관련법령, 상관례에 따릅니다.
        </p>
        <h1 className={'text-md'}>제4조 이용계약의 체결</h1>
        <p className={'text-regular'}>
          1) 이용계약은 이용자가 되려는 자(이하 ‘가입신청자’)가 이 약관의 내용에
          동의하고 회사가 이를 승낙한 사실을 표시함으로써 체결됩니다.
          <br />
          <br />
          2) 회사의 승낙은 가입신청자가 등록한 전자우편으로 발송, 회사 카카오톡
          채널로 안내 또는 기타 회사가 정하는 방법으로 통지하는 방식으로
          표시하며, 이 표시가 가입신청자에게 도달한 시점에 계약이 성립된 것으로
          봅니다.
          <br />
          <br />
          3) 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 가입신청을
          유보하거나 거부할 수 있으며, 이 때 회사는 각 호의 내용을 판단하기 위해
          가입신청자에게 적절한 자료를 요청할 수 있습니다.
          <br />
        </p>
        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>
            가입신청자가 서비스를 이용할 수 있는 정상적인 사업자가 아닌 경우
          </li>
          <li>
            가입신청자가 가입신청에 필요한 정보를 제공하지 않거나 제공한 정보가
            거짓인 경우
          </li>
          <li>
            가입신청자가 서비스의 정상적인 운영을 저해할 수 있는 사업자로 판단될
            경우
          </li>
        </ul>

        <h1 className={'text-xl'} style={{ alignSelf: 'center' }}>
          제2절 계약의 내용
        </h1>
        <h1 className={'text-md'}>제5조 서비스의 제공 및 중단</h1>
        <p className={'text-regular'}>
          1) 회사는 특별한 사정이 없는 한 이용자가 언제든지 서비스를 이용할 수
          있도록 운영합니다.
          <br />
          <br />
          2) 전항에도 불구하고 회사는 컴퓨터 등 정보통신설비의 보수점검, 노후 및
          고장에 의한 교체, 통신망의 두절 또는 기타 운영상 상당한 이유가 있는
          경우 서비스 제공을 일시적으로 중단할 수 있습니다. 서비스를 중단할 경우
          회사는 이용자에게 서비스의 초기 화면 또는 회사의 웹사이트 등의 다양한
          방식을 통해 사전 통지 또는 공지함을 원칙으로 합니다. 단, 회사가 통제할
          수 없는 사유로 인한 서비스 중단 (회사 시스템 관리자의 고의/과실이 없는
          장애 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
        </p>
        <h1 className={'text-md'}>제6조 이용자에 대한 통지 등</h1>
        <p className={'text-regular'}>
          1) 회사는 이용자가 등록한 이메일 주소 또는 SMS 등을 통해 이용자에 대한
          통지를 할 수 있습니다. 다만, 회사가 불특정 다수 이용자들에 대한 통지를
          하는 경우 서비스 내에서 게시하는 방법 등을 활용할 수 있습니다.
          <br />
          <br />
          2) 회사는 서비스 관련 각종 정보를 본 조에서 정한 방법으로 이용자에게
          제공할 수 있습니다. 회사는 회사 또는 제휴사의 광고를 홈페이지 등에
          게시하거나 이용자의 동의를 얻어 본 조에서 정한 방법으로 이용자에게
          제공할 수 있습니다.
          <br />
          <br />
          3) 이용자의 연락처 미기재, 변경 후 미수정 등으로 인해 개별 통지가
          어려운 경우 본 조에서 정한 방법으로 공지를 함으로써 개별 통지를 한
          것으로 봅니다.
        </p>
        <h1 className={'text-md'}>제7조 이용료의 결제 등</h1>
        <p className={'text-regular'}>
          1) 회사는 이용료가 부과되는 유료 서비스 제공 시 이용자에게 서비스
          이용에 대한 대가로 서비스 이용료를 청구할 수 있습니다.
          <br />
          <br />
          2) 회사가 제공하는 유료 서비스의 상세 정보, 개별 이용료의 상세 내역과
          결제 방법 등은 회사가 개별 운영 정책 등을 통해 이용자에게 서비스
          내에서 상세하게 고지 및 안내합니다.
          <br />
          <br />
          3) 이용료는 카드 결제, 계좌이체 등의 방법 중 회사가 구현하여 제공하는
          방법을 통해 결제함을 원칙으로 합니다. 회사는 직접 또는 제3자를 통해
          이용자가 이용료를 결제할 수 있는 적법한 방법을 제공할 수 있습니다.
        </p>
        <h1 className={'text-md'}>제8조 개인정보의 보호</h1>
        <p className={'text-regular'}>
          1) 회사는 관계법령이 정하는 바에 따라 이용자의 개인정보를 보호하기
          위해 노력합니다.
          <br />
          <br />
          2) 이용자의 개인정보보호 및 이용에 대해서는 관련법령 및 회사의
          개인정보처리방침이 적용됩니다.
        </p>
        <h1 className={'text-md'}>제9조 회사의 의무</h1>
        <p className={'text-regular'}>
          1) 회사는 특별한 사정이 없는 한 안정적인 서비스를 제공하기 위해 최선의
          노력을 다하며, 이 약관 및 관련 법령의 내용을 준수해야 합니다.
          <br />
          <br />
          2) 회사는 전항의 의무를 다하기 위하여 최적의 인력 및 설비 등을
          유지하기 위하여 최선의 노력을 다하여야 합니다.
        </p>
        <h1 className={'text-md'}>
          제10조 이용자의 아이디 및 비밀번호에 대한 의무
        </h1>
        <p className={'text-regular'}>
          1) 회사는 관계법령, 개인정보보호정책에 의해서 그 책임을 지는 경우를
          제외하고, 이용자 자신의 ID와 비밀번호에 관한 관리책임은 각 이용자에게
          있습니다.
          <br />
          <br />
          2) 이용자는 이용자 자신의 이메일 또는 카카오 계정 인증을 통해 서비스
          회원가입, 서비스 이용 및 비밀번호 변경을 하므로, 이용자는 이메일 주소,
          카카오 계정 및 비밀번호 관리에 대한 의무와 책임이 있습니다.
          <br />
          <br />
          3) 이메일 주소, 카카오 계정 및 비밀번호 관리 소홀, 부정 사용에 의하여
          발생하는 모든 결과에 대한 책임은 이용자 본인에게 있습니다. 이용자는
          자신의 이메일 주소, 카카오 계정 및 비밀번호를 제3자에게 이용하게
          해서는 안 됩니다.
          <br />
          <br />
          4) 이용자는 자신의 이메일 주소, 카카오 계정 및 비밀번호를 제3자가
          이용하고, 이를 활용해 회사의 서비스를 사용하고 있음을 인지하는
          경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야
          합니다. 이용자는 가입시 회사에 제공한 정보에 변경이 있는 경우, 전화
          또는 전자우편 등 기타의 방법으로 회사에 변경사항을 알려야 합니다.
        </p>
        <h1 className={'text-md'}>제11조 이용자의 의무</h1>
        <p className={'text-regular'}>
          1) 이용자는 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 하며,
          기타 회사의 업무 수행에 지장을 초래하는 행위를 하여서는 안됩니다.
          <br />
          <br />
          2) 이용자는 서비스의 이용을 위하여 필요한 범위 내에서 회사가 요청하는
          자료를 제공하여야 합니다. 이용자는 본 약관 뿐만 아니라 회사가 서비스와
          관련하여 통지하는 사항을 준수하여야 합니다. 이용자는 회사가 서비스의
          일환으로 필요하다고 인정되는 정보를 전자우편, 서신우편, 기타 방법 및
          광고의 형태로 제공 받는 것에 동의합니다.
          <br />
          <br />
          3) 이용자는 다음 각 호의 행위를 하여서는 안되며, 이에 따른 결과로
          인하여 발생한 결과에 대해서는 회사가 책임지지 않습니다.
        </p>
        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>신청 및 등록 또는 변경 시 허위 내용의 등록</li>
          <li>타인의 정보 도용</li>
          <li>회사 또는 제3자의 지식재산권에 대한 침해</li>
          <li>회사 또는 제3자의 명예 훼손 및 업무방해</li>
          <li>
            음란한 부호, 문자, 음향, 화상, 영상, 기타 공서양속에 반하는 정보의
            공개 또는 게시
          </li>
          <li>회사의 동의 없는 영리 목적의 서비스 이용</li>
          <li>
            타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
          </li>
          <li>다른 이용자의 개인정보를 수집, 저장, 공개하는 행위</li>
          <li>
            회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사,
            수정, 배포, 판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을
            허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의 추출을
            시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위
          </li>
          <li>
            회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는
            유통시키거나 상업적으로 이용하는 행위
          </li>
          <li>
            기타 현행 법령, 본 약관 및 운영정책 등 회사가 제공하는 서비스 관련
            세부지침을 위반하는 부당하고 위법한 행위
          </li>
        </ul>
        <p className={'text-regular'}>
          4) 이용자가 관련 법령, 회사의 모든 약관 또는 정책을 준수하지 않을
          경우, 회사는 이용자의 위반행위 등을 조사할 수 있고, 서비스 전체 또는
          일부 개별 서비스의 이용을 잠시 또는 계속하여 중단하거나 제한을 둘 수도
          있습니다.
        </p>
        <h1 className={'text-xl'} style={{ alignSelf: 'center' }}>
          제3절 서비스 해지 등
        </h1>
        <h1 className={'text-md'}>제12조 서비스 해지 및 자격 상실</h1>
        <p className={'text-regular'}>
          1) 회원이 서비스 이용을 해지하고자 할 때는 회원 본인이 전화나
          전자우편을 통해서 회사에 해지 신청을 하여야 합니다. 해지 신청의 처리
          여부는 회원이 서비스 로그인을 시도하였을 때 로그인이 되지 않음을
          확인함으로써 알 수 있습니다.
          <br />
          <br />
          2) 이용자가 다음 각 호의 사유에 해당하는 경우, 회사는 이용자의 회원
          자격을 상실시킬 수 있으며 사전 통보 없이 서비스의 제공을 중지할 수
          있습니다.
        </p>
        <ul
          style={{
            listStyleType: 'disc',
          }}
        >
          <li>타인의 명의를 도용하거나 허위 사실로 가입 신청이 확인된 경우</li>
          <li>
            서비스를 이용하여 법령을 위반하거나 미풍양속에 반하는 행위를 하는
            경우
          </li>
          <li>
            다른 이용자의 서비스 이용을 방해하거나 그 정보를 도용하는 등
            전자거래질서를 위협하는 경우
          </li>
          <li>
            회사가 제공하는 방식으로 서비스에 접속하지 않고 비정상적인 방법으로
            서비스를 이용하거나 서비스 관련 시스템에 접근하는 행위
          </li>
          <li>기타 불법적이거나 부당한 방법으로 회사의 업무를 방해하는 행위</li>
          <li>기타 정상적인 서비스 운영에 방해가 된다고 회사가 판단한 경우</li>
          <li>본 약관에서 명시한 이용자의 의무를 위반한 경우</li>
        </ul>
        <h1 className={'text-md'}>제13조 면책조항</h1>
        <p className={'text-regular'}>
          1) 회사는 국가비상사태, 천재지변, 기간통신사업자의 서비스제공 중단,
          전력공급 중단, 해커의 침입 등 기타 이와 유사한 사정으로 인한 서비스
          시스템의 작동불능 등 불가항력으로 인하여 서비스를 제공할 수 없는
          경우에는 서비스 제공에 관한 책임이 면책됩니다.
          <br />
          <br />
          2) 이용자가 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나
          서비스 자료에 대한 취사선택 또는 이용으로 발생하는 불이익에 대하여
          회사는 책임을 지지 아니합니다.
          <br />
          <br />
          3) 회사는 이용자들의 귀책사유로 인하여 발생하는 손해, 서비스 이용의
          장애에 대하여 책임을 지지 아니 합니다.
          <br />
          <br />
          4) 회사는 이용자 상호간, 이용자와 제3자 상호간에 서비스를 매개로
          발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할
          책임이 없습니다.
        </p>
        <h1 className={'text-md'}>제14조 지식재산권 보호</h1>
        <p className={'text-regular'}>
          1) 본 약관을 통해 회사는 이용자에게 서비스에 대한 사용권만 부여하며,
          회사가 작성하여 제공하는 콘텐츠에 관한 소유권 및 지식재산권은 회사에
          귀속됩니다. 회사에서 제공하는 콘텐츠라 함은 서비스에서 제공하는 모든
          문자, 파일, 그래픽, 소프트웨어를 의미합니다.
          <br />
          <br />
          2) 이용자는 회사가 제공하는 콘텐츠를 회사의 사전 동의 없이 대여, 배포,
          판매 행위를 하거나 제3자에게 이용하게 하여서는 안됩니다.
          <br />
          <br />
          3) 이용자가 서비스에 게재한 게시물, 자료에 관한 권리와 책임은 게시한
          이용자에게 있습니다.
          <br />
          <br />
          4) 회사는 이용자가 게재한 게시물, 자료에 대하여 서비스 내의 게재권을
          가지며, 게재한 이용자의 동의 없이 이를 영리적인 목적으로 사용하지
          않습니다.
          <br />
          <br />
          5) 회사는 제12조 의무를 위반하는 내용을 담고 있는 게시물에 대하여 수정
          또는 삭제할 권한을 갖습니다.
        </p>
        <h1 className={'text-md'}>제15조 분쟁해결</h1>
        <p className={'text-regular'}>
          1) 회사와 이용자는 서비스에 관련하여 분쟁이 발생하는 경우 각 당사자는
          원만한 해결을 위하여 서로 협력합니다.
          <br />
          <br />
          2) 발생한 분쟁이 원만하게 해결되지 아니하고 소송이 제기된 경우,
          대한민국법을 준거법으로 하며, 회사의 본사 소재지를 관할하는 법원을
          관할법원으로 합니다.
          <br />
          <br />
          [부칙]
          <br />
          <br />이 서비스 이용약관은 2024년 06월 03일부터 적용됩니다.
        </p>
      </div>
    </Container>
  );
};

export default ServiceTerms;
