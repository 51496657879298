import '@src/scss/main.scss';
import Input from '@src/components/Input.tsx';
import React from 'react';
import Button from '@src/components/Button.tsx';
import { useMediaQuery } from 'react-responsive';
import Label from '@src/components/Label';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container.tsx';

interface FindIdProps {}

export const FindIdComplete: React.FC<FindIdProps> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const handleNavigate = (email: string | null) => {
    if (email != null) {
      Cookies.set('email', email, { expires: 7 });
    }
    navigate(`/login/email`, { replace: true });
  };
  const content = (
    <div className="email-container-wrapper">
      <div className="description-container">
        <h2 className="email-desktop-title">아이디 찾기</h2>
        <span>아이디 찾기가 완료되었습니다.</span>
      </div>
      <div className="input-container">
        <div className="input-label-container">
          <Label text="아이디" required={true} />
          <div className="find-email-input-wrapper">
            <Input
              isLoading={false}
              readOnly={true}
              value={email || ''}
              customClassName={'email-input-custom'}
              maxLength={3}
              inputMode={'numeric'}
            />
          </div>
        </div>
        <>
          <div style={{ height: '16px' }}></div>
          <Button text="확인" onClick={() => handleNavigate(email)} />
        </>
      </div>
    </div>
  );

  return (
    <Container
      header={isMobile ? <DetailHeader title={'아이디 찾기'} /> : <></>}
    >
      {content}
    </Container>
  );
};

export default FindIdComplete;
