import '@src/scss/main.scss';
import { useNavigate } from 'react-router-dom';
import Label from '@src/components/Label.tsx';
import Input from '@src/components/Input.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';

import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMutation } from '@tanstack/react-query';
import {
  IApiError,
  IApiPOSTSuccess,
  IErrorData,
} from '@src/util/interfaces.ts';
import {
  companyRegisterNumberRegex,
  logoutLogic,
  numericRegex,
} from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import { useUserStore } from '@src/util/store.ts';
import Button from '@src/components/Button.tsx';
import { API_URLS } from '@src/util/constants.ts';
import RegisterCompanyInfoDrawer from '@src/pages/auth/RegisterCompanyInfoDrawer.tsx';
import CustomErrorDrawer from '@src/components/CustomErrorDrawer.tsx';
import RegisterCompanyConfirmDrawer from '@src/pages/auth/RegisterCompanyConfirmDrawer.tsx';

interface RegisterCompanyProps {}

const ValidateCompanyBizNoForm = z.object({
  registrationNumber1: z.string().regex(numericRegex).min(3).max(3),
  registrationNumber2: z.string().regex(numericRegex).min(2).max(2),
  registrationNumber3: z.string().regex(numericRegex).min(5).max(5),
});

const ValidateCompanyBizNoRequest = z.object({
  resident_number: z
    .string()
    .regex(companyRegisterNumberRegex, '사업자 등록번호가 유효하지 않습니다.'),
});

const RegisterCompanyRequest = z.object({
  name: z.string().min(1),
  tax_type: z.enum(['TAXABLE', 'EXEMPT']),
  resident_number: z
    .string()
    .regex(companyRegisterNumberRegex, '사업자 등록번호가 유효하지 않습니다.'),
});

type TValidateCompanyBizNoForm = z.infer<typeof ValidateCompanyBizNoForm>;
export type TValidateCompanyBizNoRequest = z.infer<
  typeof ValidateCompanyBizNoRequest
>;
export type TValidateCompanyBizNoResponse = {
  data: {
    status: string;
    type: string;
    name: string;
  };
};
export type TRegisterCompanyRequest = z.infer<typeof RegisterCompanyRequest>;

const RegisterCompany: React.FC<RegisterCompanyProps> = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<TValidateCompanyBizNoForm>({
    resolver: zodResolver(ValidateCompanyBizNoForm),
    mode: 'onSubmit',
  });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [companyInfo, setCompanyInfo] =
    useState<TRegisterCompanyRequest | null>(null);
  const [errorDrawerOpen, setErrorDrawerOpen] = useState(false);
  const [errorData, setErrorData] = useState<IErrorData | null>(null);
  const [confirmDrawerOpen, setConfirmDrawerOpen] = useState(false);

  useEffect(() => {
    if (companyInfo) {
      setDrawerOpen(true);
    }
  }, [companyInfo]);

  useEffect(() => {
    if (errorData) {
      setErrorDrawerOpen(true);
    }
  }, [errorData]);

  const { mutate: validateCompanyBizNo, isPending } = useMutation<
    TValidateCompanyBizNoResponse,
    IApiError,
    TValidateCompanyBizNoRequest
  >({
    mutationFn: (formData) => {
      return api.post(API_URLS.VERIFY_BIZ_NO, formData);
    },
  });

  const { mutate: registerCompany, isPending: isLoading } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TRegisterCompanyRequest
  >({
    mutationFn: (formData) => {
      return api.post('/v1/business/company', formData);
    },
  });

  const onSubmit: SubmitHandler<TValidateCompanyBizNoForm> = (data) => {
    const resident_number = `${data.registrationNumber1}-${data.registrationNumber2}-${data.registrationNumber3}`;
    validateCompanyBizNo(
      { resident_number },
      {
        onSuccess: (res) => {
          const {
            data: { status, name, type },
          } = res;

          if (status === '정상') {
            setCompanyInfo({
              name: name,
              tax_type: type.includes('면세') ? 'EXEMPT' : 'TAXABLE',
              resident_number: resident_number,
            });
          } else {
            const message =
              status === '미등록사업자'
                ? '미등록 사업자 입니다.'
                : `${status}된 사업자등록번호 입니다.`;

            setErrorData({
              title: '입력오류',
              message: message,
            });
          }
        },
        onError: (err) => {
          setErrorData(err.response.data);
        },
      },
    );
  };
  const handleRegisterCompany = () => {
    setDrawerOpen(false);
    if (companyInfo) {
      registerCompany(companyInfo, {
        onSuccess: () => {
          setConfirmDrawerOpen(true);
        },
        onError: (err) => {
          console.log(err);
          setErrorData(err.response.data);
        },
      });
    }
  };

  const user = useUserStore((state) => state.user);

  return (
    <>
      <Container
        header={
          <DetailHeader
            title={'사업장 추가'}
            backButton={() => {
              if (user?.selectedCompany === null) {
                logoutLogic(navigate);
              } else {
                navigate(-1);
              }
            }}
          />
        }
        modal={
          <>
            <RegisterCompanyInfoDrawer
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              companyInfo={companyInfo}
              registerCompany={handleRegisterCompany}
              isLoading={isLoading}
            />
            <CustomErrorDrawer
              open={errorDrawerOpen}
              setOpen={setErrorDrawerOpen}
              errorData={errorData}
            />
            <RegisterCompanyConfirmDrawer
              open={confirmDrawerOpen}
              setOpen={setConfirmDrawerOpen}
              onOk={() => {
                if (user?.selectedCompany !== null) {
                  navigate(-1);
                } else {
                  logoutLogic(navigate);
                }
              }}
            />
          </>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="register-email-container">
            <div className="register-email-form">
              <div className="email-input-container">
                <div className="input-label-container">
                  <Label text="사업자등록번호" required={true} />
                  <div className="email-verify-container">
                    <div className="one-third-flex-item">
                      <Input
                        register={register('registrationNumber1')}
                        isLoading={isPending}
                        customClassName={'input-full-width'}
                        maxLength={3}
                        inputMode={'numeric'}
                      />
                    </div>
                    <p>-</p>
                    <div className="one-third-flex-item">
                      <Input
                        register={register('registrationNumber2')}
                        isLoading={isPending}
                        customClassName={'input-full-width'}
                        maxLength={2}
                        inputMode={'numeric'}
                      />
                    </div>
                    <p>-</p>
                    <div className="one-third-flex-item">
                      <Input
                        register={register('registrationNumber3')}
                        isLoading={isPending}
                        customClassName={'input-full-width'}
                        maxLength={5}
                        inputMode={'numeric'}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="input-label-container"
                  style={{ marginTop: '4px' }}
                >
                  <Button text={'조회'} type={'submit'} isLoading={isPending} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};

export default RegisterCompany;
