import React, { useCallback, useState } from 'react';
import Container from '@src/layouts/Container';
import {
  LABOR_MENU,
  LABOR_MENU_LIST,
  LaborQuestion,
  URLS,
} from '@src/util/constants.ts';
import { useNavigate } from 'react-router-dom';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import { useMediaQuery } from 'react-responsive';
import SideBar from '@src/components/SideBar';

interface LaborProps {}

const PossibleQuestion: React.FC<LaborProps> = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 818px)' });
  // const [user] = useUserStore((state) => [state.user]);
  // const company_id = user?.selectedCompany?.id || 0;
  // const [setQuestion] = createQuestionStore(company_id)(state => [state.setQuestion]);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );

  const [radioValue, setRadioValue] = useState<LaborQuestion>(
    LaborQuestion.청년일자리도약장려금,
  );

  const handleNextClick = useCallback(() => {
    navigate(`${URLS.LABOR}/${radioValue}/1`);
  }, [radioValue]);

  // const handleRefreshClick = useCallback(() => {
  //   setQuestion({ youth_amount: undefined, senior_amount: undefined, baby_amount: undefined, current_page: undefined })
  //   navigate(`${URLS.LABOR}`)
  // }, [])

  return (
    <Container
      sideBar={<SideBar />}
      header={
        <DetailHeader
          title={'노무지원금 신청'}
          custom={
            <button
              className={'labor-custom'}
              onClick={() => navigate(`${URLS.LABOR}`)}
            >
              처음으로
            </button>
          }
        />
      }
      headerTab={
        isMobile ? (
          ''
        ) : (
          <HeaderTabs
            menus={LABOR_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
    >
      <div className="labor-request-description-container">
        <h2 className="question-title">
          귀 사업장은 노무지원금 검토 <br />
          가능 사업장입니다.
        </h2>
        <p className={'question-text'}>
          정확한 확인을 위하여 아래 노무지원금 중 <br />
          하나를 선택 후 질문에 응답해 주세요.
        </p>
        <div className={'question-radio-container'}>
          <div className={'question-radio-wrapper'}>
            <input
              className={'pointer'}
              type="radio"
              name="question-group"
              id="option1"
              value={LaborQuestion.청년일자리도약장려금}
              checked={radioValue === LaborQuestion.청년일자리도약장려금}
              onChange={(e) => setRadioValue(e.target.value as LaborQuestion)}
            />
            <label
              htmlFor="option1"
              className={
                radioValue === LaborQuestion.청년일자리도약장려금
                  ? 'checked'
                  : ''
              }
            >
              청년일자리도약장려금
            </label>
          </div>
          <div className={'question-radio-wrapper'}>
            <input
              className={'pointer'}
              type="radio"
              name="question-group"
              id="option2"
              value={LaborQuestion.시니어인턴지원금}
              checked={radioValue === LaborQuestion.시니어인턴지원금}
              onChange={(e) => setRadioValue(e.target.value as LaborQuestion)}
            />
            <label
              htmlFor="option2"
              className={
                radioValue === LaborQuestion.시니어인턴지원금 ? 'checked' : ''
              }
            >
              시니어인턴 지원금
            </label>
          </div>
          <div className={'question-radio-wrapper'}>
            <input
              className={'pointer'}
              type="radio"
              name="question-group"
              id="option3"
              value={LaborQuestion.육아휴직지원금}
              checked={radioValue === LaborQuestion.육아휴직지원금}
              onChange={(e) => setRadioValue(e.target.value as LaborQuestion)}
            />
            <label
              htmlFor="option3"
              className={
                radioValue === LaborQuestion.육아휴직지원금 ? 'checked' : ''
              }
            >
              육아휴직 지원금
            </label>
          </div>
        </div>
      </div>
      <div className={'button-bottom-default-wrapper'}>
        <div className={'button-size-wrapper'}>
          {/*<button className={"button-left-default"} onClick={handleReWriteClick}>*/}
          {/*  접수 중단*/}
          {/*</button>*/}
          <button className={'button-right-default'} onClick={handleNextClick}>
            다음
          </button>
        </div>
      </div>
    </Container>
  );
};
export default PossibleQuestion;
