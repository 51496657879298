import React from 'react';
import {
  default as ReactSelect,
  StylesConfig,
  components,
  DropdownIndicatorProps,
  ActionMeta,
} from 'react-select';
import chevronDown from '/src/assets/icons/chevron_down.png';
import { useMediaQuery } from 'react-responsive';

export interface OptionValue {
  label: string;
  value: string;
}

interface SelectProps {
  name: string;
  options: OptionValue[];
  onChange: (
    selectedOption: OptionValue | null,
    actionMeta: ActionMeta<OptionValue>,
  ) => void;
  value: OptionValue | null;
  placeholder?: string;
  size?: 'sm' | 'lg';
  isFilterSearch?: boolean;
  label?: string;
}

const Select: React.FC<SelectProps> = ({
  name,
  options,
  onChange,
  value,
  placeholder = '선택',
  size = 'lg',
  isFilterSearch = false,
  label,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const customStyles: StylesConfig<OptionValue> = {
    container: (provided) => ({
      ...provided,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '22px',
      letterSpacing: 0,
      textAlign: 'left',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: isFilterSearch ? '0' : '3px',
      borderTopLeftRadius: isFilterSearch ? '4px' : '',
      borderBottomLeftRadius: isFilterSearch ? '4px' : '',
      borderRight: isFilterSearch ? '0' : '',
      border: '1px solid #E5E5E5',
      padding: size === 'sm' ? '4px 4px 4px 10px' : '11px 4px 11px 12px',
      fontSize: size === 'sm' ? '12px' : '14px',
      fontWeight: '400',
      lineHeight: size === 'sm' ? '16px' : '22px',
      letterSpacing: 0,
      textAlign: 'left',
      minHeight: size === 'sm' ? '24px' : '',
      height: size === 'sm' ? '24px' : '',
      whiteSpace: 'nowrap',
    }),
    singleValue: (provided) => ({
      ...provided,
      // fontSize: '16px', // Set your preferred font size here
      fontSize: size === 'sm' ? '12px' : '14px',
      fontWeight: '400',
      lineHeight: size === 'sm' ? '16px' : '22px',
      letterSpacing: 0,
      textAlign: 'left',
      margin: 0,
      padding: '0px',
      whiteSpace: 'nowrap',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px',
      margin: '0px',
      fontSize: size === 'sm' ? '12px' : '',
      lineHeight: size === 'sm' ? '16px' : '',
      marginRight: size === 'sm' ? '4px' : '',
      whiteSpace: 'nowrap',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      fontSize: size === 'sm' ? '12px' : '',
      lineHeight: size === 'sm' ? '16px' : '',
      whiteSpace: 'nowrap',
    }),
    indicatorSeparator: () => ({
      display: 'none',
      fontSize: size === 'sm' ? '12px' : '',
      lineHeight: size === 'sm' ? '16px' : '',
      whiteSpace: 'nowrap',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: '0px',
      fontSize: size === 'sm' ? '12px' : '',
      lineHeight: size === 'sm' ? '16px' : '',
      whiteSpace: 'nowrap',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px',
      fontSize: size === 'sm' ? '12px' : '',
      lineHeight: size === 'sm' ? '16px' : '',
      whiteSpace: 'nowrap',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'auto',
      minWidth: '100%',
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0px',
      fontSize: size === 'sm' ? '12px' : '',
      lineHeight: size === 'sm' ? '16px' : '',
      whiteSpace: 'nowrap',
    }),
  };
  // Custom Dropdown Indicator component
  const DropdownIndicator = (props: DropdownIndicatorProps<OptionValue>) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chevronDown} alt="v" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {label && (
        <label className={'text-sm'} style={{ paddingBottom: '5px' }}>
          {label}
        </label>
      )}
      <ReactSelect<OptionValue>
        name={name}
        options={options}
        onChange={onChange}
        value={value}
        styles={customStyles}
        components={{ DropdownIndicator }}
        placeholder={placeholder}
        isSearchable={!isMobile}
      />
    </div>
  );
};

export default Select;
