import React from 'react';
import {
  REPORT_CATEGORY_TEXT,
  REPORT_TERM,
  REPORT_TERM_TEXT,
} from '@src/util/constants.ts';
import {
  getLastSixDays,
  getLastSixMonths,
  processGraphData,
} from '@src/util/functions.ts';
import { IReportGraph } from '@src/util/interfaces.ts';
import { Moment } from 'moment';
import ReportBarChart from './ReportBarChart.tsx';

interface ReportGraphBarChartProps {
  activeHeaderTab: string;
  activeCardHeaderTab: string;
  today: Moment;
  graphData: IReportGraph;
}

const ReportGraphBarChart: React.FC<ReportGraphBarChartProps> = ({
  activeHeaderTab,
  activeCardHeaderTab,
  today,
  graphData,
}) => {
  return (
    <div className={'tab-chart-container'}>
      <div className="tab-chart">
        <p className="tab-chart-label">
          {REPORT_TERM_TEXT[activeHeaderTab as keyof typeof REPORT_TERM_TEXT]}별{' '}
          {
            REPORT_CATEGORY_TEXT[
              activeCardHeaderTab as keyof typeof REPORT_CATEGORY_TEXT
            ]
          }
          현황
        </p>
        <ReportBarChart
          labels={
            activeHeaderTab === REPORT_TERM.MONTHLY
              ? getLastSixMonths(today, 'YYYY.MM')
              : getLastSixDays(today, 'YYYY.MM.DD')
          }
          dataSet={processGraphData(
            today,
            graphData,
            activeHeaderTab,
            activeCardHeaderTab,
          )}
          type={activeCardHeaderTab}
        />
      </div>
    </div>
  );
};

export default ReportGraphBarChart;
