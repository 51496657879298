import React from 'react';
import Card from '@src/components/Card.tsx';
import { getYearlyGraphUnits } from '@src/util/functions.ts';
import ProgressBar from '@src/components/ProgressBar.tsx';

interface ReportGraphCardProgressBarTotalProps {
  thisYearValueSales: number;
  lastYearValueSales: number;
  thisYearValuePurchase: number;
  lastYearValuePurchase: number;
  thisYearValueLabor: number;
  lastYearValueLabor: number;
}

const ReportGraphCardProgressBarTotal: React.FC<
  ReportGraphCardProgressBarTotalProps
> = ({
  thisYearValueSales,
  lastYearValueSales,
  thisYearValuePurchase,
  lastYearValuePurchase,
  thisYearValueLabor,
  lastYearValueLabor,
}) => {
  return (
    <div className="report-card-container">
      <Card style={{ padding: '24px', gap: '32px' }}>
        <div>
          <p className="text-base" style={{ marginBottom: '12px' }}>
            매출
          </p>
          <div className="report-card-yearly-graph-text">
            <p
              className="text-sm"
              style={{
                color: '#3182F6',
              }}
            >
              현재 {getYearlyGraphUnits(thisYearValueSales)}만원
            </p>
            <p className="text-sm" style={{ color: '#949494' }}>
              작년 {getYearlyGraphUnits(lastYearValueSales)}만원
            </p>
          </div>
          <ProgressBar
            base={lastYearValueSales}
            current={thisYearValueSales}
            color={'blue'}
          />
        </div>
        <div>
          <p className="text-base" style={{ marginBottom: '12px' }}>
            매입
          </p>
          <div className="report-card-yearly-graph-text">
            <p
              className="text-sm"
              style={{
                color: '#F96464',
              }}
            >
              현재 {getYearlyGraphUnits(thisYearValuePurchase)}만원
            </p>
            <p className="text-sm" style={{ color: '#949494' }}>
              작년 {getYearlyGraphUnits(lastYearValuePurchase)}만원
            </p>
          </div>
          <ProgressBar
            base={lastYearValuePurchase}
            current={thisYearValuePurchase}
            color={'red'}
          />
        </div>
        <div>
          <p className="text-base" style={{ marginBottom: '12px' }}>
            인건비
          </p>
          <div className="report-card-yearly-graph-text">
            <p
              className="text-sm"
              style={{
                color: '#F96464',
              }}
            >
              현재 {getYearlyGraphUnits(thisYearValueLabor)}만원
            </p>
            <p className="text-sm" style={{ color: '#949494' }}>
              작년 {getYearlyGraphUnits(lastYearValueLabor)}만원
            </p>
          </div>
          <ProgressBar
            base={lastYearValueLabor}
            current={thisYearValueLabor}
            color={'red'}
          />
        </div>
      </Card>
    </div>
  );
};

export default ReportGraphCardProgressBarTotal;
