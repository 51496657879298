import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { IManager, IQuestion, IRegister } from '../util/interfaces';
import { TEmailRegisterFormClient } from '@src/pages/auth/RegisterEmail.tsx';
import { EmailVerificationStatus } from '@src/util/constants.ts';
import { TTaxInvoiceForm } from '@src/pages/tax-invoice/TaxInvoice.tsx';
import { Moment } from 'moment';

/**
 * TODO
 *  -- server-state 관리 용도의 전역 상태관리는 persist query client로 대체 가능
 *  -- client-state 관리 용도는 zustand를 이용유지..
 */
export interface ICompany {
  id: number;
  name: string;
  user_name: string;
  resident_number: string;
  tax_type: string;
  type: string;
  industry: string;
  sector: string;
  irs_code: string;
  main_industry_code: string;
  address: string;
  phone_number: string;
  fax: string;
  email: string;
  tax_office: string;
  local_tax_building: string;
  approved: boolean;
  branch: string;
  memo: string;
  default: boolean;
  user_id: number;
}

export interface IRole {
  [key: string]: boolean;

  reports: boolean;
  tax_invoice: boolean;
  cert: boolean;
  labor_subsidy: boolean;
  tax: boolean;
  estimated_tax: boolean;
  knowledge_center: boolean;
  data_integration: boolean;
  etc: boolean;
}

export interface IUser {
  companies?: ICompany[];
  selectedCompany?: ICompany | null;
  email?: string;
  id?: number;
  is_root_rights?: boolean;
  kakao_id?: string | null;
  name?: string;
  phone_number?: string;
  branch?: string;
  current_manager?: number;
  role?: IRole;
}

interface IUserState {
  user: IUser | null;
  setUser: (user: IUser) => void;
}

export const useUserStore = create(
  persist<IUserState>(
    (set) => ({
      user: null,
      setUser: (newManager) =>
        set((state) => ({
          user: { ...state.user, ...newManager },
        })),
    }),
    {
      name: 'user-storage',
    },
  ),
);

interface ManagerState {
  manager: IManager | null;
  setManager: (manager: IManager) => void;
}

export const useManagerStore = create(
  persist<ManagerState>(
    (set) => ({
      manager: null,
      setManager: (newManager) =>
        set((state) => ({
          manager: { ...state.manager, ...newManager },
        })),
    }),
    {
      name: 'manager-storage',
    },
  ),
);

interface RegisterState {
  register: IRegister | null;
  setRegister: (register: IRegister) => void;
}

export const useRegisterStore = create(
  persist<RegisterState>(
    (set) => ({
      register: null,
      setRegister: (newRegister) =>
        set(() => ({
          register: { ...newRegister },
        })),
    }),
    {
      name: 'register-store',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

interface QuestionState {
  question: IQuestion | null;
  setQuestion: (question: IQuestion) => void;
}

export const createQuestionStore = (company_id: number) => {
  return create(
    persist<QuestionState>(
      (set) => ({
        question: null,
        setQuestion: (newQuestion) =>
          set((state) => ({
            question: { ...state.question, ...newQuestion },
          })),
      }),
      {
        name: `question-storage-${company_id}`,
      },
    ),
  );
};

type CompanySelectDrawerState = {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
};

export const useCompanySelectDrawerStore = create<CompanySelectDrawerState>(
  (set) => ({
    drawerOpen: false,
    setDrawerOpen: (open) => set({ drawerOpen: open }),
  }),
);

interface SidebarState {
  collapsed: boolean;
  activeSubMenus: string[];
  toggleCollapsed: () => void;
  toggleSubMenu: (subMenuKey: string) => void;
}

export const useSidebarStore = create<SidebarState>((set) => ({
  collapsed: false,
  activeSubMenus: [],
  toggleCollapsed: () => set((state) => ({ collapsed: !state.collapsed })),
  toggleSubMenu: (subMenuKey) =>
    set((state) => ({
      activeSubMenus: state.activeSubMenus.includes(subMenuKey)
        ? state.activeSubMenus.filter((key) => key !== subMenuKey)
        : [...state.activeSubMenus, subMenuKey],
    })),
}));

interface EmailRegisterFormState {
  emailRegisterForm: TEmailRegisterFormClient | null;
  setEmailRegisterForm: (form: TEmailRegisterFormClient) => void;
}

interface EmailRegisterFormStatusState {
  emailRegisterFormState: EmailVerificationStatus | null;
  setEmailRegisterFormState: (
    emailRegisterFormState: EmailVerificationStatus | null,
  ) => void;
}

export const useEmailRegisterFormStore = create<EmailRegisterFormState>(
  (set) => ({
    emailRegisterForm: null,
    setEmailRegisterForm: (emailRegisterForm) =>
      set(() => ({
        emailRegisterForm,
      })),
  }),
);

export const useEmailRegisterFormStatusStore =
  create<EmailRegisterFormStatusState>((set) => ({
    emailRegisterFormState: null,
    setEmailRegisterFormState: (emailRegisterFormState) =>
      set(() => ({
        emailRegisterFormState,
      })),
  }));

interface TTaxInvoiceFormState {
  taxInvoiceForm: TTaxInvoiceForm;
  setTaxInvoiceForm: (form: TTaxInvoiceForm) => void;
  clearTaxInvoiceForm: () => void;
}

export const defaultTaxInvoiceForm: TTaxInvoiceForm = {
  etxivKndCd: '01',
  registrationNumber1Supplier: '', // Example default
  registrationNumber2Supplier: '', // Example default
  registrationNumber3Supplier: '', // Example default
  splrTnmNm: '', // Example default
  registrationNumberConsumer: {
    registrationNumber1Consumer: '',
    registrationNumber2Consumer: '',
    registrationNumber3Consumer: '',
  },
  dmnrMpbNo: '', // Default empty string
  dmnrPfbAdr: '', // Default empty string
  dmnrTnmNm: '', //상호
  dmnrRprsFnm: '', // 성명
  dmnrBcNm: '', //- 업태
  dmnrItmNm: '', //- 종목
  dmnrMchrgEmlAdr: '',
  dmnrMchrgEmlAdr2: '',
  lsatInfrBizSVOList: [
    {
      lsatSplMm: (new Date().getMonth() + 1).toString().padStart(2, '0'),
      lsatSplDd: '',
      lsatNm: '',
      lsatRszeNm: '',
      lsatQty: '',
      lsatUtprc: '',
      lsatSplCft: '',
      lsatTxamt: '',
      lsatRmrkCntn: '',
    },
  ], // Default to an empty array
  taxInvoiceTypeCd: {
    label: '현금',
    value: 'csh',
  },
  recApeClCd: '02', // Default value
  wrtDt: new Date(), // Default to current date
};

export const useTaxInvoiceFormStore = create<TTaxInvoiceFormState>((set) => ({
  taxInvoiceForm: defaultTaxInvoiceForm,
  setTaxInvoiceForm: (taxInvoiceForm) => set({ taxInvoiceForm }),
  clearTaxInvoiceForm: () => set({ taxInvoiceForm: defaultTaxInvoiceForm }),
}));

interface ICertReadCheckedState {
  certReadChecked: Moment | null;
  setCertReadChecked: (checkedTime: Moment) => void;
}

export const useTaxInvoiceCertStore = create(
  persist<ICertReadCheckedState>(
    (set) => ({
      certReadChecked: null,
      setCertReadChecked: (certReadChecked) => set({ certReadChecked }),
    }),
    {
      name: 'cert-read-checked-storage',
    },
  ),
);
