import React, { useCallback, useState } from 'react';
import Container from '@src/layouts/Container';
import { LABOR_MENU, LABOR_MENU_LIST, URLS } from '@src/util/constants';
import { Link, useNavigate } from 'react-router-dom';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import { createQuestionStore, useUserStore } from '@src/util/store';
import { useMediaQuery } from 'react-responsive';
import SideBar from '@src/components/SideBar';

interface LaborProps {}

const BabyQuestion4: React.FC<LaborProps> = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 818px)' });
  const [user] = useUserStore((state) => [state.user]);
  const company_id = user?.selectedCompany?.id || 0;
  const [question, setQuestion] = createQuestionStore(company_id)((state) => [
    state.question,
    state.setQuestion,
  ]);
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );

  const handleClick = useCallback((value: boolean) => {
    setQuestion({ baby_question_4: value });
    navigate(`${URLS.LABOR}/baby/5`);
  }, []);

  return (
    <Container
      sideBar={<SideBar />}
      header={<DetailHeader title={'노무지원금 신청'} />}
      headerTab={
        isMobile ? (
          ''
        ) : (
          <HeaderTabs
            menus={LABOR_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
    >
      <div className="labor-request-description-container">
        <h2 className="text-md">질문 4</h2>
        <p className="text-regular-up" style={{ color: '#31343A' }}>
          현재 출산전후 휴가로 대체인력을 채용하고 있나요?
        </p>
        <div className={'labor-button-wrapper'}>
          <button className={'button'} onClick={() => handleClick(false)}>
            아니오
          </button>
          <button className={'button'} onClick={() => handleClick(true)}>
            예
          </button>
        </div>
      </div>
      <div className={'button-bottom-default-wrapper'}>
        <div className={'button-size-wrapper'}>
          <Link
            className={'button-right-default'}
            to={question?.current_page || '/labor'}
          >
            돌아가기
          </Link>
        </div>
      </div>
    </Container>
  );
};
export default BabyQuestion4;
