import '@src/scss/main.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import {
  Company,
  ICompanyInfo,
  ICompanyList,
  IManager,
  IManagerList,
  IUserInfo,
} from '@src/util/interfaces';
import api from '@src/util/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_URLS, MANAGER_STATUS, URLS } from '@src/util/constants';

import chevronRight from '@src/assets/icons/chevron_right_black.svg';
import { useManagerStore, useUserStore } from '@src/util/store';
import HeaderTabs from '@src/components/HeaderTabs';
import Container from '@src/layouts/Container';
import { formatPhoneNumber, logoutLogic } from '@src/util/functions';
import ON from '@src/assets/icons/on.svg';
import OFF from '@src/assets/icons/off.svg';
import DetailHeader from '@src/components/DetailHeader';
import CustomModal from '@src/components/CustomModal';
import SideBar from '@src/components/SideBar.tsx';

interface ManagerProps {}

const color = ['#3182F6', '#EBDA44', '#FFA318'];

const authorityList = [
  { id: 'reports', label: '리포트' },
  { id: 'tax_invoice', label: '세금계산서' },
  { id: 'cert', label: '증명서 발급' },
  { id: 'labor_subsidy', label: '노무지원금' },
  { id: 'tax', label: '세금 관리' },
  { id: 'estimated_tax', label: '예상 세금' },
  // { id: 'knowledge_center', label: '세무·노무 지식센터' },
  { id: 'data_integration', label: '데이터 연동' },
  { id: 'etc', label: '기타' },
];

const MY_PAGE_MENU_LIST = [
  { id: MANAGER_STATUS.USER, text: '회원 정보', url: '/my-page/user' },
  { id: MANAGER_STATUS.MANAGER, text: '담당자 관리', url: '/my-page/manager' },
  {
    id: MANAGER_STATUS.BUSINESS,
    text: '사업체 정보',
    url: '/my-page/business',
  },
];

const MyPage: React.FC<ManagerProps> = () => {
  const [manager, setManager] = useManagerStore((state) => [
    state.manager,
    state.setManager,
  ]);
  const [user] = useUserStore((state) => [state.user]);
  const company_id = user?.selectedCompany?.id;
  const queryClient = useQueryClient();

  const filteredMenuList =
    user?.current_manager && !user.is_root_rights
      ? MY_PAGE_MENU_LIST.filter((menu) => menu.id !== MANAGER_STATUS.MANAGER)
      : MY_PAGE_MENU_LIST;

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('type');
  const [headerTap, setHeaderTap] = useState<MANAGER_STATUS>(
    (query as MANAGER_STATUS) ?? MANAGER_STATUS.USER,
  );
  if (query !== null && query !== headerTap) {
    setHeaderTap(query as MANAGER_STATUS);
  }

  const [index, setIndex] = useState(0);

  const [detail, setDetail] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [myInfoModalOpen, setMyInfoModalOpen] = useState(false);
  const [isWithdrawalModalOpen, setWithdrawalModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('상호');
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');

  const business = useQuery<ICompanyInfo>({
    queryKey: [`business/company`, company_id],
    queryFn: () => api.get(API_URLS.COMPANY + `/${company_id}`),
    staleTime: 10 * 60 * 1000, // 10분
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const myInfo = useQuery<IUserInfo>({
    queryKey: [`business/user`, company_id],
    queryFn: () => api.get(API_URLS.BUSINESS),
    staleTime: 10 * 60 * 1000,
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const { mutate: updateUserInfo } = useMutation({
    mutationFn: (data: { key: string; value: string }) =>
      api.patch(API_URLS.BUSINESS, {
        id: user?.id,
        [data.key]: data.value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`business/user`, company_id],
      });
      setMyInfoModalOpen(false);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const { mutate: updateCompany } = useMutation({
    mutationFn: (data: { key: string; value: string }) =>
      api.patch(API_URLS.COMPANY + `/${company_id}`, {
        [data.key]: data.value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`business/company`, company_id],
      });
      setModalOpen(false);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const { mutate: updateManager } = useMutation({
    mutationFn: (data: { company_rights: number[] }) =>
      api.patch(API_URLS.MANAGER + `/${manager?.id}`, { ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['business/managers'] });
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const managers = useQuery<IManagerList>({
    queryKey: ['business/managers'],
    queryFn: async () => {
      const data: IManagerList = await api.get(API_URLS.MANAGER);
      const foundManager = data?.data?.find((m) => m.id === manager?.id);
      if (foundManager) {
        setManager(foundManager);
      }
      return data;
    },
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const companies = useQuery<ICompanyList>({
    queryKey: ['business/companies'],
    queryFn: () => api.get(API_URLS.COMPANY),
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const handleUpdateRole = useCallback(
    (roleName: string, status: boolean) => {
      if (manager && manager.role) {
        const updatedRole = {
          ...manager.role,
          [roleName]: status,
        };

        const updatedManager = {
          ...manager,
          role: updatedRole,
        };

        api
          .patch(API_URLS.MANAGER + `/${manager.id}`, { role: updatedRole })
          .then(() => {
            setManager(updatedManager);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    [manager, manager?.role],
  );

  const handleDeleteManager = useCallback((id?: number) => {
    if (id) {
      api
        .delete(API_URLS.MANAGER + `/${id}`)
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['business/managers'] });
          setDetail(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const handleViewDetailManager = useCallback((manager: IManager) => {
    setManager(manager);
    setDetail(true);
  }, []);

  const handleModalOpen = useCallback(
    (title: string, key: string, value = '') => {
      setModalTitle(title);
      setKey(key);
      setValue(value);
      setModalOpen(true);
    },
    [],
  );

  const handleMyInfoModalOpen = useCallback(
    (title: string, key: string, value = '') => {
      setModalTitle(title);
      setKey(key);
      setValue(value);
      setMyInfoModalOpen(true);
    },
    [],
  );

  const handleWithdrawal = useCallback(() => {}, []);

  const handleDeleteCompanyRight = useCallback(
    (company_id: number) => {
      if (manager && manager.company_rights) {
        const company_rights = manager.company_rights.filter(
          (id) => id !== company_id,
        );
        updateManager({ company_rights });
      }
    },
    [manager],
  );

  const handleAddCompanyRight = useCallback(
    (company_id: number) => {
      if (
        manager &&
        manager.company_rights &&
        !manager.company_rights.includes(company_id)
      ) {
        const company_rights = [...manager.company_rights, company_id];
        updateManager({ company_rights });
      }
    },
    [manager],
  );

  const isManager = user?.current_manager === undefined;

  const detailData = [
    {
      id: 0,
      title: '기본 정보',
      component: (key: string) => (
        <React.Fragment key={key}>
          <div className={'manager-info-wrapper'}>
            <h3 className={'manager-info-title'}>기본 정보</h3>
            <div className={'manager-info-content-wrapper'}>
              <div className={'manager-info-content'}>
                <p className={'manager-info-content-name'}>권한</p>
                <p className={'manager-info-content-value'}>
                  {manager?.is_root_rights ? '총괄 관리자' : '일반 관리자'}
                </p>
              </div>
              <div className={'manager-info-content'}>
                <p className={'manager-info-content-name'}>이름</p>
                <p className={'manager-info-content-value'}>{manager?.name}</p>
              </div>
              <div className={'manager-info-content'}>
                <p className={'manager-info-content-name'}>이메일</p>
                <p className={'manager-info-content-value'}>{manager?.email}</p>
              </div>
              <div className={'manager-info-content'}>
                <p className={'manager-info-content-name'}>휴대폰번호</p>
                <p className={'manager-info-content-value'}>
                  {formatPhoneNumber(manager?.phone_number)}
                </p>
              </div>
            </div>
          </div>
          <div className={'manager-info-wrapper'}>
            <h3 className={'manager-info-title'}>옵션</h3>
            <button
              className={'delete-manager'}
              type={'button'}
              onClick={() => handleDeleteManager(manager!.id)}
            >
              관리자 삭제
            </button>
          </div>
          <div className={'button-bottom-default-wrapper'}>
            <div className={'button-size-wrapper'}>
              <button
                className={'button-left-default'}
                onClick={() => setDetail(false)}
              >
                목록으로
              </button>
              <Link className={'button-right-default'} to={URLS.UPDATE_MANAGER}>
                수정하기
              </Link>
            </div>
          </div>
        </React.Fragment>
      ),
    },
    {
      id: 1,
      title: '권한 관리',
      component: (key: string) => (
        <React.Fragment key={key}>
          {manager?.company_rights && (
            <div className={'manager-authority-wrapper'}>
              <h3 className={'manager-authority-title'}>회사 권한 관리</h3>
              {companies?.data?.data.map((company: Company) => (
                <div key={company.id} className={'manager-authority-content'}>
                  <p className={'authority-name'}>{company.name}</p>
                  {manager?.company_rights?.includes(company.id) ? (
                    <button
                      onClick={() => handleDeleteCompanyRight(company.id || -1)}
                    >
                      <img src={ON} alt={'토글 ON'} />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleAddCompanyRight(company.id || -1)}
                    >
                      <img src={OFF} alt={'토글 OFF'} />
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className={'manager-authority-wrapper'}>
            <h3 className={'manager-authority-title'}>페이지 권한 관리</h3>
            {authorityList.map((authority) => (
              <div key={authority.id} className={'manager-authority-content'}>
                <p className={'authority-name'}>{authority.label}</p>
                <button
                  onClick={() =>
                    handleUpdateRole(
                      authority.id,
                      !manager?.role?.[authority.id],
                    )
                  }
                >
                  {manager?.role?.[authority.id] ? (
                    <img src={ON} alt={'토글 ON'} />
                  ) : (
                    <img src={OFF} alt={'토글 OFF'} />
                  )}
                </button>
              </div>
            ))}
          </div>
        </React.Fragment>
      ),
    },
  ];

  const content = {
    [MANAGER_STATUS.USER]: (
      <div className={'company-info'}>
        <div className={'company-info-content'}>
          <h3 className={'company-info-title'}>기본 정보</h3>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>이름</p>
            <div className={'company-info-data-content'}>
              {user?.current_manager ? user?.name : myInfo.data?.data.name}
            </div>
            {isManager && (
              <button
                className={'company-info-edit'}
                onClick={() =>
                  handleMyInfoModalOpen('이름', 'name', myInfo.data?.data.name)
                }
              >
                수정
              </button>
            )}
          </div>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>이메일</p>
            <div className={'company-info-data-content'}>
              {user?.current_manager ? user?.email : myInfo.data?.data.email}
            </div>
          </div>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>연락처</p>
            <div className={'company-info-data-content'}>
              {formatPhoneNumber(
                user?.current_manager
                  ? user?.phone_number
                  : myInfo.data?.data.phone_number,
              )}
            </div>
            {isManager && (
              <button
                className={'company-info-edit'}
                onClick={() =>
                  handleMyInfoModalOpen(
                    '연락처',
                    'phone_number',
                    myInfo.data?.data.phone_number,
                  )
                }
              >
                수정
              </button>
            )}
          </div>
        </div>
        <div className={'company-info-content'}>
          <h3 className={'company-info-title'}>계정 관리</h3>
          <div className={'company-info-data-wrapper'}>
            <button
              className={'company-info-data-explain'}
              onClick={() => {
                logoutLogic(navigate);
              }}
            >
              로그아웃
            </button>
          </div>
          {isManager && (
            <div className={'company-info-data-wrapper'}>
              <button
                className={'company-info-data-explain'}
                onClick={() => {
                  navigate(URLS.CHANGE_PW);
                }}
              >
                비밀번호 변경
              </button>
            </div>
          )}
          {/*<div className={"company-info-data-wrapper"}>*/}
          {/*  <button className={"company-info-data-explain"} onClick={() => {*/}
          {/*    setWithdrawalModalOpen(true)*/}
          {/*  }}>*/}
          {/*    회원 탈퇴*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      </div>
    ),
    [MANAGER_STATUS.MANAGER]: (
      <React.Fragment>
        {!detail ? (
          <React.Fragment>
            <h3 className={'manager-title'}>담당자 목록</h3>
            <div className={'manager-content'}>
              {managers?.data?.data?.map((manager, index) => {
                return (
                  <button
                    key={manager.id}
                    className={'manager-list'}
                    onClick={() => handleViewDetailManager(manager)}
                  >
                    <div className={'manager-list-icon'}>
                      <div
                        style={{
                          backgroundColor: `${color[index % color.length]}`,
                        }}
                        className={'manager-icon'}
                      >
                        {index + 1}
                      </div>
                      {manager.email}
                    </div>
                    <img src={chevronRight} alt={'우측 화살표'} />
                  </button>
                );
              })}
              <Link className={'add-to-manager-button'} to={URLS.ADD_MANAGER}>
                담당자 추가하기
              </Link>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ul className={'manager-detail-tap-wrapper'}>
              {detailData.map((item) => (
                <li
                  key={item.id}
                  className={
                    index === item.id
                      ? 'manager-detail-tap active'
                      : 'manager-detail-tap'
                  }
                  onClick={() => setIndex(item.id)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
            {detailData
              .filter((item) => index === item.id)
              .map((item) => item.component(`${item.id}`))}
          </React.Fragment>
        )}
      </React.Fragment>
    ),
    [MANAGER_STATUS.BUSINESS]: (
      <div className={'company-info'}>
        <div className={'company-info-content'}>
          <h3 className={'company-info-title'}>기본 정보</h3>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>구분</p>
            <div className={'company-info-data-content'}>
              {business.data?.data.type == 'CORP' ? '법인' : '개인'}
            </div>
          </div>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>상호</p>
            <div className={'company-info-data-content'}>
              {business.data?.data.name}
            </div>
            <button
              className={'company-info-edit'}
              onClick={() =>
                handleModalOpen('상호', 'name', business.data?.data.name)
              }
            >
              수정
            </button>
          </div>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>사업자등록번호</p>
            <div className={'company-info-data-content'}>
              {business.data?.data.resident_number}
            </div>
          </div>
        </div>
        <div className={'company-info-content'}>
          <h3 className={'company-info-title'}>세부 정보 (선택사항)</h3>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>주업종코드</p>
            <div className={'company-info-data-content'}>
              {business.data?.data.irs_code}
            </div>
            <button
              className={'company-info-edit'}
              onClick={() =>
                handleModalOpen(
                  '주업종코드',
                  'irs_code',
                  business.data?.data.irs_code,
                )
              }
            >
              수정
            </button>
          </div>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>사업장 주소</p>
            <div className={'company-info-data-content'}>
              {business.data?.data.address}
            </div>
            <button
              className={'company-info-edit'}
              onClick={() =>
                handleModalOpen(
                  '사업장 주소',
                  'address',
                  business.data?.data.address,
                )
              }
            >
              수정
            </button>
          </div>
          <div className={'company-info-data-wrapper'}>
            <p className={'company-info-data-explain'}>사업장 FAX번호</p>
            <div className={'company-info-data-content'}>
              {business.data?.data.fax}
            </div>
            <button
              className={'company-info-edit'}
              onClick={() =>
                handleModalOpen('FAX번호', 'fax', business.data?.data.fax)
              }
            >
              수정
            </button>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <Container
      header={<DetailHeader title={'마이 페이지'} />}
      headerTab={
        <HeaderTabs
          menus={filteredMenuList}
          activeHeaderTab={headerTap}
          setActiveHeaderTab={(value) => {
            setHeaderTap(value as MANAGER_STATUS);
            navigate(`?type=${value}`, { replace: true });
          }}
        />
      }
      sideBar={<SideBar />}
    >
      <div className="manager-container">{content[headerTap]}</div>
      <CustomModal
        onClose={() => setMyInfoModalOpen(false)}
        isOpen={myInfoModalOpen}
        children={
          <React.Fragment>
            <div className={'modal-custom-wrapper'}>
              <div className={'modal-custom-content-wrapper'}>
                <h2 className={'modal-custom-title'}>{`${modalTitle} 수정`}</h2>
              </div>
              <div className={'modal-input-wrapper'}>
                <input
                  className={'modal-input'}
                  value={value}
                  placeholder={modalTitle}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
            </div>
          </React.Fragment>
        }
        footer={
          <React.Fragment>
            <div className={'custom-modal-button-wrapper'}>
              <button
                className={'custom-modal-button'}
                onClick={() => updateUserInfo({ key, value })}
              >
                확인
              </button>
            </div>
          </React.Fragment>
        }
      />
      <CustomModal
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        children={
          <React.Fragment>
            <div className={'modal-custom-wrapper'}>
              <div className={'modal-custom-content-wrapper'}>
                <h2 className={'modal-custom-title'}>{`${modalTitle} 수정`}</h2>
              </div>
              <div className={'modal-input-wrapper'}>
                <input
                  className={'modal-input'}
                  value={value}
                  placeholder={modalTitle}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
            </div>
          </React.Fragment>
        }
        footer={
          <React.Fragment>
            <div className={'custom-modal-button-wrapper'}>
              <button
                className={'custom-modal-button'}
                onClick={() => updateCompany({ key, value })}
              >
                확인
              </button>
            </div>
          </React.Fragment>
        }
      />
      <CustomModal
        onClose={() => setWithdrawalModalOpen(false)}
        isOpen={isWithdrawalModalOpen}
        children={
          <React.Fragment>
            <div className={'modal-custom-wrapper'}>
              <div className={'modal-custom-content-wrapper'}>
                <h2 className={'modal-custom-title'}>{`회원 탈퇴`}</h2>
                <p className={'modal-custom-content'}>
                  회원 탈퇴 하시겠습니까?
                </p>
              </div>
            </div>
          </React.Fragment>
        }
        footer={
          <React.Fragment>
            <div className={'custom-modal-two-button-wrapper'}>
              <button
                className={'left-button'}
                onClick={() => setWithdrawalModalOpen(false)}
              >
                취소
              </button>
              <button
                className={'right-button'}
                onClick={() => handleWithdrawal()}
              >
                확인
              </button>
            </div>
          </React.Fragment>
        }
      />
    </Container>
  );
};

export default MyPage;
