import '@src/scss/main.scss';
import Input from '@src/components/Input.tsx';
import React, { useState } from 'react';
import Button from '@src/components/Button.tsx';
import { useMutation } from '@tanstack/react-query';
import { API_URLS, EmailVerificationStatus } from '@src/util/constants.ts';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import api from '@src/util/api.ts';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@src/components/SnackBar';
import { passwordRegex } from '@src/util/functions';
import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container.tsx';
import InputLabel from '@src/components/InputLabel.tsx';
import Modal from '@src/components/Modal';
import { z } from 'zod';
import { ZEmail } from '@src/util/zodSchema.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  TEmailRequestForm,
  TEmailVerificationForm,
} from '@src/pages/auth/RegisterEmail.tsx';

interface FindIdProps {}

const UpdatePasswordBase = z.object({
  email: ZEmail,
  code: z.string().min(1),
  password: z.string().regex(passwordRegex, '비밀번호가 유효하지 않습니다.'),
  password_confirm: z.string(),
});

const UpdatePasswordForm = UpdatePasswordBase.refine(
  (data) => data.password === data.password_confirm,
  {
    message: '비밀번호가 일치하지 않습니다.',
    path: ['password_confirm'],
  },
);

const UpdatePasswordFormServer = UpdatePasswordBase.omit({
  password_confirm: true,
});

type TUpdatePasswordForm = z.infer<typeof UpdatePasswordForm>;
type TUpdatePasswordFormServer = z.infer<typeof UpdatePasswordFormServer>;

export const FindPw: React.FC<FindIdProps> = () => {
  const [emailVerificationStatus, setEmailVerificationStatus] = useState(
    EmailVerificationStatus.INITIAL,
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<TUpdatePasswordForm>({
    resolver: zodResolver(UpdatePasswordForm),
    mode: 'onChange',
  });

  const emailValue = watch('email');
  const codeValue = watch('code');

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState('');

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const navigate = useNavigate();

  const { mutate: mutateRequest, isPending: requestLoading } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TEmailRequestForm
  >({
    mutationFn: (formData) => {
      return api.post(API_URLS.SEND_EMAIL_CHANGE_PASSWORD, formData);
    },
  });

  const {
    mutate: mutateVerify,
    isPending: verifyLoading,
    isError: isVerifyError,
    error: verifyError,
  } = useMutation<IApiPOSTSuccess, IApiError, TEmailVerificationForm>({
    mutationFn: (formData) => {
      return api.post(API_URLS.SEND_EMAIL_COMPLETE, {
        email: formData.email,
        value: formData.value,
      });
    },
  });

  const { mutate: updatePassword, isPending: updatePasswordLoading } =
    useMutation<IApiPOSTSuccess, IApiError, TUpdatePasswordFormServer>({
      mutationFn: (formData) => {
        return api.patch(API_URLS.UPDATE_PASSWORD_USER, formData);
      },
    });

  const verifyEmailRequest = () => {
    setEmailVerificationStatus(EmailVerificationStatus.REQUESTED);
    mutateRequest(
      {
        email: emailValue,
      },
      {
        onSuccess: (response) => {
          console.log(response);
          setSnackBarType('info');
          setSnackBarContent('이메일 인증이 요청되었습니다.');
          setSnackBarOpen(true);
        },
        onError: (error) => {
          setErrorModal(error?.response?.data?.message);
        },
        onSettled: () => {
          setTimeout(() => setSnackBarOpen(false), 3000);
        },
      },
    );
  };

  const verifyEmail = () => {
    mutateVerify(
      {
        email: emailValue,
        value: codeValue,
      },
      {
        onSuccess: (response) => {
          console.log(response);
          setEmailVerificationStatus(EmailVerificationStatus.VERIFIED);
        },
        onError: (error) => {
          setErrorModal(error?.response?.data?.message);
          console.log(error);
        },
      },
    );
  };

  const onSubmit: SubmitHandler<TUpdatePasswordForm> = (data) => {
    console.log(data);
    const formData = {
      email: data.email,
      code: data.code,
      password: data.password,
    };
    updatePassword(formData, {
      onSuccess: (response) => {
        console.log(response);
        setModalOpen(true);
      },
      onError: (error) => {
        setErrorModal(error?.response?.data?.message);
      },
    });
  };

  return (
    <Container
      header={isMobile ? <DetailHeader title={'비밀번호 변경'} /> : <></>}
      modal={
        <>
          <Snackbar
            message={snackBarContent}
            open={snackBarOpen}
            type={snackBarType}
          />
          <Modal
            isOpen={isModalOpen}
            onOk={() => {
              setModalOpen(false);
              navigate('/login', { replace: true });
            }}
            title={'비밀번호 변경 완료'}
            content={'비밀번호가 변경되었습니다.'}
            okButton={'확인'}
            onClose={() => {
              setModalOpen(false);
            }}
          />
          <Modal
            isOpen={errorModal != ''}
            onOk={() => {
              setErrorModal('');
            }}
            title={'ERROR'}
            content={errorModal}
            okButton={'확인'}
            onClose={() => {
              setErrorModal('');
            }}
          />
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="email-container-wrapper">
          <div className="description-container">
            <h2 className="email-desktop-title">비밀번호 변경</h2>
          </div>
          <div className="input-container">
            <div className="input-label-container">
              <InputLabel text="이메일" required={true}>
                <div className="email-verify-container">
                  <Input
                    placeholder="이메일"
                    register={register('email')}
                    isLoading={false}
                    disabled={
                      emailVerificationStatus ===
                      EmailVerificationStatus.VERIFIED
                    }
                  />
                  <Button
                    onClick={() => {
                      verifyEmailRequest();
                    }}
                    text={emailVerificationStatus}
                    customClassName="button-secondary"
                    disabled={
                      requestLoading ||
                      !emailValue ||
                      !!errors.email ||
                      emailVerificationStatus ===
                        EmailVerificationStatus.VERIFIED
                    }
                    style={{
                      width: '96px',
                      alignSelf: 'stretch',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </div>
              </InputLabel>
              {emailVerificationStatus ===
                EmailVerificationStatus.REQUESTED && (
                <div className="email-verify-container">
                  <Input
                    placeholder="인증번호 입력"
                    register={register('code')}
                    isLoading={false}
                  />
                  <Button
                    onClick={() => {
                      verifyEmail();
                    }}
                    text={'인증번호 확인'}
                    customClassName="button-secondary"
                    disabled={!codeValue || !!errors.code}
                    isLoading={verifyLoading}
                    type={'button'}
                    style={{
                      width: '96px',
                      alignSelf: 'stretch',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </div>
              )}
              {isVerifyError && (
                <div className="error-message">
                  {verifyError?.response.data.message}
                </div>
              )}
              <InputLabel
                text={'새 비밀번호'}
                required
                description={
                  '영문자(대,소문자), 숫자를 포함하여 최소 8자 이상 15자 이하로 작성 해야 합니다.'
                }
                containerStyle={{ marginTop: '16px' }}
                error={
                  errors.password ? (
                    <p className={'error-message'}>{errors.password.message}</p>
                  ) : errors.password_confirm ? (
                    <p className={'error-message'}>
                      {errors.password_confirm.message}
                    </p>
                  ) : (
                    <></>
                  )
                }
              >
                <Input
                  placeholder="비밀번호"
                  register={register('password')}
                  isLoading={false}
                  type={'password'}
                />
                <Input
                  placeholder="비밀번호 확인"
                  register={register('password_confirm')}
                  isLoading={false}
                  type={'password'}
                />
              </InputLabel>
            </div>
            <Button
              text="변경하기"
              type={'submit'}
              disabled={
                !isValid ||
                emailVerificationStatus !== EmailVerificationStatus.VERIFIED
              }
              isLoading={updatePasswordLoading}
              style={{ marginTop: '32px' }}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};

export default FindPw;
