import React, { startTransition, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  REPORT_CATEGORY,
  REPORT_CATEGORY_TEXT,
  REPORT_TERM,
  REPORT_TERM_TEXT,
} from '@src/util/constants.ts';
import moment from 'moment/moment';
import {
  getDateFullInfo,
  getNextDate,
  getPreviousDate,
} from '@src/util/functions.ts';
import ChevronButton from '@src/components/ChevronButton.tsx';
import { useDashboardAPI } from '@src/util/hooks.ts';
import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container';
import ReportCard from '@src/pages/reports/ReportCard';
import ReportAdditionalDetail from '@src/pages/reports/ReportAdditionalDetail';
import ReportCardStatement from '@src/pages/reports/ReportCardStatement';
import SideBar from '@src/components/SideBar.tsx';
import { Moment } from 'moment';

interface ReportAdditionalProps {}

const ReportAdditional: React.FC<ReportAdditionalProps> = () => {
  const { term = '', category = '' } = useParams<{
    term: REPORT_TERM;
    category: REPORT_CATEGORY;
  }>();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  let type = queryParams.get('type');
  if (!type) {
    type = category === REPORT_CATEGORY.LABOR ? 'employee' : 'statement';
  }

  const setType = (value: string) => {
    queryParams.set('type', value);
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  };

  const [date, setDate] = useState<Moment>(moment());

  const { dashboardData } = useDashboardAPI(date, term);

  return (
    <Container
      header={
        <DetailHeader
          title={`${
            REPORT_TERM_TEXT[term as keyof typeof REPORT_TERM_TEXT]
          }별 ${
            REPORT_CATEGORY_TEXT[category as keyof typeof REPORT_CATEGORY_TEXT]
          }`}
        />
      }
      sideBar={<SideBar />}
    >
      <ReportCard>
        <div className="report-card-additional-header">
          <ChevronButton
            direction={'left'}
            onClick={() => {
              startTransition(() => {
                setDate(getPreviousDate(date, term));
              });
            }}
          />
          <p className="text-base">{getDateFullInfo(date, term)}</p>
          {!getNextDate(date, term).isAfter(moment()) ? (
            <ChevronButton
              direction={'right'}
              onClick={() => {
                startTransition(() => {
                  setDate(getNextDate(date, term));
                });
              }}
            />
          ) : (
            <div style={{ width: '24px', height: '24px' }}></div>
          )}
        </div>
        <ReportCardStatement
          data={dashboardData}
          activeHeaderTab={term}
          activeCardHeaderTab={category}
        />
      </ReportCard>
      {category !== REPORT_CATEGORY.TOTAL &&
        category !== '' &&
        dashboardData && (
          <div className={'flex-column-flex-start-center'}>
            <ReportAdditionalDetail
              category={category}
              type={type}
              setType={setType}
              data={dashboardData}
            />
          </div>
        )}
    </Container>
  );
};

export default ReportAdditional;
