import React from 'react';
import chevronRight from '@src/assets/icons/report/chevron_right.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '@src/components/Icon.tsx';

interface ReportCardLaborProps {
  data: {
    employee_amount: string;
    freelancer_amount: string;
    severance_amount: string;
    etc_amount: string;
    total: string;
  };
  activeCardHeaderTab: string;
  activeHeaderTab: string;
}

const ReportCardLabor: React.FC<ReportCardLaborProps> = ({
  data: {
    employee_amount,
    freelancer_amount,
    severance_amount,
    etc_amount,
    total,
  },
  activeCardHeaderTab,
  activeHeaderTab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClickItems = (type: string) => {
    navigate(
      `/reports/additional/${activeHeaderTab}/${activeCardHeaderTab}?type=${type}`,
    );
  };

  return (
    <>
      <div className="tab-card-statement">{total}원</div>
      <div className="tab-card-statement-details">
        <div className="items" onClick={() => onClickItems('employee')}>
          <div>직원급여</div>
          <div className="item-right">
            <span>{employee_amount}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
        <div className="items" onClick={() => onClickItems('freelancer')}>
          <div>프리랜서</div>
          <div className="item-right">
            <span>{freelancer_amount}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
        <div className="items" onClick={() => onClickItems('severance')}>
          <div>퇴직금</div>
          <div className="item-right">
            <span>{severance_amount}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
        <div className="items" onClick={() => onClickItems('etc')}>
          <div>기타</div>
          <div className="item-right">
            <span>{etc_amount}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportCardLabor;
