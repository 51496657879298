import '@src/scss/main.scss';
import Button from '@src/components/Button.tsx';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Modal from '@src/components/Modal';
import Cookies from 'js-cookie';

interface AdminLoginProps {}

const AdminLogin: React.FC<AdminLoginProps> = () => {
  const navigate = useNavigate();

  const [errorModal, setErrorModal] = useState('');

  useEffect(() => {
    const accessToken = Cookies.get('accessTokenAdmin');

    if (accessToken) {
      navigate('/admin/client', { replace: true });
    }
  }, []);

  return (
    <div className="login-container">
      <div className="body-container">
        <div className={'flex-column-flex-start-center'}>
          <p className={'text-lg'}>Admin</p>
        </div>
        <div className="buttons-container">
          <Button
            text="이메일 로그인"
            onClick={() => {
              navigate('/admin/login/email');
            }}
          />
          <div className="guide-register">
            <p>회원이 아니신가요?</p>
            <a href="/admin/register">회원가입</a>
          </div>
        </div>
      </div>

      <Modal
        isOpen={errorModal != ''}
        onOk={() => {
          setErrorModal('');
        }}
        title={'오류발생'}
        content={errorModal}
        okButton={'확인'}
        onClose={() => {
          setErrorModal('');
        }}
      />
    </div>
  );
};

export default AdminLogin;
