import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container.tsx';
import CustomerSearchContent from '@src/pages/customer/CustomerSearchContent.tsx';

const CustomerSearch = () => {
  return (
    <Container header={<DetailHeader title={'거래처정보 목록조회'} />}>
      <CustomerSearchContent />
    </Container>
  );
};

export default CustomerSearch;
