import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useClient, useEtcSales } from '@src/util/adminHooks.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  IApiError,
  IApiPOSTSuccess,
  IClient,
  IEtcSales,
  IEtcSalesAdd,
} from '@src/util/interfaces.ts';
import apiAdmin from '@src/util/apiAdmin.ts';
import {
  API_URLS_ADMIN,
  clientKeywordSearchFilterOption,
  COLORS,
  etcSalesAddOption,
  etcSalesAddValue,
} from '@src/util/constants.ts';
import moment from 'moment';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Button from '@src/components/Button.tsx';
import { convertToKST } from '@src/util/functions.ts';
import IconSVG from '@src/components/IconSVG.tsx';
import ReactDatePicker from '@src/components/DatePicker.tsx';
import Select from '@src/components/Select.tsx';
import Input from '@src/components/Input.tsx';
import Table from '@src/components/Table.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import CustomModal from '@src/components/CustomModal.tsx';
import TailSpinner from '@src/components/TailSpinner.tsx';
import Snackbar from '@src/components/SnackBar.tsx';
import { z } from 'zod';
const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

const EtcSalesAddForm = z.object({
  tx_dt: z.date({
    errorMap: () => ({
      message: '날짜를 입력해 주세요.',
    }),
  }),
  type: z.object({
    value: z.enum(etcSalesAddValue),
    label: z.string(),
  }),
  amount: z.string().min(1, '금액을 입력해 주세요.'),
});

const EtcSalesAddServer = EtcSalesAddForm.omit({
  type: true,
  tx_dt: true,
  amount: true,
}).extend({
  company_id: z.number(),
  type: z.string(),
  tx_dt: z.string(),
  amount: z.number(),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;
type TEtcSalesAddForm = z.infer<typeof EtcSalesAddForm>;
type TEtcSalesAddServer = z.infer<typeof EtcSalesAddServer>;

const ReportAdminContent = () => {
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState<string>('');
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'name' | 'user_name' | 'resident_number' | 'user_email'
  >('user_name');

  const [etcSalesModalOpen, setEtcSalesModalOpen] = useState<boolean>(false);
  const [etcSalesModalCompanyNo, setEtcSalesModalCompanyNo] = useState<
    number | null
  >(null);

  const { data, fetchNextPage, hasNextPage } = useClient(
    { label: '전체', value: '전체' },
    { label: '전체', value: '전체' },
    keyword,
    keywordSearchCategory,
  );

  const { data: etcSalesData } = useEtcSales(etcSalesModalCompanyNo);

  const flattenedData = data?.pages.flatMap((page) => page.data);

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '대표자명', value: '대표자명' },
    },
  });

  const {
    register: registerEtcSalesAdd,
    control: controlEtcSalesAdd,
    setValue,
    reset,
    handleSubmit: handleSubmitEtcSalesAdd,
    formState: { errors },
  } = useForm<TEtcSalesAddForm>({
    resolver: zodResolver(EtcSalesAddForm),
    defaultValues: {
      type: {
        label: '과세',
        value: 'TAXABLE',
      },
    },
  });

  //
  const { mutate: addEtcSales, isPending: addEtcSalesLoading } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TEtcSalesAddServer
  >({
    mutationFn: (formData) => {
      return apiAdmin.post(API_URLS_ADMIN.ETC_SALES, formData);
    },
  });

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '대표자명') {
      setKeywordSearchCategory('user_name');
    } else if (keywordSearchCategoryValue === '대표자이메일') {
      setKeywordSearchCategory('user_email');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    } else if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('name');
    }
    setKeyword(data.keyword);
  };

  const onSubmitEtcSalesAdd: SubmitHandler<TEtcSalesAddForm> = (data) => {
    if (etcSalesModalCompanyNo !== null) {
      const formData = {
        tx_dt: moment(data.tx_dt).format('YYYYMMDD'),
        type: data.type.value,
        amount: parseInt(data.amount),
        company_id: etcSalesModalCompanyNo,
      };

      addEtcSales(formData, {
        onSuccess: (response) => {
          console.log(response);
          queryClient.invalidateQueries({
            queryKey: ['etcSales', etcSalesModalCompanyNo],
          });
          setSnackBarType('info');
          setSnackBarContent('저장 되었습니다.');
          setSnackBarOpen(true);
          setTimeout(() => {
            setSnackBarOpen(false);
          }, 3000);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  const columnHelper = createColumnHelper<IClient>();
  const columnHelperEtcSales = createColumnHelper<IEtcSales>();
  const columnHelperEtcSalesAdd = createColumnHelper<IEtcSalesAdd>();

  const columns: Array<ColumnDef<IClient, string>> = [
    columnHelper.accessor('user.name', {
      id: 'user_name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '68px' }}>대표자</div>,
    }),
    columnHelper.accessor('user.email', {
      id: 'user.email',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '68px' }}>대표자이메일</div>,
    }),
    columnHelper.accessor('resident_number', {
      id: 'resident_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>사업자등록번호</div>,
    }),
    columnHelper.accessor('name', {
      id: 'name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '160px' }}>사업자명</div>,
    }),
    columnHelper.display({
      id: 'detail',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <Button
              text={'기타매출 관리'}
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: '600',
                width: '92px',
                padding: '4px 10px',
                border: '1px solid #3182F6',
                borderRadius: '4px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setEtcSalesModalCompanyNo(props.row.original.id);
                setEtcSalesModalOpen(true);
              }}
            />
          </div>
        );
      },
      header: () => <div style={{ minWidth: '92px' }}>관리</div>,
    }),
  ];

  const etcSalesColumns: Array<ColumnDef<IEtcSales, string>> = [
    columnHelperEtcSales.accessor('tx_dt', {
      id: 'tx_dt',
      cell: (info) => (
        <i>
          {info.getValue().trim() === '' ? '-' : convertToKST(info.getValue())}
        </i>
      ),
      header: () => <div style={{ minWidth: '72px' }}>날짜</div>,
    }),
    columnHelperEtcSales.accessor('sup_amount', {
      id: 'sup_amount',
      cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
      header: () => <div style={{ minWidth: '72px' }}>공급가액</div>,
    }),
    columnHelperEtcSales.accessor('tax_amount', {
      id: 'tax_amount',
      cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
      header: () => <div style={{ minWidth: '72px' }}>세액</div>,
    }),
    columnHelperEtcSales.accessor('tot_amount', {
      id: 'tot_amount',
      cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
      header: () => <div style={{ minWidth: '72px' }}>공급대가</div>,
    }),
    columnHelperEtcSales.display({
      id: 'delete',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <IconSVG
              fill={COLORS['red']}
              size={'24'}
              iconSVG={'delete'}
              containerStyle={{ cursor: 'pointer' }}
              onClick={() => {
                const txDt = props.row.original.tx_dt;
                const companyId = props.row.original.company_id;

                apiAdmin
                  .delete(API_URLS_ADMIN.ETC_SALES + `/${companyId}/${txDt}`)
                  .then((res) => {
                    console.log(res);
                    queryClient.invalidateQueries({
                      queryKey: ['etcSales', companyId],
                    });
                    setSnackBarType('error');
                    setSnackBarContent('삭제되었습니다.');
                    setSnackBarOpen(true);
                    setTimeout(() => {
                      setSnackBarOpen(false);
                    }, 3000);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
          </div>
        );
      },
      header: () => <div style={{ minWidth: '28px' }}>삭제</div>,
    }),
  ];
  const etcSalesAddColumns: Array<ColumnDef<IEtcSalesAdd, string>> = [
    columnHelperEtcSalesAdd.accessor('tx_dt', {
      id: 'tx_dt',
      cell: () => {
        return (
          <Controller
            control={controlEtcSalesAdd}
            name={'tx_dt'}
            render={({ field: { value } }) => (
              <ReactDatePicker
                value={value}
                onChange={(date) =>
                  setValue('tx_dt', date, { shouldValidate: true })
                }
              />
            )}
          />
        );
      },
      header: () => <div style={{ minWidth: '72px' }}>날짜</div>,
    }),
    columnHelperEtcSalesAdd.accessor('type', {
      id: 'type',
      cell: () => {
        return (
          <Controller
            name="type"
            control={controlEtcSalesAdd}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="etcSalesAddType"
                  options={etcSalesAddOption}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        );
      },
      header: () => {
        return <div style={{ width: '80px' }}>과세/면세</div>;
      },
    }),
    columnHelperEtcSalesAdd.accessor('amount', {
      id: 'amount',
      cell: () => {
        return (
          <Input
            placeholder={'금액'}
            register={registerEtcSalesAdd('amount')}
            style={{ width: '100%' }}
          />
        );
      },
      header: '금액',
    }),
    columnHelperEtcSalesAdd.display({
      id: 'save',
      cell: () => {
        return (
          <div className={'flex-row-center'}>
            <>
              <Button
                text={'저장'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '600',
                  width: '60px',
                  border: '1px solid #3182F6',
                  borderRadius: '4px',
                }}
                type={'submit'}
                form={'etc-sales-add-form'}
                isLoading={addEtcSalesLoading}
              />
            </>
          </div>
        );
      },
      header: '저장',
    }),
  ];

  return (
    <>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ gap: '24px', margin: '0 48px 48px 48px' }}
      >
        <p className={'text-md'}>리포트 관리</p>

        <Table<IClient>
          data={flattenedData}
          columns={columns}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          filterContent={
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={'table-filter-container'}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={'table-filter-keyword-search-container'}>
                    <div style={{ width: '128px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={clientKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '414px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent the default behavior of the enter key
                          handleSubmit(onSubmit)(); // Execute the submit handler
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </form>
          }
        />
      </div>
      <>
        <CustomModal
          isOpen={etcSalesModalOpen}
          onClose={() => {
            setEtcSalesModalCompanyNo(null);
            setEtcSalesModalOpen(false);
            reset();
          }}
        >
          <div className={'flex-column-flex-start-stretch'}>
            <div className={'flex-row-space-between-center'}>
              <p className={'text-md'}>기타매출 관리</p>
              <IconSVG
                fill={COLORS['main']}
                size={'24'}
                iconSVG={'close'}
                containerStyle={{ cursor: 'pointer' }}
                onClick={() => {
                  setEtcSalesModalCompanyNo(null);
                  setEtcSalesModalOpen(false);
                  reset();
                }}
              />
            </div>
            <form
              id={'etc-sales-add-form'}
              onSubmit={handleSubmitEtcSalesAdd(onSubmitEtcSalesAdd)}
              style={{ margin: '24px 0' }}
            >
              <Table<IEtcSalesAdd>
                data={[{ id: 0, tx_dt: '', type: '', amount: '' }]}
                columns={etcSalesAddColumns}
              />
              {errors.tx_dt ? (
                <div style={{ margin: '10px 0' }}>
                  <p className={'error-message'}>{errors.tx_dt.message}</p>
                </div>
              ) : errors.amount ? (
                <div style={{ margin: '10px 0' }}>
                  <p className={'error-message'}>{errors.amount.message}</p>
                </div>
              ) : (
                <></>
              )}
            </form>
            {etcSalesData ? (
              <Table<IEtcSales>
                data={etcSalesData}
                columns={etcSalesColumns}
                style={{ marginBottom: '24px' }}
              />
            ) : (
              <div className={'flex-row-center'}>
                <TailSpinner type={'full-page'} />
              </div>
            )}
          </div>
          <Snackbar
            message={snackBarContent}
            open={snackBarOpen}
            type={snackBarType}
          />
        </CustomModal>
      </>
    </>
  );
};

export default ReportAdminContent;
