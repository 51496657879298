import { useNavigate } from 'react-router-dom';
import { useExpectedPayment } from '@src/util/hooks.ts';
import {
  convertNumberToDateKR,
  getNextFiveBusinessDays,
} from '@src/util/functions.ts';
import moment from 'moment';
import Card from '@src/components/Card.tsx';
import { COLORS } from '@src/util/constants.ts';
import Icon from '@src/components/Icon.tsx';
import chevronRightSm from '/src/assets/icons/chevron_right_sm.svg';
import chevronRightBlue from '/src/assets/icons/report/chevron_right_blue.svg';

const ExpectedPaymentContent = () => {
  const navigate = useNavigate();
  const { data } = useExpectedPayment();

  return (
    <div className={'expected-payment-container'}>
      <Card style={{ padding: '24px 24px 12px 24px', gap: '24px' }}>
        <div className={'expected-payment-title-container'}>
          <p className={'text-regular-bold'}>일별 입금 예정액</p>
          <p className={'text-regular-bold'}>{moment().get('month') + 1}월</p>
        </div>
        <div className={'expected-payment-content-container'}>
          {getNextFiveBusinessDays().map((day, i) => {
            return (
              <div
                key={i}
                className={'expected-payment-content-row'}
                onClick={() =>
                  navigate(`/expected-payment?date=${day.format('YYYYMMDD')}`)
                }
              >
                <div className={'expected-payment-content-row-left'}>
                  <p
                    className={'text-regular-bold'}
                    style={{
                      color: COLORS['white'],
                      backgroundColor: COLORS['primary'],
                      width: '36px',
                      height: '36px',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {day.get('date')}
                  </p>
                  <p
                    className={'text-regular-bold'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {day.get('month') + 1}월 {day.get('date')}일{' '}
                    <span
                      className={'text-regular'}
                      style={{ lineHeight: '22px', marginLeft: '4px' }}
                    >
                      {convertNumberToDateKR(day.get('day'))}
                    </span>
                  </p>
                </div>
                <div className={'expected-payment-content-row-right'}>
                  <p className={'text-regular'}>
                    {data.data.list
                      .filter((item) => item.pay_dt === day.format('YYYYMMDD'))
                      .reduce((sum, item) => sum + item.pay_amount, 0)
                      .toLocaleString()}
                    원
                  </p>
                  <Icon iconSrc={chevronRightSm} />
                </div>
              </div>
            );
          })}
          <div
            className="expected-payment-content-more"
            onClick={() =>
              navigate(`/expected-payment?date=${moment().format('YYYYMMDD')}`)
            }
          >
            <button className="text">입금 예정 더보기</button>
            <Icon iconSrc={chevronRightBlue} iconText={'>'} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ExpectedPaymentContent;
