import '@src/scss/main.scss';
import { Link, useNavigate } from 'react-router-dom';
import { HometaxUpdate, IApiError, ICert } from '@src/util/interfaces';
import api from '@src/util/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_URLS, URLS } from '@src/util/constants';
import { useUserStore } from '@src/util/store';
import React, { useCallback, useState } from 'react';
import Hometax_32 from '@src/assets/icons/logo/hometax_32.svg';
import Financing_32 from '@src/assets/icons/logo/financing_32.svg';
import DetailHeader from '@src/components/DetailHeader';
import Container from '@src/layouts/Container';
import CustomModal from '@src/components/CustomModal';
import { financingIdRegex, financingPasswordRegex } from '@src/util/functions';
import TailSpinner from '@src/components/TailSpinner';
import SideBar from '@src/components/SideBar.tsx';
import Modal from '@src/components/Modal';
import CardIcon from '@src/components/CardIcon.tsx';
import DeliveryIcon from '@src/components/DeliveryIcon.tsx';
import CommerceIcon from '@src/components/CommerceIcon.tsx';

interface DataSyncProps {}

const Sync: React.FC<DataSyncProps> = () => {
  const navigate = useNavigate();
  const [user] = useUserStore((state) => [state.user]);

  const [idModalOpen, setIdModalOpen] = useState<boolean>(false);
  const [clearModalOpen, setClearModalOpen] = useState<boolean>(false);

  const [financing_id, set_financing_id] = useState<string>('');
  const [financing_password, set_financing_password] = useState<string>('');

  const [key, setKey] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const [error, setError] = useState<string>('');

  const company_id = user?.selectedCompany?.id;
  const queryClient = useQueryClient();
  const cert = useQuery<ICert>({
    queryKey: [`cert`, company_id],
    queryFn: () => api.get(API_URLS.CERT + `/${user?.selectedCompany?.id}`),
    staleTime: 30 * 60 * 1000, // 30분
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });

  const idSyncDisable =
    cert?.data?.data.financing_id &&
    cert?.data?.data.financing_password &&
    cert?.data?.data.financing_password_error == false;
  const idPwError = cert?.data?.data.financing_password_error == true;

  const { mutate: updateIdCert, isPending: isLoading } = useMutation({
    mutationFn: (data: HometaxUpdate) =>
      api.post(API_URLS.CERT + `/financing`, { ...data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setIdModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const { mutate: updateCertClear } = useMutation({
    mutationFn: (data: { key: string; value: string }) =>
      api.patch(API_URLS.CERT + `/${user?.selectedCompany?.id}`, {
        [data.key]: data.value,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cert', company_id] });
      setClearModalOpen(false);
    },
    onError: (e: IApiError) => {
      setError(e.response.data.message);
    },
  });

  const handleClear = useCallback(
    (key: string, value: string, title: string) => {
      setKey(key);
      setValue(value);
      setTitle(title);
      setClearModalOpen(true);
    },
    [],
  );

  const handleIdSyncClick = useCallback(() => {
    if (!financingIdRegex.test(financing_id)) {
      setError('6자 이상 20자 이하의 영문 또는 영문+숫자');
      return;
    }
    if (!financingPasswordRegex.test(financing_password)) {
      setError('영문+숫자+특수문자 포함 8~20자');
      return;
    }
    updateIdCert({ financing_id, financing_password, company_id });
  }, [financing_id, financing_password, company_id]);

  if (isLoading) {
    return <TailSpinner type={'full-page'} />;
  }

  if (!user?.role?.data_integration) {
    return (
      <>
        <Modal
          isOpen={true}
          onOk={() => {
            navigate(-1);
          }}
          title={'페이지 권한이 없습니다.'}
          okButton={'확인'}
          onClose={() => {}}
        />
      </>
    );
  }

  return (
    <Container
      header={<DetailHeader title={'데이터 연동'} />}
      sideBar={<SideBar />}
    >
      <div className="data-sync-container">
        <div className={'data-sync-content-wrapper'}>
          <p className={'content-title'}>홈택스</p>
          <div className={'data-sync-content'}>
            <div className={'icon-wrapper'}>
              <div className={'hometax-icon'}>
                <img src={Hometax_32} alt="hometax" />
              </div>
              <p className={'icon-label'}>홈택스</p>
            </div>
            <Link className={'sync-button'} to={URLS.HOMETAX_SYNC}>
              연결하기
            </Link>
          </div>
        </div>
        <div className={'data-sync-content-wrapper'}>
          <p className={'content-title'}>카드매출</p>
          <div className={'data-sync-content'}>
            <div className={'icon-wrapper'}>
              <img src={Financing_32} alt="card-sales" />
              <p className={'icon-label'}>
                여신금융협회
                {idPwError && (
                  <span className={'error-message'}> (비밀번호 오류)</span>
                )}
              </p>
            </div>
            {idSyncDisable && (
              <button
                className={'sync-button-clear'}
                onClick={() =>
                  handleClear(
                    'financing_id',
                    cert?.data?.data.financing_id || '',
                    '여신금융 아이디 연결해제',
                  )
                }
              >
                연결해제
              </button>
            )}
            {!idSyncDisable && (
              <button
                className={'sync-button'}
                onClick={() => setIdModalOpen(true)}
              >
                연결하기
              </button>
            )}
          </div>
        </div>
        <div className={'data-sync-content-wrapper'}>
          <p className={'content-title'}>카드매입</p>
          <div className={'data-sync-content'}>
            <div className={'icon-wrapper-gradient'}>
              <CardIcon name={'현대카드'} size={'lg'} />
              <CardIcon name={'비씨카드'} size={'lg'} />
              <CardIcon name={'롯데카드'} size={'lg'} />
              <CardIcon name={'KB카드'} size={'lg'} />
              <CardIcon name={'하나카드'} size={'lg'} />
              <CardIcon name={'삼성카드'} size={'lg'} />
              <CardIcon name={'농협카드'} size={'lg'} />
              <CardIcon name={'신한카드'} size={'lg'} />
              <CardIcon name={'우리카드'} size={'lg'} />
            </div>
            <Link className={'sync-button'} to={URLS.CARD_SYNC}>
              연결하기
            </Link>
          </div>
        </div>
        <div className={'data-sync-content-wrapper'}>
          <p className={'content-title'}>커머스</p>
          <div className={'data-sync-content'}>
            <div className={'icon-wrapper-images'}>
              <CommerceIcon name={'NAVER'} size={'lg'} />
              <CommerceIcon name={'COUPANG'} size={'lg'} />
            </div>
            <Link className={'sync-button'} to={URLS.COMMERCE_SYNC}>
              연결하기
            </Link>
          </div>
        </div>
        <div className={'data-sync-content-wrapper'}>
          <p className={'content-title'}>배달앱</p>
          <div className={'data-sync-content'}>
            <div className={'icon-wrapper-images'}>
              <DeliveryIcon name={'BM'} size={'lg'} />
              <DeliveryIcon name={'YO'} size={'lg'} />
              <DeliveryIcon name={'CPE'} size={'lg'} />
            </div>
            <Link className={'sync-button'} to={URLS.DELIVERY_SYNC}>
              연결하기
            </Link>
          </div>
        </div>

        {/*<div className={"sync-content-wrapper"}>*/}
        {/*  <p className={"content-title"}>계좌</p>*/}
        {/*  <div className={"sync-content"}>*/}
        {/*    <div className={"icon-wrapper-gradient"}>*/}
        {/*      <img className={"icon"} src={Hd_32} alt="card-sales" />*/}
        {/*      <div className={"icon-size"} style={{ background: '#E93C42', padding: '2.667px 5.333px 5.333px 2.667px' }}>*/}
        {/*        <img className={"icon"} src={Bc_24} alt="card-sales" />*/}
        {/*      </div>*/}
        {/*      <img className={"icon"} src={Lotte_32} alt="card-sales" />*/}
        {/*      <div className={"icon-size"} style={{ background: '#F8B500', padding: '6.667px 4px 7.333px 4px' }}>*/}
        {/*        <img className={"icon"} src={Kb_24} alt="card-sales" />*/}
        {/*      </div>*/}
        {/*      <div className={"icon-size"} style={{ background: '#009B8F', padding: '5.333px 5.333px 6.519px 5.333px' }}>*/}
        {/*        <img src={Hana_24} alt="card-sales" />*/}
        {/*      </div>*/}
        {/*      <div className={"icon-size"} style={{ background: '#167BFF', padding: '2.667px' }}>*/}
        {/*        <img src={Samsung} alt="card-sales" />*/}
        {/*      </div>*/}
        {/*      <div className={"icon-size"} style={{ background: '#FDB812' }}>*/}
        {/*        <img src={Nh} alt="card-sales" />*/}
        {/*      </div>*/}
        {/*      <div className={"icon-size"} style={{ background: '#0046FF' }}>*/}
        {/*        <img src={Shc} alt="card-sales" />*/}
        {/*      </div>*/}
        {/*      <img className={"icon-size"} src={Wr} alt="card-sales" />*/}
        {/*    </div>*/}
        {/*    <button className={"sync-button"}>*/}
        {/*      연결하기*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <CustomModal
        onClose={() => {
          setError('');
          setIdModalOpen(false);
        }}
        isOpen={idModalOpen}
        children={
          <React.Fragment>
            <div className={'modal-custom-wrapper'}>
              <div className={'modal-custom-content-wrapper'}>
                <h2 className={'modal-custom-title'}>{`아이디로 연결`}</h2>
                <div className={'modal-input-wrapper'}>
                  <input
                    className={'modal-input'}
                    value={financing_id}
                    placeholder={'아이디 입력'}
                    onFocus={() => setError('')}
                    onChange={(e) => set_financing_id(e.target.value)}
                  />
                  <input
                    type={'password'}
                    className={'modal-input'}
                    value={financing_password}
                    placeholder={'비밀번호 입력'}
                    onFocus={() => setError('')}
                    onChange={(e) => set_financing_password(e.target.value)}
                  />
                  {error ? <p className={'error-message'}>{error}</p> : <></>}
                </div>
              </div>
            </div>
          </React.Fragment>
        }
        footer={
          <React.Fragment>
            <div className={'custom-modal-two-button-wrapper'}>
              <button
                className={'left-button'}
                onClick={() => setIdModalOpen(false)}
              >
                취소
              </button>
              <button
                className={'right-button'}
                onClick={() => handleIdSyncClick()}
              >
                확인
              </button>
            </div>
          </React.Fragment>
        }
      />

      <CustomModal
        onClose={() => {
          setError('');
          setClearModalOpen(false);
        }}
        isOpen={clearModalOpen}
        children={
          <React.Fragment>
            <div className={'modal-custom-wrapper'}>
              <div className={'modal-custom-content-wrapper'}>
                <h2 className={'modal-custom-title'}>{`${title}`}</h2>
              </div>
              {value ? (
                <div className={'modal-input-wrapper'}>
                  <input
                    className={'modal-input'}
                    value={value}
                    disabled={true}
                  />
                </div>
              ) : (
                <></>
              )}
              {error ? <p className={'error-message'}>{error}</p> : <></>}
            </div>
          </React.Fragment>
        }
        footer={
          <React.Fragment>
            <div className={'custom-modal-two-button-wrapper'}>
              <button
                className={'left-button'}
                onClick={() => setClearModalOpen(false)}
              >
                취소
              </button>
              <button
                className={'right-button'}
                onClick={() => updateCertClear({ key, value: '' })}
              >
                확인
              </button>
            </div>
          </React.Fragment>
        }
      />
    </Container>
  );
};

export default Sync;
