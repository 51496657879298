import React, { useState } from 'react';
import InputLabel from '@src/components/InputLabel.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from '@src/components/Select.tsx';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import { objectToQueryString } from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import {
  API_URL,
  organizationCode,
  organizationCodeOptions,
  purposeCode,
  purposeCodeOptions,
} from '@src/util/constants.ts';
import { z } from 'zod';
import Input from '@src/components/Input.tsx';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';

interface CertificateFormContent10Props {}

/**
 *  갑근세원천징수확인서
 *    대상자*: employee_name
 *    생년월일*: employee_birth
 *    제출기관: organization
 *    제출목적*: purpose
 */

const CertificateForm10 = z.object({
  employee_name: z
    .string()
    .trim()
    .refine((val) => val.length > 0, {
      message: '대상자를 입력해 주세요.', // Custom message for empty input
    }),
  employee_birth: z
    .string()
    .trim()
    .refine((val) => val.length > 0, {
      message: '생년월일을 입력해 주세요.', // Custom message for empty input
    }),
  organization: z.object({
    value: z.enum(organizationCode),
    label: z.string(),
  }),
  purpose: z.object({
    value: z.enum(purposeCode),
    label: z.string(),
  }),
});

const CertificateForm10Server = CertificateForm10.omit({
  organization: true,
  purpose: true,
}).extend({
  company_id: z.number(),
  organization: z.string(),
  purpose: z.string(),
});

type TCertificateForm10 = z.infer<typeof CertificateForm10>;
type TCertificateForm10Server = z.infer<typeof CertificateForm10Server>;
const CertificateFormContent10: React.FC<
  CertificateFormContent10Props
> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<TCertificateForm10>({
    resolver: zodResolver(CertificateForm10),
    mode: 'onChange',
    defaultValues: {
      organization: { label: '기타', value: '기타' },
      purpose: { label: '기타', value: '기타' },
    },
  });

  const {
    mutate,
    isPending: isLoading,
    isSuccess,
    error,
  } = useMutation<IApiPOSTSuccess, IApiError, TCertificateForm10Server>({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);

      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificateForm10> = (data) => {
    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: '갑근세원천징수확인서',
      employee_name: data.employee_name,
      employee_birth: data.employee_birth,
      purpose: data.purpose.value,
      organization: data.organization.value,
    };

    mutate(formData, {
      onSuccess: (response) => {
        setModalOpen(true);
        console.log(response);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="certificate-form-content">
          <CertificatesDescription
            title={
              '갑근세원천징수확인서는 올해 소득에 대해 원천세 신고를 완료한 달까지 발급 가능합니다.'
            }
          />
          <InputLabel text={'대상자'} required={true}>
            <Input
              placeholder="성명 입력"
              register={register('employee_name')}
              type={'text'}
              isLoading={isLoading}
            />
            {errors.employee_name && (
              <div className="error-message">
                {errors.employee_name?.message?.toString()}
              </div>
            )}
          </InputLabel>
          <InputLabel text={'생년월일'} required={true}>
            <Input
              placeholder="생년월일 입력"
              register={register('employee_birth')}
              type={'text'}
              isLoading={isLoading}
            />
            {errors.employee_birth && (
              <div className="error-message">
                {errors.employee_birth?.message?.toString()}
              </div>
            )}
          </InputLabel>
          <InputLabel text="제출기관">
            <Controller
              name="organization"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    name="organization"
                    options={organizationCodeOptions}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />
          </InputLabel>
          <InputLabel text="제출목적" required={true}>
            <Controller
              name="purpose"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    name="purpose"
                    options={purposeCodeOptions}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />
          </InputLabel>
        </div>
        <div className={'bottom-button-wrapper-container'}>
          <Button
            text={'신청하기'}
            isLoading={isLoading}
            type={'submit'}
            disabled={isLoading}
            style={{
              width: '100%',
              maxWidth: '480px',
              boxSizing: 'border-box',
              borderRadius: '3px',
            }}
          />
        </div>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={isSuccess ? '증명서 발급 요청' : '증명서 발급 실패'}
        content={
          isSuccess
            ? '증명서 발급 요청 되었습니다.'
            : error?.response.data.message ?? ''
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default CertificateFormContent10;
