import React from 'react';
import { REPORT_CATEGORY, REPORT_TERM } from '@src/util/constants.ts';
import { Moment } from 'moment';
import { IReportGraph } from '@src/util/interfaces.ts';
import ReportGraphCardProgressBar from '@src/pages/reports/ReportGraphCardProgressBar';
import ReportGraphBarChart from '@src/pages/reports/ReportGraphBarChart';
import ReportGraphCardProgressBarTotal from '@src/pages/reports/ReportGraphCardProgressBarTotal';
import { useUserStore } from '@src/util/store.ts';

interface ReportGraphProps {
  activeHeaderTab: string;
  activeCardHeaderTab: string;
  today: Moment;
  graphData: IReportGraph;
}

const ReportGraph: React.FC<ReportGraphProps> = ({
  activeHeaderTab,
  activeCardHeaderTab,
  today,
  graphData,
}) => {
  const thisYear = today.clone().year();
  const lastYear = today.clone().subtract(1, 'years').year();
  const [user] = useUserStore((state) => [state.user]);

  if (activeHeaderTab !== REPORT_TERM.YEARLY) {
    return (
      <ReportGraphBarChart
        activeHeaderTab={activeHeaderTab}
        activeCardHeaderTab={activeCardHeaderTab}
        today={today}
        graphData={graphData}
      />
    );
  } else {
    const { labor, purchase, sales } = graphData.data;

    if (activeCardHeaderTab === REPORT_CATEGORY.SALES) {
      return (
        <ReportGraphCardProgressBar
          activeCardHeaderTab={activeCardHeaderTab}
          thisYearValue={
            (user?.selectedCompany?.tax_type !== 'EXEMPT'
              ? sales.sup_amount?.[thisYear]
              : sales.tot_amount?.[thisYear]) ?? 0
          }
          lastYearValue={
            (user?.selectedCompany?.tax_type !== 'EXEMPT'
              ? sales.sup_amount?.[lastYear]
              : sales.tot_amount?.[lastYear]) ?? 0
          }
        />
      );
    } else if (activeCardHeaderTab === REPORT_CATEGORY.PURCHASE) {
      return (
        <ReportGraphCardProgressBar
          activeCardHeaderTab={activeCardHeaderTab}
          thisYearValue={
            (user?.selectedCompany?.tax_type !== 'EXEMPT'
              ? purchase.sup_amount?.[thisYear]
              : purchase.tot_amount?.[thisYear]) ?? 0
          }
          lastYearValue={
            (user?.selectedCompany?.tax_type !== 'EXEMPT'
              ? purchase.sup_amount?.[lastYear]
              : purchase.tot_amount?.[lastYear]) ?? 0
          }
        />
      );
    } else if (activeCardHeaderTab === REPORT_CATEGORY.LABOR) {
      return (
        <ReportGraphCardProgressBar
          activeCardHeaderTab={activeCardHeaderTab}
          thisYearValue={labor[thisYear] ?? 0}
          lastYearValue={labor[lastYear] ?? 0}
        />
      );
    } else {
      return (
        <ReportGraphCardProgressBarTotal
          thisYearValueSales={
            (user?.selectedCompany?.tax_type !== 'EXEMPT'
              ? sales.sup_amount?.[thisYear]
              : sales.tot_amount?.[thisYear]) ?? 0
          }
          lastYearValueSales={
            (user?.selectedCompany?.tax_type !== 'EXEMPT'
              ? sales.sup_amount?.[lastYear]
              : sales.tot_amount?.[lastYear]) ?? 0
          }
          thisYearValuePurchase={
            (user?.selectedCompany?.tax_type !== 'EXEMPT'
              ? purchase.sup_amount?.[thisYear]
              : purchase.tot_amount?.[thisYear]) ?? 0
          }
          lastYearValuePurchase={
            (user?.selectedCompany?.tax_type !== 'EXEMPT'
              ? purchase.sup_amount?.[lastYear]
              : purchase.tot_amount?.[lastYear]) ?? 0
          }
          thisYearValueLabor={labor[thisYear] ?? 0}
          lastYearValueLabor={labor[lastYear] ?? 0}
        />
      );
    }
  }
};

export default ReportGraph;
