import '@src/scss/main.scss';
import Input from '@src/components/Input.tsx';
import React from 'react';
import Button from '@src/components/Button.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { API_URL, API_URLS, URLS } from '@src/util/constants.ts';
import { z } from 'zod';
import { IApiError, IApiFindId } from '@src/util/interfaces.ts';
import api from '@src/util/api.ts';
import { useMediaQuery } from 'react-responsive';
import Label from '@src/components/Label';
import { useNavigate } from 'react-router-dom';
import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';

interface FindIdProps {}

interface IFindIdData {
  resident_number: string;
}

const FindIdForm = z.object({
  registrationNumber1: z
    .string()
    .min(3, '첫번째 사업자번호는 3자리 숫자여야 합니다.')
    .max(3, '첫번째 사업자번호는 3자리 숫자여야 합니다.'),
  registrationNumber2: z
    .string()
    .min(2, '두번째 사업자번호는 2자리 숫자여야 합니다.')
    .max(2, '두번째 사업자번호는 2자리 숫자여야 합니다.'),
  registrationNumber3: z
    .string()
    .min(5, '세번째 사업자번호는 5자리 숫자여야 합니다.')
    .max(5, '세번째 사업자번호는 5자리 숫자여야 합니다.'),
});

type TLoginForm = z.infer<typeof FindIdForm>;

export const FindId: React.FC<FindIdProps> = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TLoginForm>({
    resolver: zodResolver(FindIdForm),
  });

  const registrationNumber1 = watch('registrationNumber1');
  const registrationNumber2 = watch('registrationNumber2');
  const registrationNumber3 = watch('registrationNumber3');

  const isDisabled =
    !registrationNumber1 ||
    !registrationNumber2 ||
    !registrationNumber3 ||
    Boolean(
      errors.registrationNumber1 ||
        errors.registrationNumber2 ||
        errors.registrationNumber3,
    );

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const navigate = useNavigate();
  const {
    mutate,
    error,
    isError,
    isPending: isLoading,
  } = useMutation<IApiFindId, IApiError, IFindIdData>({
    mutationFn: (formData) => {
      return api.post(API_URL + API_URLS.FIND_ID, formData);
    },
  });

  const onSubmit: SubmitHandler<TLoginForm> = (data) => {
    const resident_number = `${data.registrationNumber1}-${data.registrationNumber2}-${data.registrationNumber3}`;
    mutate(
      { resident_number },
      {
        onSuccess: (response) => {
          const { email } = response.data;
          navigate(`${URLS.FIND_ID_COMPLETE}?email=${email}`, {
            replace: true,
          });
        },
        onError: (error) => {
          console.log(error);
        },
      },
    );
  };

  return (
    <Container
      header={isMobile ? <DetailHeader title={'아이디 찾기'} /> : <></>}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="email-container-wrapper">
          <div className="description-container">
            <h2 className="email-desktop-title">아이디 찾기</h2>
          </div>
          <div className="input-container">
            <div className="input-label-container">
              <Label text="사업자번호" required={true} />
              <div className="find-email-input-wrapper">
                <Input
                  type={'number'}
                  register={register('registrationNumber1')}
                  isLoading={false}
                  customClassName={'email-input-custom'}
                  maxLength={3}
                  inputMode={'numeric'}
                />
                <p>-</p>
                <Input
                  type={'number'}
                  register={register('registrationNumber2')}
                  isLoading={false}
                  customClassName={'email-input-custom'}
                  maxLength={2}
                  inputMode={'numeric'}
                />
                <p>-</p>
                <Input
                  type={'number'}
                  register={register('registrationNumber3')}
                  isLoading={false}
                  customClassName={'email-input-custom'}
                  maxLength={5}
                  inputMode={'numeric'}
                />
              </div>
            </div>
            {errors.registrationNumber1 && (
              <div className="error-message">
                {errors.registrationNumber1?.message}
              </div>
            )}
            {errors.registrationNumber2 && (
              <div className="error-message">
                {errors.registrationNumber2?.message}
              </div>
            )}
            {errors.registrationNumber3 && (
              <div className="error-message">
                {errors.registrationNumber3?.message}
              </div>
            )}
            {isError && (
              <div className="error-message">
                {error?.response?.data?.message}
              </div>
            )}
            <>
              <div style={{ height: '16px' }}></div>
              <Button
                text="다음"
                isLoading={isLoading}
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabled}
              />
            </>
          </div>
        </div>
      </form>
    </Container>
  );
};

export default FindId;
