import { useState } from 'react';
import { ILaborAdmin } from '@src/util/interfaces.ts';
import Select, { OptionValue } from '@src/components/Select.tsx';
import { convertToKST, generateOptions } from '@src/util/functions.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { COLORS, laborKeywordSearchFilterOption } from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import Table from '@src/components/Table.tsx';
import { useAdminStore } from '@src/util/adminStore.ts';
import { useBranch, useLaborAdmin } from '@src/util/adminHooks.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { z } from 'zod';

const LaborAdminContent = () => {
  const KeywordSearchForm = z.object({
    keyword: z.string(),
    keywordSearchFilter: z.object({
      label: z.string(),
      value: z.string(),
    }),
  });

  type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;

  const [admin] = useAdminStore((state) => [state.admin]);

  const [branchFilter, setBranchFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'company_name' | 'resident_number' | 'name' | 'phone_number'
  >('company_name');

  const { data, fetchNextPage, hasNextPage } = useLaborAdmin(
    branchFilter,
    keyword,
    keywordSearchCategory,
  );

  const flattenedData = data?.pages.flatMap((page) => page.data);

  const { data: branchData } = useBranch();

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '사업자명', value: '사업자명' },
    },
  });

  const columnHelper = createColumnHelper<ILaborAdmin>();

  const columns: Array<ColumnDef<ILaborAdmin, string>> = [
    columnHelper.accessor('company.branch', {
      id: 'company.branch',
      cell: (info) => {
        return <i>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '88px' }}>소속지점</div>,
    }),
    columnHelper.accessor('company.name', {
      id: 'company.name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '160px' }}>사업자명</div>,
    }),
    columnHelper.accessor('company.resident_number', {
      id: 'company.resident_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>사업자등록번호</div>,
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: (info) => <i>{convertToKST(info.getValue())}</i>,
      header: () => <div style={{ minWidth: '68px' }}>신청일</div>,
    }),
    columnHelper.accessor('tot_amount', {
      id: 'tot_amount',
      cell: (info) => <i>{info.getValue().toLocaleString()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>산출된 노무지원금</div>,
    }),
    columnHelper.accessor('user_name', {
      id: 'user_name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '68px' }}>담당자 성함</div>,
    }),
    columnHelper.accessor('phone_number', {
      id: 'phone_number',
      cell: (info) => (
        <i>{info.getValue().trim() === '' ? '-' : info.getValue()}</i>
      ),
      header: () => <div style={{ minWidth: '68px' }}>담당자 연락처</div>,
    }),
  ];

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('company_name');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    } else if (keywordSearchCategoryValue === '담당자 성함') {
      setKeywordSearchCategory('name');
    } else if (keywordSearchCategoryValue === '담당자 연락처') {
      setKeywordSearchCategory('phone_number');
    }
    setKeyword(data.keyword);
  };

  return (
    <Table<ILaborAdmin>
      data={flattenedData}
      columns={columns}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      filterContent={
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={'table-filter-container'}>
            {admin?.branch === '본점' && (
              <div style={{ minWidth: '60px' }}>
                <Select
                  name={'filterBranch'}
                  options={branchData ? generateOptions(branchData.data) : []}
                  onChange={(selectedOption) => {
                    setBranchFilter(selectedOption);
                  }}
                  value={branchFilter}
                  size={'lg'}
                  label={'소속지점'}
                />
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={'table-filter-keyword-search-container'}>
                <div style={{ width: '128px' }}>
                  <Controller
                    name="keywordSearchFilter"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          name="keywordSearchFilter"
                          options={laborKeywordSearchFilterOption}
                          onChange={onChange}
                          value={value}
                          isFilterSearch={true}
                        />
                      );
                    }}
                  />
                </div>

                <Input
                  placeholder={'검색어 입력'}
                  register={register('keyword')}
                  style={{
                    height: 'auto',
                    borderRadius: '0',
                    borderLeft: 'none',
                    width: '414px',
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault(); // Prevent the default behavior of the enter key
                      handleSubmit(onSubmit)(); // Execute the submit handler
                    }
                  }}
                />
                <Icon
                  iconSrc={searchIcon}
                  style={{
                    padding: '10px',
                    border: `1px solid ${COLORS['gray2']}`,
                    borderLeft: 'none',
                    borderBottomRightRadius: '4px',
                    borderTopRightRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </form>
      }
    />
  );
};

export default LaborAdminContent;
