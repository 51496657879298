import { useNavigate, useParams } from 'react-router-dom';
import { useTaskDetailAdmin } from '@src/util/adminHooks.ts';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  API_URLS_ADMIN,
  COLORS,
  FILE_ICONS,
  TASK_MENU,
  TASK_MENU_COLOR_CONVERT,
  TASK_MENU_CONVERT,
} from '@src/util/constants.ts';
import { formatFileSize, getFileExtension } from '@src/util/functions.ts';
import Icon from '@src/components/Icon.tsx';
import moment from 'moment';
import Modal from '@src/components/Modal.tsx';
import apiAdmin from '@src/util/apiAdmin.ts';
import Snackbar from '@src/components/SnackBar.tsx';

interface TaskDetailAdminContentProps {
  snackBarOpen: boolean;
  deleteModalOpen: boolean;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
}
const TaskDetailAdminContent: React.FC<TaskDetailAdminContentProps> = ({
  snackBarOpen,
  deleteModalOpen,
  setDeleteModalOpen,
}) => {
  const { id = '' } = useParams<{
    id: string;
  }>();
  const navigate = useNavigate();

  const { data } = useTaskDetailAdmin(id);

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  return (
    <>
      <div className={'task-detail-wrapper-container'}>
        <div
          className="task-detail-container"
          style={{
            maxHeight: 'calc(100vh - 360px)',
            overflowY: 'auto',
            paddingBottom: '80px',
          }}
        >
          <div className={'task-detail-row'}>
            <p
              className={'text-regular-bold'}
              style={{ width: '68px', boxSizing: 'border-box' }}
            >
              상태
            </p>
            <p
              className={'text-regular-bold'}
              style={{
                color:
                  TASK_MENU_COLOR_CONVERT[
                    data.status as keyof typeof TASK_MENU
                  ],
              }}
            >
              {TASK_MENU_CONVERT[data.status as keyof typeof TASK_MENU]}
            </p>
          </div>
          <div className={'task-detail-row'}>
            <p
              className={'text-regular-bold'}
              style={{ width: '68px', boxSizing: 'border-box' }}
            >
              제목
            </p>
            <p className={'text-regular'} style={{ color: COLORS['gray6'] }}>
              {data.title}
            </p>
          </div>
          <div className={'task-detail-row'}>
            <p
              className={'text-regular-bold'}
              style={{ width: '68px', boxSizing: 'border-box' }}
            >
              내용
            </p>
            <p
              className={'text-regular'}
              style={{ color: COLORS['gray6'], whiteSpace: 'pre-line' }}
            >
              {data.description}
            </p>
          </div>
          <div className={'task-detail-row'}>
            <p
              className={'text-regular-bold'}
              style={{ width: '68px', boxSizing: 'border-box' }}
            >
              첨부파일
            </p>
            <div className={'flex-column-flex-start-flex-start'}>
              {data.attachments.map((item, i) => {
                const type = getFileExtension(item.filename);
                return (
                  <div className={'task-detail-icon-row'} key={i}>
                    <Icon
                      iconSrc={FILE_ICONS[type as keyof typeof FILE_ICONS]}
                      style={{ width: '20px', height: '20px' }}
                      imgStyle={{ width: '20px', height: '20px' }}
                    />
                    <p className={'text-regular'}>
                      {item.filename} ({formatFileSize(item.size)})
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={'task-detail-row'}>
            <p
              className={'text-regular-bold'}
              style={{ width: '68px', boxSizing: 'border-box' }}
            >
              요청일
            </p>
            <p className={'text-regular'}>
              {moment(data.created_at).format('YYYY-MM-DD')}
            </p>
          </div>
          <div className={'task-detail-row'}>
            <p
              className={'text-regular-bold'}
              style={{ width: '68px', boxSizing: 'border-box' }}
            >
              수정일
            </p>
            <p className={'text-regular'}>
              {moment(data.updated_at).format('YYYY-MM-DD')}
            </p>
          </div>
        </div>
      </div>
      <>
        <Modal
          isOpen={deleteModalOpen}
          onOk={() => {
            setDeleteModalOpen(false);
            apiAdmin
              .delete(API_URLS_ADMIN.TASK + `/${id}`)
              .then((res) => {
                console.log(res);
                setDeleteConfirmModalOpen(true);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          title={'업무 요청 삭제'}
          content={'업무 요청을 삭제하시겠습니까?'}
          okButton={'삭제'}
          cancelButton={'취소'}
          onCancel={() => setDeleteModalOpen(false)}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
        />
        <Modal
          isOpen={deleteConfirmModalOpen}
          onOk={() => {
            setDeleteConfirmModalOpen(false);
            navigate(-1);
          }}
          title={'업무 요청 삭제'}
          content={'업무 요청이 삭제되었습니다.'}
          okButton={'확인'}
          onClose={() => {
            setDeleteConfirmModalOpen(false);
          }}
        />
        <Snackbar
          message="댓글이 삭제되었습니다."
          open={snackBarOpen}
          type={'info'}
        />
      </>
    </>
  );
};

export default TaskDetailAdminContent;
