import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import api from '@src/util/api';
import { useUserStore } from '@src/util/store';
import {
  ICert,
  ICertificateIssuedList,
  ICustomer,
  IExpectedPaymentApi,
  IReceiptApi,
  IReportDashboard,
  IReportGraph,
  ITaskDetailQuery,
  ITaskList,
  ITaxPaidApi,
} from '@src/util/interfaces';
import { useLocation } from 'react-router-dom';
import { Moment } from 'moment';
import {
  getFirstDayOfFifthPreviousDays,
  getFirstDayOfFirstMonth,
  getFirstDayOfLastYear,
  getFirstDayOfMonth,
  getLastDayOfLastMonth,
  getLastDayOfMonth,
  getNextFiveBusinessDays,
  isNotEmpty,
} from '@src/util/functions';
import { API_URLS, REPORT_TERM } from '@src/util/constants';
import { getFirstDayOfFifthPreviousMonth } from '@src/util/functions';
import moment from 'moment/moment';
import { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';

export const useReportAPI = (date: Moment, activeHeaderTab: string) => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);

  const company_id = user?.selectedCompany?.id;
  const data_type = 'all';
  let end_date = date.clone().format('YYYYMMDD');

  const select_type = activeHeaderTab;

  let start_date: string;
  const today = moment();

  let firstDayReport: string;
  if (activeHeaderTab === REPORT_TERM.MONTHLY) {
    start_date = getFirstDayOfMonth(date);
    if (
      date.clone().month() !== today.clone().month() ||
      date.clone().year() !== today.clone().year()
    ) {
      end_date = getLastDayOfMonth(date);
    }
    firstDayReport = getFirstDayOfFifthPreviousMonth(date);
  } else if (activeHeaderTab === REPORT_TERM.YEARLY) {
    start_date = getFirstDayOfFirstMonth(date);
    if (date.clone().year() !== today.clone().year()) {
      end_date = getLastDayOfLastMonth(date);
    }
    firstDayReport = getFirstDayOfLastYear(date);
  } else {
    start_date = end_date;
    firstDayReport = getFirstDayOfFifthPreviousDays(date);
  }
  const dashboardQuery = useSuspenseQuery<IReportDashboard>({
    queryKey: [
      'report',
      company_id,
      start_date,
      end_date,
      data_type,
      select_type,
    ],
    queryFn: () => {
      return api.get(
        `/v1/report/dashboard/${company_id}/${start_date}/${end_date}/${data_type}/${select_type}`,
      );
    },
    staleTime: 30 * 60 * 1000, // 30분 캐싱
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const graphQuery = useSuspenseQuery<IReportGraph>({
    queryKey: [
      'report',
      company_id,
      firstDayReport,
      end_date,
      data_type,
      select_type,
    ],
    queryFn: () =>
      api.get(
        `/v1/report/graph/${company_id}/${firstDayReport}/${end_date}/${select_type}`,
      ),
    staleTime: 30 * 60 * 1000, // 30분 캐싱
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return {
    dashboardData: dashboardQuery.data,
    graphData: graphQuery.data,
  };
};

export const useDashboardAPI = (date: Moment, activeHeaderTab: string) => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);

  const company_id = user?.selectedCompany?.id;
  const data_type = 'all';
  let end_date = date.clone().format('YYYYMMDD');

  const select_type = activeHeaderTab;

  let start_date: string;
  const today = moment();

  if (activeHeaderTab === REPORT_TERM.MONTHLY) {
    start_date = getFirstDayOfMonth(date);
    if (
      date.clone().month() !== today.clone().month() ||
      date.clone().year() !== today.clone().year()
    ) {
      end_date = getLastDayOfMonth(date);
    }
  } else if (activeHeaderTab === REPORT_TERM.YEARLY) {
    start_date = getFirstDayOfFirstMonth(date);
    if (date.clone().year() !== today.clone().year()) {
      end_date = getLastDayOfLastMonth(date);
    }
  } else {
    start_date = end_date;
  }
  const dashboardQuery = useSuspenseQuery<IReportDashboard>({
    queryKey: [
      'report',
      company_id,
      start_date,
      end_date,
      data_type,
      select_type,
    ],
    queryFn: () => {
      return api.get(
        `/v1/report/dashboard/${company_id}/${start_date}/${end_date}/${data_type}/${select_type}`,
      );
    },
    staleTime: 30 * 60 * 1000, // 30분 캐싱
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return {
    dashboardData: dashboardQuery.data,
  };
};

export const useCertificateIssued = () => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);
  const company_id = user?.selectedCompany?.id;
  const page_no = 1;
  const page_size = 1000;
  const axiosConfig: AxiosRequestConfig = {
    params: {
      company_id: company_id,
      page_no: page_no,
      page_size: page_size,
    },
  };

  return useSuspenseQuery<ICertificateIssuedList>({
    queryKey: ['certificateIssued', company_id, page_no, page_size],
    queryFn: () => api.get('/v1/communication/document', axiosConfig),
    refetchOnWindowFocus: false,
    retry: 2,
  });
};

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const useCommunicationTask = () => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);

  const company_id = user?.selectedCompany?.id;
  const start_date = moment().subtract(1, 'years').format('YYYYMM');
  const page_no = 1;
  const page_size = 1000;
  const axiosConfig: AxiosRequestConfig = {
    params: {
      company_id: company_id,
      start_date: start_date,
      page_no: page_no,
      page_size: page_size,
    },
  };

  return useSuspenseQuery<ITaskList>({
    queryKey: ['task', company_id, page_no, page_size],
    queryFn: () => api.get(API_URLS.TASK, axiosConfig),
    refetchOnWindowFocus: false,
  });
};

export const useCommunicationTaskDetail = (task_id: string) => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);
  const company_id = user?.selectedCompany?.id;

  const communicationTaskDetailQuery = useSuspenseQuery<ITaskDetailQuery>({
    queryKey: ['task', task_id],
    queryFn: () => api.get(API_URLS.TASK + `/${company_id}/${task_id}`, {}),
    refetchOnWindowFocus: false,
  });

  return {
    data: communicationTaskDetailQuery.data.data,
  };
};

export const useTaxPaid = (type: string, date: string) => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);

  const company_id = user?.selectedCompany?.id;
  let urlPath = API_URLS.TAX_PAID + `/${company_id}/${type}`;

  if (type !== 'debt') {
    urlPath = urlPath + `?date=${date}`;
  }

  return useSuspenseQuery<ITaxPaidApi>({
    queryKey: ['taxPaid', type, date, company_id],
    queryFn: () => api.get(urlPath),
    staleTime: 30 * 60 * 1000, // 30분 캐싱
    refetchOnWindowFocus: false,
  });
};

export const useReceipt = () => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);

  const company_id = user?.selectedCompany?.id;
  const page_size = 1000;
  const axiosConfig: AxiosRequestConfig = {
    params: {
      company_id: company_id,
      page_size: page_size,
    },
  };

  return useSuspenseQuery<IReceiptApi>({
    queryKey: ['receipt', company_id, page_size],
    queryFn: async () => await api.get(API_URLS.RECEIPT, axiosConfig),
    refetchOnWindowFocus: false,
  });
};

export const useExpectedPayment = () => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);
  const company_id = user?.selectedCompany?.id;
  const days = getNextFiveBusinessDays();
  const start_date = days[0].format('YYYYMMDD');
  const end_date = days[days.length - 1].format('YYYYMMDD');

  return useSuspenseQuery<IExpectedPaymentApi>({
    queryKey: ['expected-payment', company_id, start_date, end_date],
    queryFn: () =>
      api.get(
        API_URLS.EXPECTED_PAYMENT + `/${company_id}/${start_date}/${end_date}`,
      ),
    staleTime: 30 * 60 * 1000, // 30분 캐싱
    // enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });
};

export const useExpectedPaymentSingle = (date: string | null) => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);
  const company_id = user?.selectedCompany?.id;
  const start_date = date;
  const end_date = date;

  return useSuspenseQuery<IExpectedPaymentApi>({
    queryKey: ['expected-payment', company_id, start_date, end_date],
    queryFn: () =>
      api.get(
        API_URLS.EXPECTED_PAYMENT + `/${company_id}/${start_date}/${end_date}`,
      ),
    staleTime: 30 * 60 * 1000, // 30분 캐싱
    refetchOnWindowFocus: false,
  });
};

export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export const useCustomerSearch = (
  keyword: string = '',
  keywordSearchCategory: 'txprDscmNo' | 'txprNm' | 'rprsFnm' = 'txprDscmNo',
) => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);
  const company_id = user?.selectedCompany?.id;
  const urlPath = API_URLS.CUSTOMER_SEARCH + `/${company_id}`;

  const axiosConfig: AxiosRequestConfig = {
    params: {},
  };

  if (isNotEmpty(keyword)) {
    axiosConfig.params[keywordSearchCategory] = keyword;
  }

  return useQuery<ICustomer[]>({
    queryKey: ['customer', company_id, keyword, keywordSearchCategory],
    queryFn: async () => {
      const { data } = await api.get(urlPath, axiosConfig);
      return data;
    },
    retry: false,
  });
};

export const useCert = () => {
  const [user] = useUserStore((state) => [state.user, state.setUser]);
  const company_id = user?.selectedCompany?.id;
  return useQuery<ICert>({
    queryKey: [`cert`, company_id],
    queryFn: () => api.get(API_URLS.CERT + `/${company_id}`),
    staleTime: 30 * 60 * 1000, // 30분
    enabled: !!user && !!user.selectedCompany,
    refetchOnWindowFocus: false,
  });
};
