import { useState } from 'react';
import Input from '@src/components/Input.tsx';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  COLORS,
  customerKeywordSearchFilterOption,
} from '@src/util/constants.ts';
import { ICustomer } from '@src/util/interfaces.ts';
import Select from '@src/components/Select.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import Table from '@src/components/Table.tsx';
import { useCustomerSearch } from '@src/util/hooks.ts';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import CustomerSearchDetailDrawer from '@src/components/CustomerSearchDetailDrawer.tsx';
import { formatBusinuessNumber } from '@src/util/functions.ts';
import TailSpinner from '@src/components/TailSpinner.tsx';

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;

const CustomerSearchContent = () => {
  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'txprDscmNo' | 'txprNm' | 'rprsFnm'
  >('txprNm');
  const [keyword, setKeyword] = useState<string>('');
  const { data, isPending } = useCustomerSearch(keyword, keywordSearchCategory);

  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>({
    id: 0,
    txprDscmNoEncCntn: '',
    tnmNm: '',
    rprsFnm: '',
    pfbAdr: '',
    bcNm: '',
    itmNm: '',
    frsRgtDtm: '',
    prcpClplcYn: '',
    mchrgEmlAdr: '',
    dList: [],
  });
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  const navigate = useNavigate();

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '거래처명', value: '거래처명' },
    },
  });

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '거래처등록번호') {
      setKeywordSearchCategory('txprDscmNo');
    } else if (keywordSearchCategoryValue === '거래처명') {
      setKeywordSearchCategory('txprNm');
    } else if (keywordSearchCategoryValue === '대표자명') {
      setKeywordSearchCategory('rprsFnm');
    }
    setKeyword(data.keyword);
  };

  const columnHelper = createColumnHelper<ICustomer>();

  const columns: Array<ColumnDef<ICustomer, string>> = [
    columnHelper.accessor('tnmNm', {
      id: 'tnmNm',
      header: () => <div>거래처명</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('txprDscmNoEncCntn', {
      id: 'txprDscmNoEncCntn',
      header: () => <div>거래처등록번호</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '100px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {formatBusinuessNumber(info.getValue().toString())}
        </i>
      ),
    }),
    columnHelper.accessor('rprsFnm', {
      id: 'rprsFnm',
      header: () => <div>대표자</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '80px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
  ];

  const handleCloseAndNavigateBack = () => {
    setDetailModalOpen(false);
    setTimeout(() => {
      navigate(-1);
    }, 0);
  };

  const renderContent = () => {
    if (isPending) {
      return <TailSpinner type={'full-page'} />;
    } else {
      if (data && data?.length > 0) {
        return (
          <Table<ICustomer>
            data={data}
            columns={columns}
            filterContent={
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'table-filter-container'}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '100px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={customerKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '180px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </form>
            }
            onRowClickCustom={(row) => {
              setSelectedCustomer(row.original);
              setDetailModalOpen(true);
            }}
          />
        );
      } else {
        return (
          <p className={'text-regular-up'} style={{ textAlign: 'center' }}>
            조회되는 거래처가 없습니다.
          </p>
        );
      }
    }
  };

  return (
    <>
      <div className={'customer-search-container'}>
        <div className={'customer-search-content'}>{renderContent()}</div>
      </div>
      <CustomerSearchDetailDrawer
        open={detailModalOpen}
        setOpen={setDetailModalOpen}
        selectedCustomer={selectedCustomer}
        onCloseAndNavigateBack={handleCloseAndNavigateBack}
      />
    </>
  );
};

export default CustomerSearchContent;
