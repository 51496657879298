import React from 'react';
import { useParams } from 'react-router-dom';
import { CERTIFICATES_REQUEST_MENU_LIST } from '@src/util/constants.ts';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import CertificateFormContent0 from './CertificateFormContent0.tsx';
import CertificateFormContent1 from './CertificateFormContent1.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container';
import CertificateFormContent2 from '@src/pages/certificates/CertificateFormContent2';
import CertificateFormContent3 from '@src/pages/certificates/CertificateFormContent3';
import CertificateFormContent4 from '@src/pages/certificates/CertificateFormContent4';
import CertificateFormContent5 from '@src/pages/certificates/CertificateFormContent5';
import CertificateFormContent6 from '@src/pages/certificates/CertificateFormContent6';
import CertificateFormContent7 from '@src/pages/certificates/CertificateFormContent7';
import CertificateFormContent8 from '@src/pages/certificates/CertificateFormContent8';
import CertificateFormContent9 from '@src/pages/certificates/CertificateFormContent9';
import CertificateFormContent10 from '@src/pages/certificates/CertificateFormContent10';
import CertificateFormContent11 from '@src/pages/certificates/CertificateFormContent11';
import CertificateFormContent12 from '@src/pages/certificates/CertificateFormContent12';
import CertificateFormContent13 from '@src/pages/certificates/CertificateFormContent13';
import SideBar from '@src/components/SideBar.tsx';

interface CertificatesFormProps {}

const CertificatesForm: React.FC<CertificatesFormProps> = () => {
  const { type = '' } = useParams<{ type: string }>();

  const renderCertificateFormContents = (type: number) => {
    switch (type) {
      case 0:
        return <CertificateFormContent0 />;
      case 1:
        return <CertificateFormContent1 />;
      case 2:
        return <CertificateFormContent2 />;
      case 3:
        return <CertificateFormContent3 />;
      case 4:
        return <CertificateFormContent4 />;
      case 5:
        return <CertificateFormContent5 />;
      case 6:
        return <CertificateFormContent6 />;
      case 7:
        return <CertificateFormContent7 />;
      case 8:
        return <CertificateFormContent8 />;
      case 9:
        return <CertificateFormContent9 />;
      case 10:
        return <CertificateFormContent10 />;
      case 11:
        return <CertificateFormContent11 />;
      case 12:
        return <CertificateFormContent12 />;
      case 13:
        return <CertificateFormContent13 />;
      default:
        return <CertificateFormContent0 />;
    }
  };

  return (
    <Container
      header={<DetailHeader title={'증명서 발급 신청'} />}
      headerTab={
        <HeaderTabs
          menus={CERTIFICATES_REQUEST_MENU_LIST.filter(
            (menu) => menu.id === type,
          )}
          activeHeaderTab={type}
        />
      }
      sideBar={<SideBar />}
    >
      {renderCertificateFormContents(parseInt(type))}
    </Container>
  );
};

export default CertificatesForm;
