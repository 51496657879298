import React from 'react';
import chevronRight from '@src/assets/icons/report/chevron_right.svg';
import { REPORT_TERM } from '@src/util/constants.ts';
import Icon from '@src/components/Icon.tsx';
import { useLocation, useNavigate } from 'react-router-dom';

interface ReportCardTotalProps {
  data: {
    sales: string;
    purchase: string;
    labor: string;
    total: string;
    activeCardHeaderTab: string;
    activeHeaderTab: string;
  };
}

const ReportCardTotal: React.FC<ReportCardTotalProps> = ({
  data: { sales, purchase, labor, total, activeCardHeaderTab, activeHeaderTab },
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClickItems = () => {
    navigate(`/reports/additional/${activeHeaderTab}/${activeCardHeaderTab}`);
  };
  return (
    <>
      <div className="tab-card-statement">{total}원</div>
      <div className="tab-card-statement-details">
        <div
          className="items"
          style={{ color: '#3182F6' }}
          onClick={() => onClickItems()}
        >
          <div>매출</div>
          <div className="item-right">
            <span>{sales}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
        <div
          className="items"
          style={{ color: '#F96464' }}
          onClick={() => onClickItems()}
        >
          <div>매입</div>
          <div className="item-right">
            <span>{purchase}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
        {activeHeaderTab === REPORT_TERM.YEARLY && (
          <div
            className="items"
            style={{ color: '#F96464' }}
            onClick={() => onClickItems()}
          >
            <div>인건비</div>
            <div className="item-right">
              <span>{labor}원</span>
              {location.pathname === '/reports' && (
                <Icon iconSrc={chevronRight} iconText={'>'} />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportCardTotal;
