import Table from '@src/components/Table.tsx';
import {
  IApiError,
  IApiPOSTSuccess,
  IClient,
} from '@src/util/interfaces.ts';
import Select, { OptionValue } from '@src/components/Select.tsx';
import { convertToKST, generateOptions, isEmpty } from '@src/util/functions.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  API_URLS_ADMIN,
  clientKeywordSearchFilterOption,
  COLORS,
  TaxTypes,
} from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import apiAdmin from '@src/util/apiAdmin.ts';
import Button from '@src/components/Button.tsx';
import IconSVG from '@src/components/IconSVG.tsx';
import {useCallback, useState} from 'react';
import { useAdminStore } from '@src/util/adminStore.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useBranch, useClient } from '@src/util/adminHooks.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Snackbar from '@src/components/SnackBar.tsx';
import Modal from '@src/components/Modal.tsx';
import Divider from '@src/components/Divider.tsx';
import CustomDatePicker from "@src/components/CustomDatePicker";
import { ko } from 'date-fns/locale/ko';

import { default as ReactSelect, components, OptionProps, GroupBase } from "react-select";


const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

const PasswordVerifyForm = z.object({
  password: z.string(),
});

const PasswordVerifyFormServer = PasswordVerifyForm.extend({
  type: z.string(),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;
type TPasswordVerifyForm = z.infer<typeof PasswordVerifyForm>;
type TPasswordVerifyFormServer = z.infer<typeof PasswordVerifyFormServer>;

const Option = (props: OptionProps<OptionValue, true, GroupBase<OptionValue>>) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const options = [
  {
    label: '전체',
    value: 'ALL',
  },
  {
    label: '홈택스',
    value: 'HOMETAX',
  },
  {
    label: '홈택스 현금',
    value: 'HOMETAX_CASH',
  },
  {
    label: '홈택스 계산서',
    value: 'HOMETAX_STATEMENT',
  },
  {
    label: '홈택스 카드',
    value: 'HOMETAX_CARD',
  },
  {
    label: '홈택스 인건비',
    value: 'HOMETAX_LABOR',
  },
  {
    label: '홈택스 세금납부내역',
    value: 'HOMETAX_PAYMENT',
  },
  {
    label: '홈택스 수출',
    value: 'HOMETAX_EXPORT',
  },
  {
    label: '여신금융',
    value: 'FINANCING',
  },
  {
    label: '카드',
    value: 'CARD',
  },
  {
    label: '배민',
    value: 'BM',
  },
  {
    label: '쿠팡이츠',
    value: 'CPE',
  },
  {
    label: '요기요',
    value: 'YO',
  },
  {
    label: '네이버',
    value: 'NAVER',
  },
  {
    label: '쿠팡',
    value: 'COUPANG',
  },
] as OptionValue[]

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const getYesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

const BatchContent = () => {
  const [approvedStatusFilter, setApprovedStatusFilter] =
    useState<OptionValue | null>({
      label: '전체',
      value: '전체',
    });

  const [startDate, setStartDate] = useState(getYesterday());
  const [endDate, setEndDate] = useState(getYesterday());

  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const handleCheckboxChange = (company_id: number) => {
    setSelectedCompanies((prevSelected) =>
      prevSelected.includes(company_id)
        ? prevSelected.filter((id) => id !== company_id)
        : [...prevSelected, company_id]
    );
  };

  const [selectedOptions, setSelectedOptions] = useState<{ optionSelected: OptionValue[] }>({ optionSelected: [] });
  const [openBatchModal, setOpenBatchModal] = useState<boolean>(false);

  const handleChange = (selected: OptionValue[]) => {
    setSelectedOptions({
      optionSelected: selected
    });
  };

  const [admin] = useAdminStore((state) => [state.admin]);

  const [branchFilter, setBranchFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });

  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'name' | 'user_name' | 'resident_number' | 'user_email'
  >('user_name');

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [deleteCompanyNo, setDeleteCompanyNo] = useState<number | null>(null);
  const [scrapCompanyNo, setScrapCompanyNo] = useState<number | null>(null);
  const [confirmRetryDailyBatchModal, setConfirmRetryDailyBatchModal] =
    useState<boolean>(false);
  const [verifyPasswordModal, setVerifyPasswordModal] =
    useState<boolean>(false); // OPEN, CLOSE,
  const [verifyPasswordType, setVerifyPasswordType] = useState<
    'delete' | 'retryDailyBatch' | null
  >(null);

  const queryClient = useQueryClient();

  const columnHelper = createColumnHelper<IClient>();

  const columns: Array<ColumnDef<IClient, string>> = [
    columnHelper.display({
      id: 'checkbox',
      header: ({ table }) => (
        <input
          type="checkbox"
          onChange={(e) => {
            const isChecked = e.target.checked;
            const visibleRows = table.getCoreRowModel().rows.map(row => row.original.id);
            const newSelected = isChecked ? visibleRows : [];
            setSelectedCompanies(newSelected);
          }}
          checked={table.getCoreRowModel().rows.length > 0 && table.getCoreRowModel().rows.every(row => selectedCompanies.includes(row.original.id))}
        />
      ),
      cell: (info) => (
        <input
          type="checkbox"
          checked={selectedCompanies.includes(info.row.original.id)}
          onChange={() => handleCheckboxChange(info.row.original.id)}
        />
      ),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      cell: (info) => {
        return <i>{convertToKST(info.getValue())}</i>;
      },
      header: () => <div style={{ minWidth: '68px' }}>가입일</div>,
    }),
    columnHelper.accessor('branch', {
      id: 'branch',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '68px' }}>소속지점</div>,
    }),
    columnHelper.accessor('tax_type', {
      id: 'tax_type',
      cell: (info) => (
        <i>{TaxTypes[info.getValue() as keyof typeof TaxTypes]}</i>
      ),
      header: () => <div style={{ minWidth: '68px' }}>과세 유형</div>,
    }),
    columnHelper.accessor('user.name', {
      id: 'user_name',
      cell: (info) => {
        const kakaoId = info.row.original.user.kakao_id;
        const password = info.row.original.user.password;

        let color;
        if (!isEmpty(kakaoId) && !isEmpty(password)) {
          color = COLORS['green'];
        } else if (isEmpty(kakaoId)) {
          color = COLORS['primary'];
        } else {
          color = '#FEE500';
        }

        return <i style={{ color: color }}>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '68px' }}>대표자</div>,
    }),
    columnHelper.accessor('user.email', {
      id: 'user.email',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '68px' }}>대표자이메일</div>,
    }),
    columnHelper.accessor('resident_number', {
      id: 'resident_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>사업자등록번호</div>,
    }),
    columnHelper.accessor('name', {
      id: 'name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '160px' }}>사업자명</div>,
    }),
    columnHelper.display({
      id: 'sync',
      cell: (props) => {
        return (
          <div className={'flex-row-center'}>
            <IconSVG
              fill={COLORS['primary']}
              size={'24'}
              iconSVG={'sync'}
              containerStyle={{ cursor: 'pointer' }}
              onClick={() => {
                setScrapCompanyNo(props.row.original.id);
              }}
            />
          </div>
        );
      },
      header: () => <div style={{ minWidth: '28px' }}>재연동</div>,
    }),
  ];

  const { data, fetchNextPage, hasNextPage } = useClient(
    approvedStatusFilter,
    branchFilter,
    keyword,
    keywordSearchCategory,
  );
  const flattenedData = data.pages.flatMap((page) => page.data);
  const userCnt = data?.pages[0].userCnt;
  const companyCnt = data?.pages[0].companyCnt;

  const { data: branchData } = useBranch();

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '대표자명', value: '대표자명' },
    },
  });

  const {
    register: registerVerifyPassword,
    handleSubmit: handleSubmitVerifyPassword,
    reset: resetForm,
  } = useForm<TPasswordVerifyForm>({
    resolver: zodResolver(PasswordVerifyForm),
  });

  const {
    mutate,
    isPending: passwordVerifyIsLoading,
    error,
    reset: resetMutation,
  } = useMutation<IApiPOSTSuccess, IApiError, TPasswordVerifyFormServer>({
    mutationFn: (formData) => {
      return apiAdmin.post(API_URLS_ADMIN.CHECK_ADMIN_PASSWORD, formData);
    },
  });

  // const { mutate: downloadReceipt, isPending: downloadReceiptLoading } =
  //   useMutation<IApiReceiptDownloadSuccess, IApiError, TDownloadReceipt>({
  //     mutationFn: (formData) => {
  //       return apiAdmin.get(
  //         API_URLS_ADMIN.RECEIPT_DOWNLOAD + `/${formData.receiptCompanyNo}`,
  //         { responseType: 'blob' },
  //       );
  //     },
  //   });

  const { mutate: retryDailyBatch, isPending: retryDailyBatchIsLoading } =
    useMutation({
      mutationFn: () => {
        return apiAdmin.post(API_URLS_ADMIN.DAILY_BATCH);
      },
    });

  const periodOptionsBatch = useCallback(async (data: any) => {
    await apiAdmin.post(API_URLS_ADMIN.PERIOD_BATCH_OPTION, data)
  }, [])

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '대표자명') {
      setKeywordSearchCategory('user_name');
    } else if (keywordSearchCategoryValue === '대표자이메일') {
      setKeywordSearchCategory('user_email');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    } else if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('name');
    }
    setKeyword(data.keyword);
  };

  const onSubmitVerifyPassword: SubmitHandler<TPasswordVerifyForm> = (data) => {
    const formData = {
      type: 'ADMIN',
      password: data.password,
    };
    mutate(formData, {
      onSuccess: (response) => {
        console.log(response);
        setVerifyPasswordModal(false);
        if (verifyPasswordType === 'delete') {
          apiAdmin
            .delete(API_URLS_ADMIN.CLIENT + `/${deleteCompanyNo}`)
            .then((res) => {
              console.log(res);
              setSnackBarContent(
                `${flattenedData?.filter(
                  (item) => item.id === deleteCompanyNo,
                )[0].name}가 삭제되었습니다.`,
              );
              setSnackBarOpen(true);
              setTimeout(() => {
                setSnackBarOpen(false);
              }, 3000);
              resetForm();
              setDeleteCompanyNo(null);

              queryClient.invalidateQueries({ queryKey: ['client'] });
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (verifyPasswordType === 'retryDailyBatch') {
          retryDailyBatch(undefined, {
            onSuccess: () => {
              setSnackBarContent('금일 배치가 재실행 되었습니다.');
              setSnackBarOpen(true);
              setTimeout(() => {
                setSnackBarOpen(false);
              }, 3000);
            },
            onError: (error) => {
              setSnackBarContent(`금일 배치 재실행 오류: ${error.message}`);
              setSnackBarOpen(true);
              setTimeout(() => {
                setSnackBarOpen(false);
              }, 3000);
            },
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        setVerifyPasswordType(null);
      },
    });
  };

  const handleDailyBatchBtnClick = () => {
    setConfirmRetryDailyBatchModal(true);
  };

  return (
    <>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{
          gap: '24px',
          margin: '0 48px 48px 48px',
        }}
      >
        <div className={'flex-row-space-between-center'}>
          <p className={'text-md'}>배치 관리</p>
          <div className={'flex-row-center'} style={{ gap: '20px' }}>
            <Button
              text={'금일배치 재실행'}
              style={{ padding: '10px' }}
              onClick={handleDailyBatchBtnClick}
              isLoading={retryDailyBatchIsLoading}
            />
            <div className={'flex-column-flex-start-stretch'}>
              <p className={'text-regular'} style={{ whiteSpace: 'nowrap' }}>
                유저수: {userCnt}
              </p>
              <p className={'text-regular'} style={{ whiteSpace: 'nowrap' }}>
                회사수: {companyCnt}
              </p>
            </div>
          </div>
        </div>
        <Table<IClient>
          data={flattenedData}
          columns={columns}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          filterContent={
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={'table-filter-container'}>
                <div style={{ minWidth: '60px' }}>
                  <Select
                    name={'filterStatus'}
                    options={[
                      {
                        label: '전체',
                        value: '전체',
                      },
                      {
                        label: '승인',
                        value: '승인',
                      },
                      {
                        label: '미승인',
                        value: '미승인',
                      },
                    ]}
                    onChange={(selectedOption) => {
                      setApprovedStatusFilter(selectedOption);
                    }}
                    value={approvedStatusFilter}
                    size={'lg'}
                    label={'승인상태'}
                  />
                </div>

                {admin?.branch === '본점' && (
                  <div style={{ minWidth: '60px' }}>
                    <Select
                      name={'filterBranch'}
                      options={
                        branchData ? generateOptions(branchData.data) : []
                      }
                      onChange={(selectedOption) => {
                        setBranchFilter(selectedOption);
                      }}
                      value={branchFilter}
                      size={'lg'}
                      label={'소속지점'}
                    />
                  </div>
                )}

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={'table-filter-keyword-search-container-batch-page'}>
                    <div style={{ width: '128px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={clientKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '414px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent the default behavior of the enter key
                          handleSubmit(onSubmit)(); // Execute the submit handler
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className={'text-sm'} style={{ paddingBottom: '5px' }}>
                      시작일
                    </label>
                    <CustomDatePicker
                      className={'date-picker'}
                      value={startDate}
                      onChange={(date) =>
                        setStartDate(date)
                      }
                      locale={ko}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className={'text-sm'} style={{ paddingBottom: '5px' }}>
                      종료일
                    </label>
                    <CustomDatePicker
                      className={'date-picker'}
                      value={endDate}
                      onChange={(date) =>
                        setEndDate(date)
                      }
                      locale={ko}
                    />
                  </div>
                </div>

                <ReactSelect
                  options={options}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option
                  }}
                  onChange={value => handleChange(value as OptionValue[])}
                  value={selectedOptions.optionSelected}
                  styles={
                    {
                      container: (provided) => ({
                        ...provided,
                        minWidth: '250px',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: 'auto',
                        minWidth: '100%',
                        zIndex: 9999,
                      }),
                    }
                  }
                />

                <Button
                  text={'조건 배치 실행'}
                  style={{ padding: '10px', width: '100px' }}
                  onClick={() => setOpenBatchModal(true)}
                />

              </div>
            </form>
          }
        />
      </div>
      <>
        <Snackbar message={snackBarContent} open={snackBarOpen} type={'info'} />
        <Modal
          onClose={() => {
            setVerifyPasswordModal(false);
            setDeleteCompanyNo(null);
          }}
          isOpen={verifyPasswordModal}
          body={
            <form onSubmit={handleSubmitVerifyPassword(onSubmitVerifyPassword)}>
              <div
                className={'modal-custom-wrapper'}
                style={{ paddingBottom: 0 }}
              >
                <div className={'modal-custom-content-wrapper'}>
                  <h2 className={'modal-custom-title'}>비밀번호 확인</h2>
                </div>
                <div className={'modal-input-wrapper'}>
                  <Input
                    register={registerVerifyPassword('password')}
                    type={'password'}
                    placeholder={'비밀번호 입력'}
                  />
                  {error && (
                    <div className="error-message">
                      {error?.response?.data?.message}
                    </div>
                  )}
                </div>
              </div>
              <Divider
                customClassName="overflow-divider-modal"
                style={{ marginTop: '20px' }}
              />
              <div className={'custom-modal-two-button-wrapper'}>
                <Button
                  customClassName={'left-button'}
                  text={'취소'}
                  type={'button'}
                  onClick={() => {
                    resetForm();
                    resetMutation();
                    setVerifyPasswordModal(false);
                    setDeleteCompanyNo(null);
                  }}
                />
                <Button
                  customClassName={'right-button'}
                  type={'submit'}
                  text={'확인'}
                  isLoading={passwordVerifyIsLoading}
                />
              </div>
            </form>
          }
        />
        <Modal
          isOpen={confirmRetryDailyBatchModal}
          onOk={() => {
            setConfirmRetryDailyBatchModal(false);
            setVerifyPasswordType('retryDailyBatch');
            setVerifyPasswordModal(true);
          }}
          title={'금일배치 재실행'}
          content={'금일배치를 재실행 하시겠습니까?'}
          okButton={'확인'}
          cancelButton={'취소'}
          onCancel={() => {
            setConfirmRetryDailyBatchModal(false);
          }}
          onClose={() => {
            setConfirmRetryDailyBatchModal(false);
          }}
        />
        <Modal
          isOpen={openBatchModal}
          onOk={() => {
            periodOptionsBatch({
              company_ids: selectedCompanies.length == 0 ? null : selectedCompanies,
              types: selectedOptions.optionSelected.map(item => item.value),
              start_date: formatDate(startDate),
              end_date: formatDate(endDate),
            })
            setOpenBatchModal(false);
          }}
          title={'조건 배치 실행'}
          content={`
            조건 배치를 실행 하시겠습니까?
            회사 : ${selectedCompanies.length == 0 ? 'ALL' : selectedCompanies.reduce((string, item) => {
              string += `${item}, `
              return string;
            }, '')}
            시작일 : ${formatDate(startDate)}
            종료일 : ${formatDate(endDate)}
            옵션 : ${selectedOptions.optionSelected.reduce((string, item) => {
              string += `${item.label}, `
              return string;
            }, '')}
          `}
          okButton={'확인'}
          cancelButton={'취소'}
          onCancel={() => {
            setOpenBatchModal(false);
          }}
          onClose={() => {
            setOpenBatchModal(false);
          }}
        />
        <Modal
          isOpen={scrapCompanyNo !== null}
          onOk={() => {
            apiAdmin
              .post(API_URLS_ADMIN.COMPANY_SCRAP + `/${scrapCompanyNo}`)
              .then((res) => {
                console.log(res);
                queryClient
                  .invalidateQueries({ queryKey: ['client', 1, 1000] })
                  .then(() => {
                    setSnackBarContent(
                      `${
                        flattenedData.filter(
                          (item) => item.id === scrapCompanyNo,
                        )[0].name
                      }가 재연동되었습니다.`,
                    );
                    setSnackBarOpen(true);
                    setTimeout(() => {
                      setSnackBarOpen(false);
                    }, 3000);
                  });
                setScrapCompanyNo(null);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          title={'고객 정보 재연동'}
          content={'고객정보를 재연동 하겠습니까?'}
          okButton={'확인'}
          cancelButton={'취소'}
          onCancel={() => setScrapCompanyNo(null)}
          onClose={() => setScrapCompanyNo(null)}
        />
      </>
    </>
  );
};

export default BatchContent;
