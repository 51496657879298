import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';
import { API_URLS_ADMIN, COLORS } from '@src/util/constants.ts';
import { useAdminStore } from '@src/util/adminStore.ts';
import React, { useCallback, useState } from 'react';
import CustomModal from '@src/components/CustomModal.tsx';
import { useMutation } from '@tanstack/react-query';
import apiAdmin, { refreshToken } from '@src/util/apiAdmin.ts';
import { logoutLogicAdmin, passwordRegex } from '@src/util/functions.ts';
import { useNavigate } from 'react-router-dom';
import IconSVG from '@src/components/IconSVG.tsx';
import InputLabel from '@src/components/InputLabel.tsx';
import Input from '@src/components/Input.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@src/components/Button.tsx';
import Snackbar from '@src/components/SnackBar.tsx';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import TailSpinner from '@src/components/TailSpinner.tsx';

const PasswordVerifyForm = z.object({
  password: z.string().min(1),
});

const PasswordChangeForm = z
  .object({
    password: z.string().regex(passwordRegex, '비밀번호가 유효하지 않습니다.'),
    password_confirm: z.string(),
  })
  .refine((data) => data.password === data.password_confirm, {
    message: '비밀번호가 일치하지 않습니다.',
    path: ['password_confirm'],
  });

type TPasswordVerifyForm = z.infer<typeof PasswordVerifyForm>;
type TPasswordChangeForm = z.infer<typeof PasswordChangeForm>;

const MyPageAdmin = () => {
  const [admin] = useAdminStore((state) => [state.admin]);
  const [modalTitle, setModalTitle] = useState('상호');
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [myInfoModalOpen, setMyInfoModalOpen] = useState(false);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<TPasswordVerifyForm>({
    resolver: zodResolver(PasswordVerifyForm),
    defaultValues: {
      password: '',
    },
  });

  const {
    register: changePasswordRegister,
    handleSubmit: changePasswordHandleSubmit,
    reset: changePasswordReset,
    formState: { errors: changePasswordErrors },
  } = useForm<TPasswordChangeForm>({
    resolver: zodResolver(PasswordChangeForm),
  });

  const navigate = useNavigate();

  const handleMyInfoModalOpen = useCallback(
    (title: string, key: string, value = '') => {
      setModalTitle(title);
      setKey(key);
      setValue(value);
      setMyInfoModalOpen(true);
    },
    [],
  );
  const { mutate: updateUserInfo } = useMutation({
    mutationFn: (data: { key: string; value: string }) =>
      apiAdmin.patch(API_URLS_ADMIN.MY_PAGE_DETAIL + `/${admin?.id}`, {
        [data.key]: data.value,
      }),
    onSuccess: async () => {
      refreshToken().finally(() => {
        setMyInfoModalOpen(false);
      });
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const { mutate: changePassword, isPending: changePasswordLoading } =
    useMutation<IApiPOSTSuccess, IApiError, TPasswordVerifyForm>({
      mutationFn: (formData) => {
        return apiAdmin.patch(
          API_URLS_ADMIN.MY_PAGE_DETAIL + `/${admin?.id}`,
          formData,
        );
      },
    });

  const { mutate: verifyPassword, isPending: verifyPasswordLoading } =
    useMutation<IApiPOSTSuccess, IApiError, TPasswordVerifyForm>({
      mutationFn: (formData) => {
        return apiAdmin.post(
          API_URLS_ADMIN.CHECK_PASSWORD + `/${admin?.id}`,
          formData,
        );
      },
    });

  const onSubmitPasswordChange: SubmitHandler<TPasswordChangeForm> = (data) => {
    if (isPasswordVerified) {
      changePassword(
        { password: data.password },
        {
          onSuccess: (res) => {
            console.log(res);
            setIsPasswordVerified(false);
            setPasswordChangeModalOpen(false);
            reset();
            changePasswordReset();
            setSnackBarType('info');
            setSnackBarContent('비밀번호가 변경 되었습니다.');
            setSnackBarOpen(true);
            setTimeout(() => {
              setSnackBarOpen(false);
            }, 3000);
          },
          onError: (err) => {
            console.log(err);
            setSnackBarType('error');
            setSnackBarContent(err.response.data.message);
            setSnackBarOpen(true);
            setTimeout(() => {
              setSnackBarOpen(false);
            }, 3000);
          },
        },
      );
    }
  };

  const onSubmit: SubmitHandler<TPasswordVerifyForm> = (data) => {
    if (!isPasswordVerified) {
      verifyPassword(data, {
        onSuccess: (res) => {
          if (res.data) {
            setIsPasswordVerified(true);
            setSnackBarType('info');
            setSnackBarContent('비밀번호가 인증 되었습니다.');
            setSnackBarOpen(true);
            setTimeout(() => {
              setSnackBarOpen(false);
            }, 3000);
          } else {
            setSnackBarType('error');
            setSnackBarContent('기존 비밀번호를 확인해주세요.');
            setSnackBarOpen(true);
            setTimeout(() => {
              setSnackBarOpen(false);
            }, 3000);
          }
        },
        onError: (err) => {
          console.log(err);
          setSnackBarType('error');
          setSnackBarContent('기존 비밀번호를 확인해주세요.');
          setSnackBarOpen(true);
          setTimeout(() => {
            setSnackBarOpen(false);
          }, 3000);
        },
      });
    }
  };

  return (
    <Container
      sideBar={<AdminSideBar />}
      modal={
        <>
          <Snackbar
            message={snackBarContent}
            open={snackBarOpen}
            type={snackBarType}
          />
          <CustomModal
            onClose={() => setMyInfoModalOpen(false)}
            isOpen={myInfoModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <h2
                      className={'modal-custom-title'}
                    >{`${modalTitle} 수정`}</h2>
                  </div>
                  <div className={'modal-input-wrapper'}>
                    <input
                      className={'modal-input'}
                      value={value}
                      placeholder={modalTitle}
                      onChange={(e) => setValue(e.target.value)}
                      type={key !== 'password' ? 'text' : 'password'}
                    />
                  </div>
                </div>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <div className={'custom-modal-button-wrapper'}>
                  <button
                    className={'custom-modal-button'}
                    onClick={() => updateUserInfo({ key, value })}
                  >
                    확인
                  </button>
                </div>
              </React.Fragment>
            }
          />

          <CustomModal
            onClose={() => {
              reset();
              changePasswordReset();
              setIsPasswordVerified(false);
              setPasswordChangeModalOpen(false);
            }}
            isOpen={passwordChangeModalOpen}
            children={
              <React.Fragment>
                <div className={'modal-custom-wrapper'}>
                  <div className={'modal-custom-content-wrapper'}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        marginBottom: '16px',
                      }}
                    >
                      <h2 className={'modal-custom-title'}>비밀번호 변경</h2>
                      <IconSVG
                        fill={COLORS['main']}
                        size={'24'}
                        iconSVG={'close'}
                        containerStyle={{ cursor: 'pointer' }}
                        onClick={() => {
                          reset({}, { keepDirty: false });
                          changePasswordReset({}, { keepDirty: false });
                          setIsPasswordVerified(false);
                          setPasswordChangeModalOpen(false);
                        }}
                      />
                    </div>

                    <InputLabel
                      required
                      text={'기존 비밀번호'}
                      description={
                        isPasswordVerified
                          ? '영문자(대,소문자), 숫자를 포함하여 최소 8자 이상 15자 이하로 작성 해야 합니다.'
                          : ''
                      }
                      error={
                        errors.password && (
                          <p className={'error-message'}>
                            비밀번호가 유효하지 않습니다.
                          </p>
                        )
                      }
                    >
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={'flex-row'} style={{ gap: '10px' }}>
                          <Input
                            register={register('password')}
                            isLoading={false}
                            inputMode={'text'}
                            type={'password'}
                            placeholder={'기존 비밀번호'}
                            style={{
                              backgroundColor: COLORS['gray0'],
                              flex: 1,
                            }}
                            disabled={
                              isPasswordVerified || verifyPasswordLoading
                            }
                          />
                          <Button
                            text={isPasswordVerified ? '인증완료' : '인증하기'}
                            style={{ width: '96px' }}
                            type={'submit'}
                            disabled={isPasswordVerified || !isDirty}
                            isLoading={verifyPasswordLoading}
                          />
                        </div>
                      </form>

                      {isPasswordVerified && (
                        <form
                          id={'change-password-form'}
                          onSubmit={changePasswordHandleSubmit(
                            onSubmitPasswordChange,
                          )}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'stretch',
                          }}
                        >
                          <InputLabel
                            required
                            text={'새 비밀번호'}
                            error={
                              changePasswordErrors.password ? (
                                <p className={'error-message'}>
                                  {changePasswordErrors.password.message}
                                </p>
                              ) : changePasswordErrors.password_confirm ? (
                                <p className={'error-message'}>
                                  {
                                    changePasswordErrors.password_confirm
                                      .message
                                  }
                                </p>
                              ) : (
                                <></>
                              )
                            }
                          >
                            <Input
                              register={changePasswordRegister('password')}
                              inputMode={'text'}
                              type={'password'}
                              placeholder={'새 비밀번호 입력'}
                              style={{
                                backgroundColor: COLORS['gray0'],
                                flex: 1,
                              }}
                            />
                            <Input
                              register={changePasswordRegister(
                                'password_confirm',
                              )}
                              inputMode={'text'}
                              type={'password'}
                              placeholder={'새 비밀번호 재입력'}
                              style={{
                                backgroundColor: COLORS['gray0'],
                                flex: 1,
                              }}
                            />
                          </InputLabel>
                        </form>
                      )}
                    </InputLabel>
                  </div>
                </div>
                {changePasswordLoading ? (
                  <div
                    className={'flex-row-center'}
                    style={{ alignSelf: 'stretch', padding: '10px' }}
                  >
                    <TailSpinner type={'full-page'} />
                  </div>
                ) : isPasswordVerified ? (
                  <React.Fragment>
                    <div className={'custom-modal-two-button-wrapper'}>
                      <button
                        className={'left-button'}
                        onClick={() => {
                          reset(undefined, { keepDirty: false });
                          changePasswordReset();
                          setIsPasswordVerified(false);
                          setPasswordChangeModalOpen(false);
                        }}
                      >
                        취소
                      </button>
                      <button
                        className={'right-button'}
                        type={'submit'}
                        form={'change-password-form'}
                      >
                        변경하기
                      </button>
                    </div>
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </React.Fragment>
            }
          />
        </>
      }
    >
      <div
        className={'flex-column-flex-start-stretch'}
        style={{
          gap: '24px',
          margin: '0 48px 48px 48px',
        }}
      >
        <p className={'text-md'}>마이 페이지</p>
        <div className={'flex-column-flex-start-stretch'}>
          <p className={'text-regular-bold'}>세부 정보</p>
          <div className={'my-page-admin-detail-contents'}>
            <p
              className={'text-regular-bold'}
              style={{ color: COLORS['gray4'], width: '182px' }}
            >
              지점명
            </p>
            <p className={'text-regular'} style={{ width: '282px' }}>
              {admin?.branch}
            </p>
            {admin?.branch === '본점' && (
              <button
                onClick={() => {
                  handleMyInfoModalOpen('지점', 'branch', admin.branch);
                }}
              >
                <span
                  className={'text-regular-bold'}
                  style={{ fontSize: '12px' }}
                >
                  수정
                </span>
              </button>
            )}
          </div>
          <div className={'my-page-admin-detail-contents'}>
            <p
              className={'text-regular-bold'}
              style={{ color: COLORS['gray4'], width: '182px' }}
            >
              사업자등록번호
            </p>
            <p className={'text-regular'} style={{ width: '282px' }}>
              {admin?.resident_number}
            </p>
            <button
              onClick={() => {
                handleMyInfoModalOpen(
                  '사업자등록번호',
                  'resident_number',
                  admin?.resident_number,
                );
              }}
            >
              <span
                className={'text-regular-bold'}
                style={{ fontSize: '12px' }}
              >
                수정
              </span>
            </button>
          </div>
          <div className={'my-page-admin-detail-contents'}>
            <p
              className={'text-regular-bold'}
              style={{ color: COLORS['gray4'], width: '182px' }}
            >
              ID
            </p>
            <p className={'text-regular'} style={{ width: '282px' }}>
              {admin?.admin_id}
            </p>
          </div>
          <div className={'my-page-admin-detail-contents'}>
            <p
              className={'text-regular-bold'}
              style={{ color: COLORS['gray4'], width: '182px' }}
            >
              연락처
            </p>
            <p className={'text-regular'} style={{ width: '282px' }}>
              {admin?.phone_number}
            </p>
            <button
              onClick={() => {
                handleMyInfoModalOpen(
                  '연락처',
                  'phone_number',
                  admin?.phone_number,
                );
              }}
            >
              <span
                className={'text-regular-bold'}
                style={{ fontSize: '12px' }}
              >
                수정
              </span>
            </button>
          </div>
        </div>
        <div className={'flex-column-flex-start-stretch'}>
          <p className={'text-regular-bold'}>계정 관리</p>
          <button
            className={'text-regular-bold'}
            style={{
              color: COLORS['gray4'],
              width: '182px',
              padding: '22px 0',
              cursor: 'pointer',
              textAlign: 'left',
            }}
            onClick={() => {
              logoutLogicAdmin(navigate);
            }}
          >
            로그아웃
          </button>
          <button
            className={'text-regular-bold'}
            style={{
              color: COLORS['gray4'],
              width: '182px',
              padding: '22px 0',
              cursor: 'pointer',
              textAlign: 'left',
            }}
            onClick={() => {
              setPasswordChangeModalOpen(true);
            }}
          >
            비밀번호 변경
          </button>
        </div>
      </div>
    </Container>
  );
};

export default MyPageAdmin;
