import React, { useEffect, useState } from 'react';
import SearchBar from '@src/components/SearchBar.tsx';
import Button from '@src/components/Button.tsx';
import { TAX_MENU, TAX_MENU_LIST } from '@src/util/constants.ts';
import { useNavigate } from 'react-router-dom';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import SideBar from '@src/components/SideBar.tsx';
import Footer from '@src/components/Footer.tsx';
import Container from '@src/layouts/Container.tsx';
import TaskContent from '@src/pages/tax/TaskContent.tsx';
import FilterModal from '@src/components/FilterModal.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import { refreshToken } from '@src/util/api.ts';

interface TaskProps {}

const Task: React.FC<TaskProps> = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const filterOptions = ['전체', '요청', '진행', '피드백', '완료', '보류'];
  const [selectedFilterValue, setSelectedFilterValue] =
    useState<string>('전체');
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <Container
      header={<Header setOpen={setOpen} />}
      headerTab={
        <HeaderTabs menus={TAX_MENU_LIST} activeHeaderTab={TAX_MENU.TASK} />
      }
      sideBar={<SideBar />}
      footer={<Footer current={'tax'} />}
      modal={
        <>
          <CompanyDrawer open={open} setOpen={setOpen} />
          <FilterModal
            filterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
            filterOptions={filterOptions}
            selectedFilterValue={selectedFilterValue}
            setSelectedFilterValue={setSelectedFilterValue}
          />
        </>
      }
    >
      <div className={'task-wrapper-container'}>
        <div className={'task-container'}>
          <div className="task-search-bar-container">
            <SearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <Button
              text={'업무 요청'}
              onClick={() => {
                navigate('/tax/task/request');
              }}
              style={{ padding: '10px', whiteSpace: 'nowrap' }}
            />
          </div>
          <div className="task-title-container">
            <p className="text-regular-bold">처리 현황</p>
          </div>
          <TaskContent
            searchValue={searchValue}
            setFilterModalOpen={setFilterModalOpen}
            selectedFilterValue={selectedFilterValue}
          />
        </div>
      </div>
    </Container>
  );
};

export default Task;
