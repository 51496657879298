import React from 'react';
import chevronRight from '@src/assets/icons/report/chevron_right.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '@src/components/Icon.tsx';

interface ReportCardSalesProps {
  data: {
    statement: string;
    card: string;
    cash: string;
    commerceAndDelivery: string;
    total: string;
  };
  activeCardHeaderTab: string;
  activeHeaderTab: string;
}

const ReportCardSales: React.FC<ReportCardSalesProps> = ({
  data: { statement, card, cash, commerceAndDelivery, total },
  activeHeaderTab,
  activeCardHeaderTab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClickItems = (type: string) => {
    navigate(
      `/reports/additional/${activeHeaderTab}/${activeCardHeaderTab}?type=${type}`,
    );
  };

  return (
    <>
      <div className="tab-card-statement">{total}원</div>
      <div className="tab-card-statement-details">
        <div className="items" onClick={() => onClickItems('statement')}>
          <div>세금계산서 / 계산서 등</div>
          <div className="item-right">
            <span>{statement}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
        <div className="items" onClick={() => onClickItems('card')}>
          <div>카드</div>
          <div className="item-right">
            <span>{card}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
        <div className="items" onClick={() => onClickItems('cash')}>
          <div>현금영수증</div>
          <div className="item-right">
            <span>{cash}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
        <div
          className="items"
          onClick={() => onClickItems('commerceAndDelivery')}
        >
          <div>커머스 / 배달앱 등</div>
          <div className="item-right">
            <span>{commerceAndDelivery}원</span>
            {location.pathname === '/reports' && (
              <Icon iconSrc={chevronRight} iconText={'>'} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportCardSales;
