import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../scss/components/_react-datepicker-custom.scss';
import { Locale } from 'date-fns';
import { forwardRef } from 'react';

interface ReactDatePickerProps {
  value: Date | null;
  onChange: (date: Date) => void;
  className?: string;
  locale?: Locale;
  dateFormat?: string;
}

const ReactDatePicker = forwardRef<DatePicker, ReactDatePickerProps>(
  ({ value, onChange, className, locale, dateFormat = 'yyyy-MM-dd' }, ref) => {
    return (
      <div className={'custom-datepicker-wrapper'}>
        <DatePicker
          ref={ref}
          selected={value}
          onChange={onChange}
          className={className}
          dateFormat={dateFormat}
          locale={locale}
          onFocus={(e) => e.target.blur()}
        />
      </div>
    );
  },
);

export default ReactDatePicker;
