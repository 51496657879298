import '@src/scss/main.scss';
import Input from '@src/components/Input.tsx';
import React, { useEffect } from 'react';
import CheckBox from '@src/components/CheckBox.tsx';
import Button from '@src/components/Button.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { API_URL, URLS } from '@src/util/constants.ts';
import { z } from 'zod';
import { IApiAuthSuccess, IApiError } from '@src/util/interfaces.ts';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { IUser, useUserStore } from '@src/util/store.ts';
import api from '@src/util/api.ts';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import InputLabel from '@src/components/InputLabel.tsx';

interface EmailLoginProps {}

const LoginForm = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: '이메일을 입력해 주세요.' })
    .email({ message: '이메일 형태가 아닙니다.' }),
  password: z.string().trim().min(1, { message: '비밀번호를 입력해 주세요.' }),
  rememberId: z.boolean(),
  autoLogin: z.boolean(),
});

const LoginFormServer = LoginForm.omit({
  rememberId: true,
  autoLogin: true,
});

type TLoginForm = z.infer<typeof LoginForm>;
type TLoginFormServer = z.infer<typeof LoginFormServer>;

export const EmailLogin: React.FC<EmailLoginProps> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<TLoginForm>({
    resolver: zodResolver(LoginForm),
    defaultValues: {
      email: Cookies.get('email') ? Cookies.get('email') : '',
      rememberId: true,
      autoLogin: true,
    },
  });

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const {
    mutate,
    error,
    isError,
    isPending: isLoading,
  } = useMutation<IApiAuthSuccess, IApiError, TLoginFormServer>({
    mutationFn: (formData) => {
      return api.post(API_URL + '/v1/auth/users/login', formData);
    },
  });
  const navigate = useNavigate();
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      if (user?.role?.reports) {
        navigate('/reports', { replace: true });
      } else {
        navigate('/menu', { replace: true });
      }
    }
  }, [navigate]);

  const onSubmit: SubmitHandler<TLoginForm> = (data) => {
    const formData = {
      email: data.email,
      password: data.password,
    };

    mutate(formData, {
      onSuccess: (response) => {
        const { accessToken, refreshToken, user: userResponse } = response.data;
        const selectedCompany =
          userResponse?.companies?.length !== 0
            ? userResponse?.companies?.[0]
            : null;

        const user: IUser = {
          ...userResponse,
          selectedCompany,
        };

        if (data.autoLogin) {
          Cookies.set('accessToken', accessToken, { expires: 7 });
          Cookies.set('refreshToken', refreshToken, { expires: 7 });
        } else {
          Cookies.set('accessToken', accessToken);
          Cookies.set('refreshToken', refreshToken);
          Cookies.set('autoLogin', 'N');
        }

        if (data.rememberId) {
          Cookies.set('email', data.email, { expires: 7 }); // 아이디 저장
        } else {
          Cookies.remove('email');
        }

        if (!user?.current_manager) user.current_manager = undefined;
        setUser(user);
        if (user.selectedCompany === null) {
          navigate('/register/company', { replace: true });
        } else if (user?.role?.reports) {
          navigate('/reports', { replace: true });
        } else {
          navigate('/menu', { replace: true });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <Container
      header={isMobile ? <DetailHeader title={'이메일 로그인'} /> : <></>}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="email-container-wrapper">
          <div className="description-container">
            <h2 className="email-desktop-title">이메일 로그인</h2>
            <span>
              촘촘택스 회원 로그인을 위해 <br />
              메일과 비밀번호를 입력해 주세요.
            </span>
          </div>
          <div className="input-container">
            <InputLabel
              error={
                errors.email && (
                  <div className="error-message">
                    {errors.email?.message?.toString()}
                  </div>
                )
              }
            >
              <Input
                placeholder="이메일"
                register={register('email')}
                customClassName="email-input-custom"
                isLoading={isLoading}
              />
            </InputLabel>
            <Input
              placeholder="비밀번호"
              register={register('password')}
              customClassName="password-input-custom"
              type={'password'}
              isLoading={isLoading}
            />
            {errors.password && (
              <div className="error-message">
                {errors.password?.message?.toString()}
              </div>
            )}
            {isError && (
              <div className="error-message">
                {error?.response?.data?.message}
              </div>
            )}
            <div className="login-detail-checkboxes">
              <Controller
                name={'rememberId'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CheckBox
                    checked={value}
                    onChange={onChange}
                    label={'아이디 저장'}
                    size={'sm'}
                  />
                )}
              />
              <Controller
                name={'autoLogin'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CheckBox
                    checked={value}
                    onChange={onChange}
                    label={'자동 로그인'}
                    size={'sm'}
                  />
                )}
              />
            </div>

            <Button
              text="로그인"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            />
            <div className="login-forgot-container">
              <Link to={URLS.FIND_ID}>아이디 찾기</Link>
              <p className="divider-line">|</p>
              <Link to={URLS.FIND_PW}>비밀번호 찾기</Link>
              <p className="divider-line">|</p>
              <Link to={URLS.REGISTER_EMAIL}>이메일로 회원가입</Link>
            </div>
          </div>
        </div>
      </form>
    </Container>
  );
};

export default EmailLogin;
