import React, { useState } from 'react';
import InputLabel from '@src/components/InputLabel.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from '@src/components/Select.tsx';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import {
  generateLastYearsOptions,
  generateTxnrmStrtYm,
  objectToQueryString,
} from '@src/util/functions.ts';
import api from '@src/util/api.ts';
import { API_URL } from '@src/util/constants.ts';
import { z } from 'zod';
import CheckBox from '@src/components/CheckBox.tsx';
import RadioButton from '@src/components/RadioButton.tsx';
import moment from 'moment/moment';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';

interface CertificateFormContent8Props {}

/**
 *  매출처별 / 매입처별 세금계산서 합계표
 *    구분*: type에서 분리되어 있음.
 *    년도*: wrtYr -- 이전 5개 년도만 가능
 *    분기별*: wrtQt
 */

const CertificateForm8 = z.object({
  type: z.enum(['매출', '매입']),
  wrtYr: z.object({
    label: z.string(),
    value: z.enum(generateTxnrmStrtYm(moment().year(), 5)),
  }),
  q1: z.boolean(),
  q2: z.boolean(),
  q3: z.boolean(),
  q4: z.boolean(),
});

const CertificateForm8Server = CertificateForm8.omit({
  type: true,
  q1: true,
  q2: true,
  q3: true,
  q4: true,
}).extend({
  company_id: z.number(),
  type: z.string(),
  wrtQt: z.string(),
  wrtYr: z.string(),
});

type TCertificateForm8 = z.infer<typeof CertificateForm8>;
type TCertificateForm8Server = z.infer<typeof CertificateForm8Server>;
const CertificateFormContent8: React.FC<CertificateFormContent8Props> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
  } = useForm<TCertificateForm8>({
    resolver: zodResolver(CertificateForm8),
    mode: 'onChange',
    defaultValues: {
      type: '매출',
      q1: false,
      q2: false,
      q3: false,
      q4: false,
    },
  });

  const { mutate, isSuccess, error, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TCertificateForm8Server
  >({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);

      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });
  const q1Value = watch('q1');
  const q2Value = watch('q2');
  const q3Value = watch('q3');
  const q4Value = watch('q4');
  const onSubmit: SubmitHandler<TCertificateForm8> = (data) => {
    const isAnyQuarterSelected = data.q1 || data.q2 || data.q3 || data.q4;

    if (!isAnyQuarterSelected) {
      // Set an error for the quarter checkboxes and prevent form submission
      setError('q1', {
        type: 'custom',
        message: '분기를 선택해 주세요.',
      });
      return; // Stop the submission process
    }

    const type = `${data.type} 세금계산서합계표`;
    let wrtQt = '0';

    if (data.q1 && data.q2) {
      wrtQt = '3';
    } else if (data.q3 && data.q4) {
      wrtQt = '6';
    } else {
      if (data.q1) {
        wrtQt = '1';
      } else if (data.q2) {
        wrtQt = '2';
      } else if (data.q3) {
        wrtQt = '4';
      } else if (data.q4) {
        wrtQt = '5';
      }
    }

    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: type,
      wrtYr: data.wrtYr.value,
      wrtQt: wrtQt,
    };

    mutate(formData, {
      onSuccess: (response) => {
        setModalOpen(true);
        console.log(response);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="certificate-form-content">
          <CertificatesDescription
            title={'분기 및 반기 단위로 발급 가능합니다.'}
            description={
              '반기 단위로 신청하는 경우 상반기는 1,2분기 / 하반기는 3,4분기 선택'
            }
          />
          <InputLabel text={'구분'} required={true}>
            <Controller
              name={'type'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioButton
                  name={'type'}
                  options={[
                    { label: '매출', value: '매출' },
                    { label: '매입', value: '매입' },
                  ]}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </InputLabel>
          <InputLabel
            text={'년도'}
            required={true}
            description={
              '요청일 기준, 이전 5개 년도만 가능합니다.\n예시) 요청일이 2023.04.01인 경우 → 2023~2019년'
            }
            error={
              errors.wrtYr && (
                <div className="error-message">연도를 선택해 주세요.</div>
              )
            }
          >
            <div className="flex-row" style={{ gap: '10px' }}>
              <Controller
                name="wrtYr"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="year"
                      options={generateLastYearsOptions(moment().year(), 5)}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
              <div>
                <p className="text-regular" style={{ lineHeight: '22px' }}>
                  년
                </p>
              </div>
            </div>
          </InputLabel>
          <InputLabel
            text={'분기별'}
            required={true}
            error={
              errors.q1 && (
                <div className="error-message">
                  {errors.q1?.message?.toString()}
                </div>
              )
            }
          >
            <div className="checkbox-container">
              <div className="checkbox-row-container">
                <Controller
                  name={'q1'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CheckBox
                      checked={value}
                      onChange={onChange}
                      label={'1분기'}
                      size={'lg'}
                      disabled={q3Value || q4Value}
                    />
                  )}
                />
                <Controller
                  name={'q2'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CheckBox
                      checked={value}
                      onChange={onChange}
                      label={'2분기'}
                      size={'lg'}
                      disabled={q3Value || q4Value}
                    />
                  )}
                />
              </div>
              <div className="checkbox-row-container">
                <Controller
                  name={'q3'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CheckBox
                      checked={value}
                      onChange={onChange}
                      label={'3분기'}
                      size={'lg'}
                      disabled={q1Value || q2Value}
                    />
                  )}
                />
                <Controller
                  name={'q4'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CheckBox
                      checked={value}
                      onChange={onChange}
                      label={'4분기'}
                      size={'lg'}
                      disabled={q1Value || q2Value}
                    />
                  )}
                />
              </div>
            </div>
          </InputLabel>
        </div>
        <div className={'bottom-button-wrapper-container'}>
          <Button
            text={'신청하기'}
            isLoading={isPending}
            type={'submit'}
            disabled={isPending}
            style={{
              width: '100%',
              maxWidth: '480px',
              boxSizing: 'border-box',
              borderRadius: '3px',
            }}
          />
        </div>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={isSuccess ? '증명서 발급 성공' : '증명서 발급 실패'}
        content={
          isSuccess
            ? '증명서 발급이 완료되었습니다. \n 증명서 발급 내역 메뉴에서 다운로드 가능합니다.'
            : error?.response.data.message ?? ''
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default CertificateFormContent8;
