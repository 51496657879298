import React, { Suspense, useEffect, useState } from 'react';
import {
  CERTIFICATES_ISSUED_MENU,
  CERTIFICATES_ISSUED_MENU_LIST,
  CERTIFICATES_MENU_LIST,
  CERTIFICATES_MENU_TYPE,
} from '@src/util/constants.ts';
import OvalTabs from '@src/components/OvalTabs.tsx';
import Header from '@src/components/Header.tsx';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import SideBar from '@src/components/SideBar.tsx';
import Footer from '@src/components/Footer.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import Container from '@src/layouts/Container.tsx';
import CertificateIssuedTable from '@src/pages/certificates/CertificateIssuedTable.tsx';
import { refreshToken } from '@src/util/api.ts';
import TailSpinner from '@src/components/TailSpinner.tsx';

interface CertificateIssuedProps {}

const CertificateIssued: React.FC<CertificateIssuedProps> = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    refreshToken();
  }, []);

  const [activeCardHeaderTab, setActiveCardHeaderTab] = useState<string>(
    CERTIFICATES_ISSUED_MENU.ALL,
  );

  return (
    <Container
      header={<Header setOpen={setOpen} />}
      headerTab={
        <HeaderTabs
          menus={CERTIFICATES_MENU_LIST}
          activeHeaderTab={CERTIFICATES_MENU_TYPE.ISSUED}
        />
      }
      sideBar={<SideBar />}
      footer={<Footer current={'certificates'} />}
      modal={<CompanyDrawer open={open} setOpen={setOpen} />}
    >
      <div className={'certificate-issued-wrapper-container'}>
        <div className="certificate-issued-container">
          <OvalTabs
            menus={CERTIFICATES_ISSUED_MENU_LIST}
            activeCardHeaderTab={activeCardHeaderTab}
            setActiveCardHeaderTab={setActiveCardHeaderTab}
          />
          <div className={'certificate-issued-list-container'}>
            <div style={{ transform: 'rotateX(180deg)' }}>
              <Suspense fallback={<TailSpinner type={'full-page'} />}>
                <CertificateIssuedTable
                  activeCardHeaderTab={activeCardHeaderTab}
                />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CertificateIssued;
