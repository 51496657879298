import { useAdminStore } from '@src/util/adminStore.ts';
import { useState } from 'react';
import Select, { OptionValue } from '@src/components/Select.tsx';
import { useBranch, useTaskAdmin } from '@src/util/adminHooks.ts';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ITaskAdmin } from '@src/util/interfaces.ts';
import {
  API_URLS_ADMIN,
  COLORS,
  laborKeywordSearchFilterOption,
  TASK_MENU,
  TASK_MENU_CONVERT,
  TASK_MENU_OPTIONS,
  TASK_MENU_OPTIONS_WITH_ALL,
} from '@src/util/constants.ts';
import apiAdmin from '@src/util/apiAdmin.ts';
import { convertToKST, generateOptions } from '@src/util/functions.ts';
import Table from '@src/components/Table.tsx';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import Snackbar from '@src/components/SnackBar.tsx';
import { z } from 'zod';

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;

const TaskAdminContent = () => {
  const [admin] = useAdminStore((state) => [state.admin]);
  const [statusFilter, setStatusFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });
  const [branchFilter, setBranchFilter] = useState<OptionValue | null>({
    label: '전체',
    value: '전체',
  });
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'name' | 'resident_number'
  >('name');

  const { data, fetchNextPage, hasNextPage } = useTaskAdmin(
    statusFilter,
    branchFilter,
    keyword,
    keywordSearchCategory,
  );
  const queryClient = useQueryClient();

  const flattenedData = data?.pages.flatMap((page) => page.data);

  const { data: branchData } = useBranch();

  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '사업자명', value: '사업자명' },
    },
  });

  const columnHelper = createColumnHelper<ITaskAdmin>();

  const columns: Array<ColumnDef<ITaskAdmin, string>> = [
    columnHelper.accessor('company.branch', {
      id: 'company.branch',
      cell: (info) => {
        return <i>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '88px' }}>소속지점</div>,
    }),
    columnHelper.accessor('company.name', {
      id: 'company.name',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '160px' }}>사업자명</div>,
    }),
    columnHelper.accessor('company.resident_number', {
      id: 'company.resident_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>사업자등록번호</div>,
    }),
    columnHelper.display({
      id: 'status',
      cell: (props) => {
        return (
          <Select
            options={TASK_MENU_OPTIONS}
            value={{
              label:
                TASK_MENU_CONVERT[
                  props.row.original.status as keyof typeof TASK_MENU
                ],
              value: props.row.original.status,
            }}
            size={'sm'}
            name={'approved'}
            onChange={(selectedValue) => {
              if (selectedValue) {
                apiAdmin
                  .patch(API_URLS_ADMIN.TASK + `/${props.row.original.id}`, {
                    status: selectedValue.value,
                  })
                  .then((res) => {
                    console.log(res);
                    queryClient.invalidateQueries({ queryKey: ['taskAdmin'] });
                    setSnackBarContent(
                      `상태가 ${selectedValue.label}으로 변경되었습니다.`,
                    );
                    setSnackBarOpen(true);
                    setTimeout(() => {
                      setSnackBarOpen(false);
                    }, 3000);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }}
          />
        );
      },
      header: () => <div style={{ minWidth: '88px' }}>승인상태</div>,
    }),
    columnHelper.accessor('title', {
      id: 'title',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '240px' }}>업무명</div>,
    }),
    columnHelper.accessor('created_at', {
      id: 'createdAt',
      cell: (info) => <i>{convertToKST(info.getValue())}</i>,
      header: () => <div style={{ minWidth: '64px' }}>요청일</div>,
    }),
    columnHelper.accessor('updated_at', {
      id: 'updatedAt',
      cell: (info) => <i>{convertToKST(info.getValue())}</i>,
      header: () => <div style={{ minWidth: '64px' }}>수정일</div>,
    }),
  ];

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('name');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    }
    setKeyword(data.keyword);
  };

  return (
    <>
      <div
        className={'flex-column-flex-start-stretch'}
        style={{ gap: '24px', margin: '0 48px 48px 48px' }}
      >
        <p className={'text-md'}>업무처리 현황 관리</p>

        <Table<ITaskAdmin>
          data={flattenedData}
          columns={columns}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          onRowClick={(id) => `/admin/task/${id}`}
          filterContent={
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={'table-filter-container'}>
                <div style={{ minWidth: '60px' }}>
                  <Select
                    name={'filterStatus'}
                    options={TASK_MENU_OPTIONS_WITH_ALL}
                    onChange={(selectedOption) => {
                      setStatusFilter(selectedOption);
                    }}
                    value={statusFilter}
                    size={'lg'}
                    label={'상태'}
                  />
                </div>

                {admin?.branch === '본점' && (
                  <div style={{ minWidth: '60px' }}>
                    <Select
                      name={'filterBranch'}
                      options={
                        branchData ? generateOptions(branchData.data) : []
                      }
                      onChange={(selectedOption) => {
                        setBranchFilter(selectedOption);
                      }}
                      value={branchFilter}
                      size={'lg'}
                      label={'소속지점'}
                    />
                  </div>
                )}

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={'table-filter-keyword-search-container'}>
                    <div style={{ width: '128px' }}>
                      <Controller
                        name="keywordSearchFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name="keywordSearchFilter"
                              options={laborKeywordSearchFilterOption}
                              onChange={onChange}
                              value={value}
                              isFilterSearch={true}
                            />
                          );
                        }}
                      />
                    </div>

                    <Input
                      placeholder={'검색어 입력'}
                      register={register('keyword')}
                      style={{
                        height: 'auto',
                        borderRadius: '0',
                        borderLeft: 'none',
                        width: '414px',
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent the default behavior of the enter key
                          handleSubmit(onSubmit)(); // Execute the submit handler
                        }
                      }}
                    />
                    <Icon
                      iconSrc={searchIcon}
                      style={{
                        padding: '10px',
                        border: `1px solid ${COLORS['gray2']}`,
                        borderLeft: 'none',
                        borderBottomRightRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </form>
          }
        />
      </div>
      <Snackbar message={snackBarContent} open={snackBarOpen} type={'info'} />
    </>
  );
};

export default TaskAdminContent;
