import React, { Dispatch, SetStateAction } from 'react';
import logoIcon from '/src/assets/icons/logo-final.png';
import syncIcon from '/src/assets/icons/sync.svg';
import bellIcon from '/src/assets/icons/bell.svg';
import { COMPANY_LIST_BACKGROUND_COLOR, URLS } from '../util/constants.ts';
import { getFirstCharacter } from '../util/functions.ts';
import Icon from './Icon.tsx';
import { useUserStore } from '../util/store.ts';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = ({ setOpen }) => {
  const user = useUserStore((state) => state.user);
  // const setOpen = useCompanySelectDrawerStore((state) => state.setDrawerOpen);
  const navigate = useNavigate();

  if (user?.companies) {
    return (
      <div className="header">
        <img src={logoIcon} alt="logo" style={{ maxWidth: '110px' }} />
        <div className="header-right">
          <Icon
            iconSrc={syncIcon}
            iconText={'sync'}
            type={'header'}
            onClick={() => navigate(URLS.SYNC)}
            style={{ cursor: 'pointer' }}
          />
          <Icon
            iconSrc={bellIcon}
            iconText={'bell'}
            type={'header'}
            style={{ cursor: 'pointer' }}
          />
          <Icon
            onClick={() => {
              setOpen(true);
            }}
            style={{
              backgroundColor:
                COMPANY_LIST_BACKGROUND_COLOR[
                  user?.companies.findIndex(
                    (company) => company.id === user?.selectedCompany?.id,
                  )
                ],
              cursor: 'pointer',
            }}
            text={getFirstCharacter(user?.selectedCompany?.name)}
            type={'account'}
          />
        </div>
      </div>
    );
  }
};

export default Header;
