import React, { useState } from 'react';
import DetailHeader from '@src/components/DetailHeader.tsx';
import Container from '@src/layouts/Container.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Input from '@src/components/Input.tsx';
import { API_URLS, COLORS } from '@src/util/constants.ts';
import InputLabel from '@src/components/InputLabel.tsx';
import Button from '@src/components/Button.tsx';
import moment from 'moment/moment';
import { useMutation } from '@tanstack/react-query';
import { IApiError } from '@src/util/interfaces.ts';
import api from '@src/util/api.ts';
import Table from '@src/components/Table.tsx';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import IndustrySearchDetailDrawer from '@src/components/IndustrySearchDetailDrawer.tsx';
import { useNavigate } from 'react-router-dom';

interface IndustrySearchProps {}

const IndustrySearchForm = z.object({
  year: z.string(),
  code: z.string(),
  industry: z.string(),
});

type TIndustrySearchForm = z.infer<typeof IndustrySearchForm>;

export type TIndustrySearchResponse = {
  id: number;
  tfbCd: string;
  bcNm: string;
  tfbDclsNm: string;
};

type TIndustrySearchApiResponse = {
  data: {
    list: TIndustrySearchResponse[];
  };
};

const IndustrySearch: React.FC<IndustrySearchProps> = () => {
  const [industryList, setIndustryList] = useState<TIndustrySearchResponse[]>(
    [],
  );
  const [selectedIndustry, setSelectedIndustry] =
    useState<TIndustrySearchResponse>({
      id: 0,
      tfbCd: '',
      bcNm: '',
      tfbDclsNm: '',
    });
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const navigate = useNavigate();

  const { register, handleSubmit, getValues } = useForm<TIndustrySearchForm>({
    resolver: zodResolver(IndustrySearchForm),
    defaultValues: {
      year: moment().year().toString(),
    },
  });

  const { mutate, isPending } = useMutation<
    TIndustrySearchApiResponse,
    IApiError,
    TIndustrySearchForm
  >({
    mutationFn: (formData) => {
      return api.get(API_URLS.INDUSTRY_SEARCH, {
        params: {
          year: formData.year,
          code: formData.code,
          industry: formData.industry,
          pageSize: 50,
          pageNum: 1,
        },
      });
    },
  });

  const onSubmit: SubmitHandler<TIndustrySearchForm> = (data) => {
    mutate(data, {
      onSuccess: (res) => {
        console.log(res);
        setIndustryList(res.data.list);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };
  const columnHelper = createColumnHelper<TIndustrySearchResponse>();

  const columns: Array<ColumnDef<TIndustrySearchResponse, string>> = [
    columnHelper.accessor('tfbCd', {
      id: 'tfbCd',
      header: () => <div>업종코드</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '60px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('bcNm', {
      id: 'bcNm',
      header: () => <div>업태명</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '84px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
    columnHelper.accessor('tfbDclsNm', {
      id: 'tfbDclsNm',
      header: () => <div>세세분류명</div>,
      cell: (info) => (
        <i
          style={{
            minWidth: '116px',
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
          }}
        >
          {info.getValue()}
        </i>
      ),
    }),
  ];
  const handleCloseAndNavigateBack = () => {
    setDetailModalOpen(false);
    setTimeout(() => {
      navigate(-1);
    }, 0);
  };

  return (
    <Container
      header={<DetailHeader title={'업종코드 목록조회'} />}
      modal={
        <IndustrySearchDetailDrawer
          open={detailModalOpen}
          setOpen={setDetailModalOpen}
          selectedIndustry={selectedIndustry}
          onCloseAndNavigateBack={handleCloseAndNavigateBack}
          year={getValues('year')}
        />
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'industry-search-container'}>
          <div className={'industry-search-content'}>
            <div className={'industry-search-input-list'}>
              <InputLabel text={'귀속연도'} required>
                <Input
                  register={register('year')}
                  isLoading={isPending}
                  style={{
                    background: COLORS['gray0'],
                  }}
                />
              </InputLabel>
              <InputLabel text={'업종코드'}>
                <Input
                  register={register('code')}
                  isLoading={isPending}
                  style={{
                    background: COLORS['gray0'],
                  }}
                />
              </InputLabel>
              <InputLabel text={'업종'}>
                <Input
                  register={register('industry')}
                  isLoading={isPending}
                  style={{
                    background: COLORS['gray0'],
                  }}
                />
              </InputLabel>
            </div>
            <div className={'industry-search-input-description'}>
              <ul>
                <li>
                  업종코드 전부 또는 일부를 입력하거나 업종의 주요 키워드를
                  입력하시고 조회를 클릭하세요.
                </li>
              </ul>
            </div>
            <Button
              text={'조회하기'}
              type={'submit'}
              style={{ margin: '16px 0' }}
              isLoading={isPending}
            />
            <div className={'industry-search-response-list'}>
              <p className={'industry-search-response-title'}>업종코드목록</p>
              <p className={'industry-search-response-sub-title'}>
                (해당 업종을 터치(클릭)하여 선택하세요.)
              </p>
              <Table<TIndustrySearchResponse>
                data={industryList}
                columns={columns}
                onRowClickCustom={(row) => {
                  setSelectedIndustry(row.original);
                  setDetailModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </Container>
  );
};

export default IndustrySearch;
