import { API_URLS_ADMIN } from '@src/util/constants.ts';
import {
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  IBranchAdminApi,
  IBranchApi,
  ICertificatesApi,
  ICertificatesApiParams,
  IClientApi,
  IClientApiParams,
  IEtcSales,
  IExpectedTaxAdminApi,
  IExpectedTaxAdminWithoutId,
  IExpectedTaxApiParams,
  IFailedBatchApi,
  IFailedBatchApiParams,
  ILaborAdminApi,
  ILaborApiParams,
  IReceiptAdminApi,
  IReceiptApiParams,
  ITaskAdminApi,
  ITaskApiParams,
  ITaskDetailApi,
} from '@src/util/interfaces.ts';
import apiAdmin from '@src/util/apiAdmin.ts';
import { OptionValue } from '@src/components/Select.tsx';
import { AxiosRequestConfig } from 'axios';
import { isNotEmpty } from '@src/util/functions.ts';

export const useClient = (
  approvedFilter: OptionValue | null,
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory:
    | 'name'
    | 'user_name'
    | 'resident_number'
    | 'user_email' = 'user_name',
) => {
  const page_size = 15;
  const urlPath = API_URLS_ADMIN.CLIENT;

  let approved: boolean | null = null;
  if (approvedFilter !== null && approvedFilter.value !== '전체') {
    approved = approvedFilter.value === '승인';
  }

  let branch: string | null = null;
  if (branchFilter !== null && branchFilter.value !== '전체') {
    branch = branchFilter.value;
  }

  return useSuspenseInfiniteQuery<IClientApi>({
    initialPageParam: null,
    queryKey: [
      'client',
      approvedFilter,
      branchFilter,
      keyword,
      keywordSearchCategory,
    ],
    queryFn: async ({ pageParam }) => {
      const params: IClientApiParams = {
        page_size: page_size,
        approved: approved,
        branch: branch,
        lastId: pageParam,
      };

      if (isNotEmpty(keyword)) {
        params[keywordSearchCategory] = keyword;
      }

      const response = await apiAdmin.get(urlPath, {
        params,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
    staleTime: 30 * 60 * 1000, // 30분 캐싱
  });
};

export const useBranch = () => {
  const urlPath = API_URLS_ADMIN.BRANCH;

  return useSuspenseQuery<IBranchApi>({
    queryKey: ['branch'],
    queryFn: () => apiAdmin.get(urlPath),
    staleTime: 30 * 60 * 1000,
  });
};

export const useCertificateAdmin = (
  statusFilter: OptionValue | null,
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'user_name' | 'resident_number' = 'user_name',
) => {
  const urlPath = API_URLS_ADMIN.CERTIFICATES;
  const page_size = 15;

  let status: string | null = null;

  if (statusFilter !== null && statusFilter.value !== '전체') {
    status = statusFilter.value;
  }

  let branch: string | null = null;
  if (branchFilter !== null && branchFilter.value !== '전체') {
    branch = branchFilter.value;
  }

  return useSuspenseInfiniteQuery<ICertificatesApi>({
    initialPageParam: null,
    queryKey: [
      'certificates',
      status,
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: ICertificatesApiParams = {
        page_size: page_size,
        status: status,
        branch: branch,
        lastId: pageParam,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }

      const axiosConfig: AxiosRequestConfig = {
        params,
      };

      const response = await apiAdmin.get(urlPath, axiosConfig);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useLaborAdmin = (
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory:
    | 'company_name'
    | 'resident_number'
    | 'name'
    | 'phone_number' = 'company_name',
) => {
  const urlPath = API_URLS_ADMIN.LABOR;
  const page_size = 15;

  let branch: string | null = null;
  if (branchFilter !== null && branchFilter.value !== '전체') {
    branch = branchFilter.value;
  }

  return useSuspenseInfiniteQuery<ILaborAdminApi>({
    initialPageParam: null,
    queryKey: ['laborAdmin', branch, page_size, keyword, keywordSearchCategory],
    queryFn: async ({ pageParam = null }) => {
      const params: ILaborApiParams = {
        page_size: page_size,
        branch: branch,
        lastId: pageParam,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }
      const axiosConfig: AxiosRequestConfig = {
        params,
      };
      const response = await apiAdmin.get(urlPath, axiosConfig);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useTaskAdmin = (
  statusFilter: OptionValue | null,
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'resident_number' = 'name',
) => {
  const urlPath = API_URLS_ADMIN.TASK;
  const page_size = 15;
  let status: string | null = null;

  if (statusFilter !== null && statusFilter.value !== '전체') {
    status = statusFilter.value;
  }

  let branch: string | null = null;
  if (branchFilter !== null && branchFilter.value !== '전체') {
    branch = branchFilter.value;
  }

  return useSuspenseInfiniteQuery<ITaskAdminApi>({
    initialPageParam: null,
    queryKey: [
      'taskAdmin',
      status,
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: ITaskApiParams = {
        page_size: page_size,
        status: status,
        branch: branch,
        lastId: pageParam,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }

      const axiosConfig: AxiosRequestConfig = {
        params,
      };

      const response = await apiAdmin.get(urlPath, axiosConfig);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useTaskDetailAdmin = (id: string) => {
  const urlPath = API_URLS_ADMIN.TASK + `/${id}`;

  return useSuspenseQuery<ITaskDetailApi>({
    queryKey: ['taskAdminDetail', id],
    queryFn: async () => {
      const response = await apiAdmin(urlPath);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
  });
};

export const useReceiptAdmin = (
  statusFilter: OptionValue | null,
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'resident_number' = 'name',
) => {
  const urlPath = API_URLS_ADMIN.RECEIPT;
  const page_size = 15;
  let status: string | null = null;

  if (statusFilter !== null && statusFilter.value !== '전체') {
    status = statusFilter.value;
  }

  let branch: string | null = null;
  if (branchFilter !== null && branchFilter.value !== '전체') {
    branch = branchFilter.value;
  }

  return useSuspenseInfiniteQuery<IReceiptAdminApi>({
    initialPageParam: null,
    queryKey: [
      'receiptAdmin',
      status,
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: IReceiptApiParams = {
        page_size: page_size,
        status: status,
        branch: branch,
        lastId: pageParam,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }
      const axiosConfig: AxiosRequestConfig = {
        params,
      };
      const response = await apiAdmin.get(urlPath, axiosConfig);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useExpectedTaxAdmin = (
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' | 'resident_number' = 'name',
) => {
  const urlPath = API_URLS_ADMIN.EXPECTED_TAX;
  const page_size = 15;

  let branch: string | null = null;
  if (branchFilter !== null && branchFilter.value !== '전체') {
    branch = branchFilter.value;
  }

  return useSuspenseInfiniteQuery<IExpectedTaxAdminApi>({
    initialPageParam: null,
    queryKey: [
      'expectedTaxAdmin',
      branch,
      page_size,
      keyword,
      keywordSearchCategory,
    ],
    queryFn: async ({ pageParam = null }) => {
      const params: IExpectedTaxApiParams = {
        page_size: page_size,
        branch: branch,
        lastId: pageParam,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }
      const axiosConfig: AxiosRequestConfig = {
        params,
      };
      const response = await apiAdmin.get(urlPath, axiosConfig);

      return {
        data: response.data.data.map((item: IExpectedTaxAdminWithoutId) => {
          return {
            id: item.company_id,
            ...item,
          };
        }),
        hasNext: response.data.hasNext,
      };
    },
    staleTime: 30 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
  });
};

export const useBranchAdmin = (
  branchFilter: OptionValue | null,
  keyword: string = '',
  keywordSearchCategory: 'name' = 'name',
) => {
  const page_size = 15;
  const urlPath = API_URLS_ADMIN.BRANCH_ADMIN;

  let branch: string | null = null;
  if (branchFilter !== null && branchFilter.value !== '전체') {
    branch = branchFilter.value;
  }

  return useSuspenseInfiniteQuery<IBranchAdminApi>({
    initialPageParam: null,
    queryKey: ['branch_admin', branchFilter, keyword, keywordSearchCategory],
    queryFn: async ({ pageParam = null }) => {
      const params: IClientApiParams = {
        page_size: page_size,
        branch: branch,
        lastId: pageParam,
      };

      if (keyword.trim() !== '') {
        params[keywordSearchCategory] = keyword;
      }
      const axiosConfig: AxiosRequestConfig = {
        params,
      };

      const response = await apiAdmin.get(urlPath, axiosConfig);

      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext
        ? lastPage.data[lastPage.data.length - 1].id
        : undefined;
    },
    staleTime: 30 * 60 * 1000, // 30분 캐싱
  });
};

export const useEtcSales = (companyNo: number | null) => {
  const urlPath = API_URLS_ADMIN.ETC_SALES + `/${companyNo}`;

  return useQuery<IEtcSales[]>({
    queryKey: ['etcSales', companyNo],
    queryFn: async () => {
      const response = await apiAdmin.get(urlPath);
      return response.data;
    },
    staleTime: 30 * 60 * 1000,
    enabled: !!companyNo,
  });
};

export const useFailedBatch = (
  keyword: string = '',
  keywordSearchCategory: 'company_name' | 'resident_number',
) => {
  const page_size = 15;

  const urlPath = API_URLS_ADMIN.FAILED_BATCH;

  return useSuspenseInfiniteQuery<IFailedBatchApi>({
    initialPageParam: 1,
    queryKey: ['failedBatch', keyword, keywordSearchCategory],
    queryFn: async ({ pageParam }) => {
      const params: IFailedBatchApiParams = {
        page_size: page_size,
        page_number: pageParam as number,
      };

      if (isNotEmpty(keyword)) {
        params[keywordSearchCategory] = keyword;
      }

      const response = await apiAdmin.get(urlPath, {
        params,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.hasNextPage ? lastPage.nextPage : undefined;
    },
    staleTime: 30 * 60 * 1000, // 30분 캐싱
  });
};
