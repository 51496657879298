import React, { Dispatch, SetStateAction } from 'react';
import ReportCard from '@src/pages/reports/ReportCard.tsx';
import TaskProgressBar from '@src/pages/tax/TaskProgressBar.tsx';
import { COLORS, TASK_MENU, TASK_MENU_CONVERT } from '@src/util/constants.ts';
import Icon from '@src/components/Icon.tsx';
import infoIcon from '/src/assets/icons/info.svg';
import filterIcon from '/src/assets/icons/filter.svg';
import Table from '@src/components/Table.tsx';
import { ITask } from '@src/util/interfaces.ts';
import { useCommunicationTask } from '@src/util/hooks.ts';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { formatDateString } from '@src/util/functions.ts';

const columnHelper = createColumnHelper<ITask>();

const columns: Array<ColumnDef<ITask, string>> = [
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status =
        TASK_MENU_CONVERT[info.getValue() as keyof typeof TASK_MENU];
      let color = COLORS['purple'];
      if (status === '진행') {
        color = COLORS['green'];
      } else if (status === '피드백') {
        color = COLORS['red'];
      } else if (status === '보류') {
        color = COLORS['gray4'];
      } else if (status === '완료') {
        color = COLORS['primary'];
      }
      return <div style={{ color: color }}>{status}</div>;
    },
    header: () => <div style={{ minWidth: '40px' }}>상태</div>,
  }),
  columnHelper.accessor('title', {
    id: 'title',
    cell: (info) => <i>{info.getValue()}</i>,
    header: () => <div style={{ minWidth: '172px' }}>업무명</div>,
  }),
  columnHelper.accessor('created_at', {
    id: 'createdAt',
    cell: (info) => <i>{formatDateString(info.getValue())}</i>,
    header: () => <div style={{ minWidth: '64px' }}>요청일</div>,
  }),
  columnHelper.accessor('updated_at', {
    id: 'updatedAt',
    cell: (info) => <i>{formatDateString(info.getValue())}</i>,
    header: () => <div style={{ minWidth: '64px' }}>수정일</div>,
  }),
];

interface TaskContentProps {
  searchValue: string;
  setFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedFilterValue: string;
}

const TaskContent: React.FC<TaskContentProps> = ({
  searchValue,
  setFilterModalOpen,
  selectedFilterValue,
}) => {
  const { data } = useCommunicationTask();

  return (
    <>
      <ReportCard cardStyle={{ padding: '24px', gap: '20px' }}>
        <TaskProgressBar
          current={
            data.data.items.filter((item) => item.status === TASK_MENU.REQUEST)
              .length
          }
          base={data.data.items.length}
          color={'purple'}
          text={'요청'}
        />
        <TaskProgressBar
          current={
            data.data.items.filter(
              (item) => item.status === TASK_MENU.PROCEEDING,
            ).length
          }
          base={data.data.items.length}
          color={'green'}
          text={'진행'}
        />
        <TaskProgressBar
          current={
            data.data.items.filter((item) => item.status === TASK_MENU.FEEDBACK)
              .length
          }
          base={data.data.items.length}
          color={'red'}
          text={'피드백'}
        />
        <TaskProgressBar
          current={
            data.data.items.filter((item) => item.status === TASK_MENU.HOLD)
              .length
          }
          base={data.data.items.length}
          color={'gray4'}
          text={'보류'}
        />
        <TaskProgressBar
          current={
            data.data.items.filter((item) => item.status === TASK_MENU.COMPLETE)
              .length
          }
          base={data.data.items.length}
          color={'primary'}
          text={'완료'}
        />
        <div className="flex-row-flex-end-center" style={{ gap: '4px' }}>
          <p
            className="text-xs"
            style={{ fontWeight: '400', color: COLORS['gray4'] }}
          >
            처리 완료 :{' '}
            {
              data.data.items.filter(
                (item) => item.status === TASK_MENU.COMPLETE,
              ).length
            }
            건/{data.data.items.length}건 (과거 1년 기준)
          </p>
          <Icon iconSrc={infoIcon} />
        </div>
      </ReportCard>
      <div className="task-table-container">
        <div
          className="flex-row-space-between-center"
          style={{ marginBottom: '8px' }}
        >
          <p className="text-regular-bold">업무 목록</p>
          <Icon
            iconSrc={filterIcon}
            onClick={() => {
              setFilterModalOpen(true);
            }}
          />
        </div>
        <Table<ITask>
          data={
            data.data.items
              .filter(
                (item) =>
                  TASK_MENU_CONVERT[item.status as keyof typeof TASK_MENU] ===
                    selectedFilterValue || selectedFilterValue === '전체',
              )
              .filter((item) => item.title.includes(searchValue)) ?? []
          }
          columns={columns}
          onRowClick={(id) => `/tax/task/${id}`}
        />
      </div>
    </>
  );
};

export default TaskContent;
