import React from 'react';
import Card from '@src/components/Card.tsx';

interface ReportCardProps {
  children: React.ReactNode;
  cardStyle?: React.CSSProperties;
}

const ReportCard: React.FC<ReportCardProps> = ({ children, cardStyle }) => {
  return (
    <div className="report-card-container">
      <Card style={cardStyle}>{children}</Card>
    </div>
  );
};

export default ReportCard;
