import React from 'react';
import backArrow from '../assets/icons/top/backarrow.svg';
import { useNavigate } from 'react-router-dom';
import Icon from './Icon.tsx';

interface DetailHeaderProps {
  title?: string;
  custom?: React.ReactNode;
  backButton?: () => void;
}

const DetailHeader: React.FC<DetailHeaderProps> = ({
  title,
  custom,
  backButton,
}) => {
  const navigate = useNavigate();

  return (
    <nav className="detail-header">
      <div className="detail-header-contents">
        <div
          className="icon-container pointer"
          onClick={() => {
            if (backButton) {
              backButton();
            } else {
              navigate(-1);
            }
          }}
        >
          <Icon iconSrc={backArrow} />
        </div>
        {title && <span>{title}</span>}
        {custom}
      </div>
    </nav>
  );
};

export default DetailHeader;
