import React, { useState } from 'react';
import Container from '@src/layouts/Container';
import { LABOR_MENU, LABOR_MENU_LIST, URLS } from '@src/util/constants';
import { Link } from 'react-router-dom';
import DetailHeader from '@src/components/DetailHeader';
import HeaderTabs from '@src/components/HeaderTabs';
import { useMediaQuery } from 'react-responsive';
import SideBar from '@src/components/SideBar';

interface LaborProps {}

const StopQuestion: React.FC<LaborProps> = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 818px)' });
  const [activeHeaderTab, setActiveHeaderTab] = useState<string>(
    LABOR_MENU.REQUEST,
  );

  return (
    <Container
      sideBar={<SideBar />}
      header={<DetailHeader title={'노무지원금 신청'} />}
      headerTab={
        isMobile ? (
          ''
        ) : (
          <HeaderTabs
            menus={LABOR_MENU_LIST}
            activeHeaderTab={activeHeaderTab}
            setActiveHeaderTab={(value) => {
              setActiveHeaderTab(value);
            }}
          />
        )
      }
    >
      <div className="labor-request-description-container">
        <h2 className="text-md">
          귀 사업장은 노무지원금 검토 가능 사업장이 아닙니다.
        </h2>
      </div>
      <div className={'button-bottom-default-wrapper'}>
        <div className={'button-size-wrapper'}>
          <Link className={'button-right-default'} to={URLS.LABOR}>
            돌아가기
          </Link>
        </div>
      </div>
    </Container>
  );
};
export default StopQuestion;
