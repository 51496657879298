import React from 'react';
import { useNavigate } from 'react-router-dom';

type Menu = {
  id: string;
  text: string;
  url: string;
};

interface HeaderTabProps {
  // Add any props you need
  menus: Menu[];
  activeHeaderTab: string;
  setActiveHeaderTab?: (value: string) => void;
  headerStyle?: React.CSSProperties;
  onTabChange?: () => void;
}

const HeaderTabs: React.FC<HeaderTabProps> = ({
  menus,
  activeHeaderTab,
  setActiveHeaderTab,
  headerStyle,
  onTabChange,
}) => {
  const navigate = useNavigate();
  return (
    <div className="header-tab" style={headerStyle}>
      {menus.map((menu, i) => {
        return (
          <div
            className={`tab-item ${
              activeHeaderTab === menu.id ? 'active' : ''
            }`}
            onClick={() => {
              if (setActiveHeaderTab) {
                setActiveHeaderTab(menu.id);
              } else {
                if (activeHeaderTab !== menu.id && onTabChange) {
                  onTabChange();
                }

                navigate(menu.url, { replace: true });
              }
            }}
            key={i}
          >
            <p
              className="text-base"
              style={{
                color: `${activeHeaderTab === menu.id ? '#31343A' : '#BDBDBD'}`,
              }}
            >
              {menu.text}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderTabs;
