import '@src/scss/main.scss';
import Input from '@src/components/Input.tsx';
import React, { useEffect } from 'react';
import CheckBox from '@src/components/CheckBox.tsx';
import Button from '@src/components/Button.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { API_URL } from '@src/util/constants.ts';
import { z } from 'zod';
import { IApiError, IApiAuthSuccess } from '@src/util/interfaces.ts';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import api from '@src/util/api.ts';
import { useMediaQuery } from 'react-responsive';
import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import { IAdmin, useAdminStore } from '@src/util/adminStore.ts';

interface AdminEmailLoginProps {}

const LoginForm = z.object({
  email: z.string().trim().min(1, { message: 'id를 입력해주세요' }),
  password: z.string().trim().min(1, { message: '비밀번호를 입력해 주세요.' }),
  rememberId: z.boolean(),
  autoLogin: z.boolean(),
});

const LoginFormServer = LoginForm.omit({
  rememberId: true,
  autoLogin: true,
});

type TLoginForm = z.infer<typeof LoginForm>;
type TLoginFormServer = z.infer<typeof LoginFormServer>;

export const AdminEmailLogin: React.FC<AdminEmailLoginProps> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<TLoginForm>({
    resolver: zodResolver(LoginForm),
    defaultValues: {
      email: Cookies.get('admin_id') ? Cookies.get('admin_id') : '',
      rememberId: true,
      autoLogin: true,
    },
  });

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const {
    mutate,
    error,
    isError,
    isPending: isLoading,
  } = useMutation<IApiAuthSuccess, IApiError, TLoginFormServer>({
    mutationFn: (formData) => {
      return api.post(API_URL + '/v1/auth/admin/login', formData);
    },
  });

  const navigate = useNavigate();
  const [setAdmin] = useAdminStore((state) => [state.setAdmin]);

  useEffect(() => {
    const accessToken = Cookies.get('accessTokenAdmin');
    if (accessToken) {
      navigate('/admin/client', { replace: true });
    }
  }, [navigate]);

  const onSubmit: SubmitHandler<TLoginForm> = (data) => {
    const formData = {
      email: data.email,
      password: data.password,
    };

    mutate(formData, {
      onSuccess: (response) => {
        const { accessToken, refreshToken, user: userResponse } = response.data;
        const admin: IAdmin = {
          ...userResponse,
        };

        if (data.autoLogin) {
          Cookies.set('accessTokenAdmin', accessToken, { expires: 7 });
          Cookies.set('refreshTokenAdmin', refreshToken, { expires: 7 });
        } else {
          Cookies.set('autoLoginAdmin', 'N');
          Cookies.set('accessTokenAdmin', accessToken);
          Cookies.set('refreshTokenAdmin', refreshToken);
        }

        if (data.rememberId) {
          Cookies.set('admin_id', data.email, { expires: 7 }); // 아이디 저장
        } else {
          Cookies.remove('admin_id');
        }

        setAdmin(admin);
        navigate('/admin/client', { replace: true });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const content = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="email-container-wrapper">
        <div className="description-container">
          <h2 className="email-desktop-title">이메일 로그인</h2>
          <span>
            촘촘택스 Admin 회원 로그인을 위해 <br />
            메일과 비밀번호를 입력해 주세요.
          </span>
        </div>
        <div className="input-container">
          <Input
            placeholder="이메일"
            register={register('email')}
            customClassName="email-input-custom"
            isLoading={isLoading}
          />
          {errors.email && (
            <div className="error-message">
              {errors.email?.message?.toString()}
            </div>
          )}
          <Input
            placeholder="비밀번호"
            register={register('password')}
            customClassName="password-input-custom"
            type={'password'}
            isLoading={isLoading}
          />
          {errors.password && (
            <div className="error-message">
              {errors.password?.message?.toString()}
            </div>
          )}
          {isError && (
            <div className="error-message">
              {error?.response?.data?.message}
            </div>
          )}
          <div className="login-detail-checkboxes">
            <Controller
              name={'rememberId'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <CheckBox
                  checked={value}
                  onChange={onChange}
                  label={'아이디 저장'}
                  size={'sm'}
                />
              )}
            />
            <Controller
              name={'autoLogin'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <CheckBox
                  checked={value}
                  onChange={onChange}
                  label={'자동 로그인'}
                  size={'sm'}
                />
              )}
            />
          </div>

          <Button
            text="로그인"
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </form>
  );

  return (
    <Container
      header={isMobile ? <DetailHeader title={'이메일 로그인'} /> : <></>}
    >
      {content}
    </Container>
  );
};

export default AdminEmailLogin;
