import { useState } from 'react';
import ReportCard from '@src/pages/reports/ReportCard.tsx';
import ReportCardHeader from '@src/pages/reports/ReportCardHeader.tsx';
import { REPORT_CATEGORY, REPORT_TERM } from '@src/util/constants.ts';
import ReportCardStatement from '@src/pages/reports/ReportCardStatement.tsx';
import ReportGraph from '@src/pages/reports/ReportGraph.tsx';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useReportAPI } from '@src/util/hooks.ts';

const ReportYearlyContent = () => {
  const [activeCardHeaderTab, setActiveCardHeaderTab] = useState<string>(
    REPORT_CATEGORY.SALES,
  );
  const navigate = useNavigate();
  const [today] = useState(moment());

  const { graphData, dashboardData } = useReportAPI(today, REPORT_TERM.YEARLY);

  return (
    <>
      <ReportCard>
        <ReportCardHeader
          activeHeaderTab={REPORT_TERM.YEARLY}
          activeCardHeaderTab={activeCardHeaderTab}
          setActiveCardHeaderTab={setActiveCardHeaderTab}
          today={today}
        />
        <ReportCardStatement
          data={dashboardData}
          activeCardHeaderTab={activeCardHeaderTab}
          activeHeaderTab={REPORT_TERM.YEARLY}
          moreClick={() => {
            navigate(
              `/reports/additional/${REPORT_TERM.YEARLY}/${activeCardHeaderTab}`,
            );
          }}
        />
      </ReportCard>
      <ReportGraph
        activeHeaderTab={REPORT_TERM.YEARLY}
        activeCardHeaderTab={activeCardHeaderTab}
        today={today}
        graphData={graphData}
      />
    </>
  );
};

export default ReportYearlyContent;
