import { Drawer } from 'vaul';
import IconSVG from '@src/components/IconSVG.tsx';
import { COLORS, COMPANY_LIST_BACKGROUND_COLOR } from '@src/util/constants.ts';
import { getFirstCharacter } from '@src/util/functions.ts';
import { ICompany, useUserStore } from '@src/util/store.ts';
import addIcon from '@src/assets/icons/add.svg';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';

interface CompanyDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyDrawer: React.FC<CompanyDrawerProps> = ({ open, setOpen }) => {
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);

  const navigate = useNavigate();

  const handleRadioChange = (company: ICompany) => {
    setUser({ ...user, selectedCompany: company });
  };

  if (user?.companies) {
    return (
      <Drawer.Root open={open} onOpenChange={setOpen} shouldScaleBackground>
        <Drawer.Portal>
          <Drawer.Overlay className="company-drawer-overlay" />
          <Drawer.Content data-vaul-no-drag className="company-drawer-content">
            <div className={'company-drawer-content-header'}>
              <p className={'text-base'}>사업장 선택</p>
              <IconSVG
                fill={COLORS['main']}
                size={'24'}
                containerStyle={{
                  cursor: 'pointer',
                }}
                iconSVG={'close'}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>

            <div className={'company-drawer-content-body'}>
              {user?.companies &&
                user?.companies.map((company, i) => {
                  return (
                    <div
                      key={i}
                      className="company-drawer-content-item"
                      onClick={() => {
                        setUser({ ...user, selectedCompany: company });
                        setOpen(false);
                      }}
                    >
                      <div className={'flex-row-container'}>
                        <div
                          className="icon-container"
                          style={{
                            backgroundColor: COMPANY_LIST_BACKGROUND_COLOR[i],
                          }}
                        >
                          <p>{getFirstCharacter(company.name)}</p>
                        </div>
                        <p>{company.name}</p>
                      </div>
                      <div>
                        <input
                          type="radio"
                          checked={company.id === user?.selectedCompany?.id}
                          onChange={() => handleRadioChange(company)}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={'company-drawer-content-footer'}>
              <button
                className="icon-container"
                id={'register-company-button'}
                style={{
                  backgroundColor: COLORS['bg'],
                  border: '1px solid #E5E5E5',
                  width: '36px',
                  height: '36px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '36px',
                }}
                onClick={() => {
                  setOpen(false);
                  navigate('/register/company');
                }}
              >
                <img src={addIcon} alt={'+'} />
              </button>
              <label
                htmlFor={'register-company-button'}
                className={'text-base'}
                style={{ fontWeight: '400' }}
              >
                사업장 추가
              </label>
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    );
  }
};

export default CompanyDrawer;
