import Container from '@src/layouts/Container.tsx';
import AdminSideBar from '@src/components/AdminSideBar.tsx';

import BranchDetailAdminContent from '@src/pages/admin/branch/BranchDetailAdminContent.tsx';

const BranchDetailAdmin = () => {
  return (
    <Container sideBar={<AdminSideBar />}>
      <BranchDetailAdminContent />
    </Container>
  );
};

export default BranchDetailAdmin;
