import React from 'react';
import Divider from './Divider';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
  modalCss?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  children,
  footer,
  modalCss,
}) => {
  if (!isOpen) return null;
  const handleBackdropClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div
        className={`${modalCss ? modalCss : 'modal-content'}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
        {footer && (
          <React.Fragment>
            <Divider customClassName="overflow-divider-modal" />
            {footer}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
