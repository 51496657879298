import React from 'react';
import Container from '@src/layouts/Container';
import DetailHeader from '@src/components/DetailHeader.tsx';
import SideBar from '@src/components/SideBar.tsx';
import ExpectedPaymentDetailContent from '@src/pages/reports/ExpectedPaymentDetailContent.tsx';

interface ExpectedPaymentDetailProps {}

const ExpectedPaymentDetail: React.FC<ExpectedPaymentDetailProps> = () => {
  return (
    <Container
      header={<DetailHeader title={'입금 예정'} />}
      sideBar={<SideBar />}
    >
      <ExpectedPaymentDetailContent />
    </Container>
  );
};

export default ExpectedPaymentDetail;
