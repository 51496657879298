import { IFailedBatch } from '@src/util/interfaces.ts';
import Select from '@src/components/Select.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  COLORS,
  receiptKeywordSearchFilterOption,
} from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import searchIcon from '@src/assets/icons/search_black.svg';
import Table from '@src/components/Table.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useState } from 'react';
import { useFailedBatch } from '@src/util/adminHooks.ts';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';

const KeywordSearchForm = z.object({
  keyword: z.string(),
  keywordSearchFilter: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

type TKeywordSearchForm = z.infer<typeof KeywordSearchForm>;

const ErrorsAdminContent = () => {
  const [keyword, setKeyword] = useState<string>('');

  const [keywordSearchCategory, setKeywordSearchCategory] = useState<
    'company_name' | 'resident_number'
  >('company_name');

  const { data, fetchNextPage, hasNextPage } = useFailedBatch(
    keyword,
    keywordSearchCategory,
  );
  const flattenedData = data.pages.flatMap((page) => page.data);

  const columnHelper = createColumnHelper<IFailedBatch>();

  const columns: Array<ColumnDef<IFailedBatch, string>> = [
    columnHelper.accessor('company.name', {
      id: 'company_name',
      cell: (info) => {
        return <i>{info.getValue()}</i>;
      },
      header: () => <div style={{ minWidth: '68px' }}>사업자명</div>,
    }),
    columnHelper.accessor('company.resident_number', {
      id: 'resident_number',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>사업자등록번호</div>,
    }),
    columnHelper.accessor('start_date', {
      id: 'start_date',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>검색기간(시작)</div>,
    }),
    columnHelper.accessor('end_date', {
      id: 'end_date',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>검색기간(종료)</div>,
    }),
    columnHelper.accessor('message', {
      id: 'message',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <div style={{ minWidth: '92px' }}>내용</div>,
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: (info) => (
        <i>{moment(info.getValue()).format('YYYY-MM-DD HH:mm:ss')}</i>
      ),
      header: () => <div style={{ minWidth: '64px' }}>생성일</div>,
    }),
  ];
  const { register, control, handleSubmit } = useForm<TKeywordSearchForm>({
    resolver: zodResolver(KeywordSearchForm),
    defaultValues: {
      keywordSearchFilter: { label: '사업자명', value: '사업자명' },
    },
  });

  const onSubmit: SubmitHandler<TKeywordSearchForm> = (data) => {
    const keywordSearchCategoryValue = data.keywordSearchFilter.value;
    if (keywordSearchCategoryValue === '사업자명') {
      setKeywordSearchCategory('company_name');
    } else if (keywordSearchCategoryValue === '사업자등록번호') {
      setKeywordSearchCategory('resident_number');
    }

    setKeyword(data.keyword);
  };

  return (
    <div
      className={'flex-column-flex-start-stretch'}
      style={{
        gap: '24px',
        margin: '0 48px 48px 48px',
      }}
    >
      <p className={'text-md'}>에러 리스트</p>
      <Table<IFailedBatch>
        data={flattenedData}
        columns={columns}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        filterContent={
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={'table-filter-container'}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={'table-filter-keyword-search-container'}>
                  <div style={{ width: '128px' }}>
                    <Controller
                      name="keywordSearchFilter"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            name="keywordSearchFilter"
                            options={receiptKeywordSearchFilterOption}
                            onChange={onChange}
                            value={value}
                            isFilterSearch={true}
                          />
                        );
                      }}
                    />
                  </div>

                  <Input
                    placeholder={'검색어 입력'}
                    register={register('keyword')}
                    style={{
                      height: 'auto',
                      borderRadius: '0',
                      borderLeft: 'none',
                      width: '414px',
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault(); // Prevent the default behavior of the enter key
                        handleSubmit(onSubmit)(); // Execute the submit handler
                      }
                    }}
                  />
                  <Icon
                    iconSrc={searchIcon}
                    style={{
                      padding: '10px',
                      border: `1px solid ${COLORS['gray2']}`,
                      borderLeft: 'none',
                      borderBottomRightRadius: '4px',
                      borderTopRightRadius: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
          </form>
        }
      />
    </div>
  );
};

export default ErrorsAdminContent;
