import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import api from '@src/util/api.ts';
import {
  API_URLS,
  API_URLS_ADMIN,
  COLORS,
  COMPANY_LIST_BACKGROUND_COLOR,
} from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import IconSVG from '@src/components/IconSVG.tsx';
import { useUserStore } from '@src/util/store.ts';
import { getFirstCharacter } from '@src/util/functions.ts';
import Icon from '@src/components/Icon.tsx';
import moment from 'moment/moment';
import jylabsIcon from '/src/assets/icons/jylabs.svg';
import Button from '@src/components/Button.tsx';
import { useLocation, useParams } from 'react-router-dom';
import apiAdmin from '@src/util/apiAdmin.ts';
import { useCommunicationTaskDetail } from '@src/util/hooks.ts';

interface TaskCommentProps {
  taskId: string;
  setSnackBarOpen: (value: boolean) => void;
}

const TaskCommentForm = z.object({
  comment: z.string(),
});

const TaskCommentFormServer = TaskCommentForm.extend({
  task_id: z.number(),
  company_id: z.number(),
  name: z.string(),
});

const TaskCommentFormAdminServer = TaskCommentFormServer.omit({
  company_id: true,
  name: true,
});

type TTaskCommentForm = z.infer<typeof TaskCommentForm>;
type TTaskCommentFormServer = z.infer<typeof TaskCommentFormServer>;
type TTaskCommentAdminServer = z.infer<typeof TaskCommentFormAdminServer>;

const TaskComment: React.FC<TaskCommentProps> = ({
  taskId,
  setSnackBarOpen,
}) => {
  const { id = '' } = useParams<{
    id: string;
  }>();
  const { register, watch, handleSubmit, reset } = useForm<TTaskCommentForm>({
    resolver: zodResolver(TaskCommentForm),
  });
  const user = useUserStore((state) => state.user);
  const company_id = user?.selectedCompany?.id || 0;
  const commentValue = watch('comment');
  const queryClient = useQueryClient();
  const [commentUpdateOpenNo, setCommentUpdateOpenNo] = useState(0);
  const [commentUpdateContent, setCommentUpdateContent] = useState('');
  const location = useLocation();
  const [isAdmin] = useState<boolean>(location.pathname.startsWith('/admin'));
  const {
    data: { task_comments: comments },
  } = useCommunicationTaskDetail(id);

  const { mutate } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TTaskCommentFormServer
  >({
    mutationFn: (formData) => {
      return api.post(API_URLS.CREATE_TASK_COMMENT, formData);
    },
  });

  const { mutate: mutateAdmin } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TTaskCommentAdminServer
  >({
    mutationFn: (formData) => {
      return apiAdmin.post(API_URLS_ADMIN.TASK_COMMENT, formData);
    },
  });

  const onSubmit: SubmitHandler<TTaskCommentForm> = (data) => {
    const task_id = parseInt(taskId);

    if (!isAdmin) {
      const formData = {
        task_id: task_id,
        comment: data.comment,
        company_id: user?.selectedCompany?.id ?? 0,
        name: user?.selectedCompany?.name ?? '',
      };
      mutate(formData, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['task', taskId] });
          reset({ comment: '' });
        },
      });
    } else {
      const formData = {
        task_id: task_id,
        comment: data.comment,
      };
      mutateAdmin(formData, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['taskAdminDetail', taskId],
          });
          reset({ comment: '' });
        },
      });
    }
  };
  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, []);

  if (user?.companies) {
    return (
      <div className={'task-comment-wrapper-container'}>
        <div className={'task-comment-container'}>
          <p className={'text-regular-bold'}>댓글 ({comments.length})</p>
          {comments.length !== 0 && (
            <div
              ref={commentsContainerRef}
              style={{
                maxHeight: '120px',
                overflowY: 'auto',
              }}
            >
              {comments.map((comment, i) => {
                if (user?.companies) {
                  return (
                    <div
                      className={'flex-row'}
                      style={{
                        gap: '12px',
                        padding: '12px 0',
                        borderBottom: '1px solid #F5F5F5',
                      }}
                      key={i}
                    >
                      {comment.name === '관리자' ? (
                        <Icon
                          iconSrc={jylabsIcon}
                          style={{
                            width: '28px',
                            height: '28px',
                            borderRadius: '50%',
                            backgroundColor: '#E5E5E5',
                          }}
                        />
                      ) : (
                        <Icon
                          style={{
                            backgroundColor:
                              COMPANY_LIST_BACKGROUND_COLOR[
                                user.companies.findIndex(
                                  (company) =>
                                    company.id === user?.selectedCompany?.id,
                                )
                              ],
                            width: '28px',
                            height: '28px',
                            cursor: 'text',
                          }}
                          text={getFirstCharacter(user?.selectedCompany?.name)}
                          type={'account'}
                        />
                      )}

                      <div
                        className={'flex-column-flex-start-flex-start'}
                        style={{ flex: 1 }}
                      >
                        <p
                          className={'text-xs'}
                          style={{
                            gap: '4px',
                            color: COLORS['gray4'],
                            fontWeight: '400',
                            display: 'flex',
                          }}
                        >
                          <span
                            className={'text-xs'}
                            style={{ color: COLORS['main'] }}
                          >
                            {comment.name}
                          </span>{' '}
                          · {moment(comment.created_at).format('YYYY-MM-DD')}
                        </p>
                        {commentUpdateOpenNo === comment.id ? (
                          <Input
                            value={commentUpdateContent}
                            onChange={(e) =>
                              setCommentUpdateContent(e.target.value)
                            }
                          />
                        ) : (
                          <p className={'text-regular'}>{comment.comment}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '3px',
                        }}
                      >
                        {((isAdmin && comment.name === '관리자') ||
                          comment.name === user?.selectedCompany?.name) && (
                          <>
                            <Button
                              text={
                                commentUpdateOpenNo === comment.id
                                  ? '저장'
                                  : '수정'
                              }
                              style={{
                                backgroundColor: COLORS['gray0'],
                                fontSize: '12px',
                                lineHeight: '16px',
                                fontWeight: '400',
                                color: COLORS['gray3'],
                              }}
                              onClick={() => {
                                if (commentUpdateOpenNo === comment.id) {
                                  if (!isAdmin) {
                                    api
                                      .patch(
                                        API_URLS.TASK +
                                          `/${company_id}/comment/${comment.id}`,
                                        {
                                          name: user?.selectedCompany?.name,
                                          comment: commentUpdateContent,
                                        },
                                      )
                                      .then(() => {
                                        setCommentUpdateOpenNo(0);
                                        setCommentUpdateContent('');
                                        queryClient.invalidateQueries({
                                          queryKey: ['task', taskId],
                                        });
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  } else {
                                    apiAdmin
                                      .patch(
                                        API_URLS_ADMIN.TASK_COMMENT +
                                          `/${comment.id}`,
                                        {
                                          comment: commentUpdateContent,
                                        },
                                      )
                                      .then((res) => {
                                        console.log(res);
                                        setCommentUpdateOpenNo(0);
                                        setCommentUpdateContent('');
                                        queryClient.invalidateQueries({
                                          queryKey: ['taskAdminDetail', taskId],
                                        });
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }
                                } else {
                                  setCommentUpdateOpenNo(comment.id);
                                  setCommentUpdateContent(comment.comment);
                                }
                              }}
                            />
                            <p
                              className={'text-sm'}
                              style={{ color: COLORS['gray3'] }}
                            >
                              |
                            </p>
                            <Button
                              text={'삭제'}
                              style={{
                                backgroundColor: COLORS['gray0'],
                                fontSize: '12px',
                                lineHeight: '16px',
                                fontWeight: '400',
                                color: COLORS['gray3'],
                              }}
                              onClick={() => {
                                if (!isAdmin) {
                                  api
                                    .delete(
                                      API_URLS.TASK +
                                        `/${company_id}/comment/${comment.id}`,
                                    )
                                    .then((res) => {
                                      console.log(res);
                                      setSnackBarOpen(true);
                                      setTimeout(
                                        () => setSnackBarOpen(false),
                                        3000,
                                      );
                                      queryClient.invalidateQueries({
                                        queryKey: ['task', taskId],
                                      });
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                } else {
                                  apiAdmin
                                    .delete(
                                      API_URLS_ADMIN.TASK_COMMENT +
                                        `/${comment.id}`,
                                    )
                                    .then((res) => {
                                      console.log(res);
                                      setSnackBarOpen(true);
                                      setTimeout(
                                        () => setSnackBarOpen(false),
                                        3000,
                                      );
                                      queryClient.invalidateQueries({
                                        queryKey: ['taskAdminDetail', taskId],
                                      });
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={'comment-container'}>
              <Input
                style={{
                  flexGrow: 1,
                  boxSizing: 'border-box',
                  borderRight: '0',
                  borderTopRightRadius: '0',
                  borderBottomRightRadius: '0',
                }}
                register={register('comment')}
              />
              <IconSVG
                fill={!commentValue ? '#BDBDBD' : '#3182F6'}
                size={'24'}
                containerStyle={{
                  padding: '10px',
                  boxSizing: 'border-box',
                  height: '44px',
                  width: '44px',
                  border: `1px solid ${!commentValue ? '#E5E5E5' : '#3182F6'}`,
                }}
                iconSVG={'send'}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default TaskComment;
