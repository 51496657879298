import Container from '@src/layouts/Container.tsx';
import SideBar from '@src/components/SideBar.tsx';
import {
  API_URLS,
  COLORS,
  etxivKndCdExemptOptions,
  etxivKndCdOptions,
  TAX_INVOICE_MENU_LIST,
  TAX_INVOICE_MENU_TYPE,
  taxInvoiceTypeCode,
  taxInvoiceTypeOptions,
  URLS,
} from '@src/util/constants.ts';
import HeaderTabs from '@src/components/HeaderTabs.tsx';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import RadioButton from '@src/components/RadioButton.tsx';
import InputLabel from '@src/components/InputLabel.tsx';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Input from '@src/components/Input.tsx';
import { isNotEmpty, numericRegex } from '@src/util/functions.ts';
import TaxInvoiceProduct from '@src/pages/tax-invoice/TaxInvoiceProduct.tsx';
import addBlue from '/src/assets/icons/add_blue.svg';
import calendarMonth from '/src/assets/icons/calendar_month.svg';
import Select from '@src/components/Select.tsx';
import ReactDatePicker from '@src/components/DatePicker.tsx';
import { ko } from 'date-fns/locale/ko';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  defaultTaxInvoiceForm,
  useTaxInvoiceCertStore,
  useTaxInvoiceFormStore,
  useUserStore,
} from '@src/util/store.ts';
import { useMutation } from '@tanstack/react-query';
import { IApiError } from '@src/util/interfaces.ts';
import api from '@src/util/api.ts';
import {
  TValidateCompanyBizNoRequest,
  TValidateCompanyBizNoResponse,
} from '@src/pages/auth/RegisterCompany.tsx';
import Button from '@src/components/Button.tsx';
import Snackbar from '@src/components/SnackBar.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '@src/components/Footer.tsx';
import Header from '@src/components/Header.tsx';
import TaxInvoiceDetailDrawer from '@src/components/TaxInvoiceDetailDrawer.tsx';
import CompanyDrawer from '@src/components/CompanyDrawer.tsx';
import { useCert, useCustomerSearch } from '@src/util/hooks.ts';
import TaxInvoiceCertDrawer from '@src/components/TaxInvoiceCertDrawer.tsx';
import moment from 'moment';

const TaxInvoiceForm = z.object({
  etxivKndCd: z.enum(['01', '02']), //etxivKndCd(발행종류) - '01' - 일반, '02' - 영세율 -- 공통(계산서에서는 영세율이 없다)
  registrationNumber1Supplier: z.string().regex(numericRegex).min(3).max(3), // 공급자 사업자번호(클라에서 보여주는 목적)
  registrationNumber2Supplier: z.string().regex(numericRegex).min(2).max(2), // 공급자 사업자번호(클라에서 보여주는 목적)
  registrationNumber3Supplier: z.string().regex(numericRegex).min(5).max(5), // 공급자 사업자번호(클라에서 보여주는 목적)
  splrTnmNm: z.string(), // 공급자 상호(클라에서 보여주는 목적)
  registrationNumberConsumer: z.object({
    registrationNumber1Consumer: z
      .string()
      .length(3, { message: '사업자번호를 입력해 주세요.' })
      .regex(numericRegex, { message: '사업자번호는 숫자만 입력해 주세요.' }), // 공급받는자 사업자번호(서버 보낼때 splrTxprDscmNo로 합쳐야 한다)
    registrationNumber2Consumer: z
      .string()
      .length(2, { message: '사업자번호를 입력해 주세요.' })
      .regex(numericRegex, { message: '사업자번호는 숫자만 입력해 주세요.' }), // 공급받는자 사업자번호(서버 보낼때 splrTxprDscmNo로 합쳐야 한다)
    registrationNumber3Consumer: z
      .string()
      .length(5, { message: '사업자번호를 입력해 주세요.' })
      .regex(numericRegex), // 공급받는자 사업자번호(서버 보낼때 splrTxprDscmNo로 합쳐야 한다)
  }),
  dmnrPfbAdr: z.string(), //공급받는자 사업장 주소
  dmnrTnmNm: z.string().min(1, { message: '상호를 입력해 주세요.' }), // 공급받는자 상호
  dmnrRprsFnm: z.string().min(1, { message: '성명을 입력해 주세요.' }), // 공급받는자 성명
  dmnrBcNm: z.string(), // 공급받는자 업태
  dmnrItmNm: z.string(), // 공급받는자 종목
  dmnrMpbNo: z.string(), // 공급받는자 종사업장
  dmnrMchrgEmlAdr: z.string(), // 공급받는자 이메일
  dmnrMchrgEmlAdr2: z.string().optional(), // 공급받는자 추가 이메일
  lsatInfrBizSVOList: z.array(
    // 발행 품목 리스트
    z.object({
      lsatSplMm: z.string(), // 월
      lsatSplDd: z.string(), // 일
      lsatNm: z.string(), // 품목
      lsatRszeNm: z.string(), // 규격
      lsatQty: z.string(), // 수량
      lsatUtprc: z.string(), // 공급대가(합계)
      lsatSplCft: z.string().min(1, { message: '금액을 입력해 주세요.' }), // 공급가액
      lsatTxamt: z.string(), // 세액
      lsatRmrkCntn: z.string(), //비고
    }),
  ),
  taxInvoiceTypeCd: z.object({
    // 현금, 수표, 어음, 외상미수금중 택일.
    value: z.enum(taxInvoiceTypeCode),
    label: z.string(),
  }),
  recApeClCd: z.enum(['01', '02']), //'01' - 청구, '02' - 영수
  wrtDt: z.date(), // 작성일자. 서버로 보낼때는 YYYYMMDD 포맷으로 수정해야 한다.
});

const TaxInvoiceFormServer = TaxInvoiceForm.omit({
  registrationNumber1Supplier: true,
  registrationNumber2Supplier: true,
  registrationNumber3Supplier: true,
  splrTnmNm: true,
  registrationNumberConsumer: true,
  wrtDt: true,
  taxInvoiceTypeCd: true,
}).extend({
  etxivClsfCd: z.enum(['01', '03']), //(계산서 종류) - '01' - 세금계산서, '03' - 계산서 -- 서버에만 사용
  etxivDmnrClsfCd: z.enum(['01']),
  emailYn: z.enum(['Y', 'N']), // 확인 이메일 받을지. (무조건 Y)
  wrtDt: z.string(), // YYYYMMDD 형태로
  dmnrTxprDscmNo: z.string(), // 공급받는자 사업자번호 -- 숫자만
  csh: z.string().optional(),
  chck: z.string().optional(),
  note: z.string().optional(),
  crit: z.string().optional(),
});

const TaxInvoiceFormServerWrapper = z.object({
  issue: TaxInvoiceFormServer,
});

export type TTaxInvoiceForm = z.infer<typeof TaxInvoiceForm>;
export type TTaxInvoiceFormServerWrapper = z.infer<
  typeof TaxInvoiceFormServerWrapper
>;

const TaxInvoice = () => {
  const datePickerRef = useRef<DatePicker>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [consumerBizNoErrMsg, setConsumerBizNoErrMsg] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [certModalOpen, setCertModalOpen] = useState(false);

  const [user] = useUserStore((state) => [state.user]);
  const { data } = useCert();
  const { data: customerData } = useCustomerSearch('', 'txprNm');
  const [open, setOpen] = useState(false);

  const { taxInvoiceForm, setTaxInvoiceForm, clearTaxInvoiceForm } =
    useTaxInvoiceFormStore((state) => ({
      taxInvoiceForm: state.taxInvoiceForm,
      setTaxInvoiceForm: state.setTaxInvoiceForm,
      clearTaxInvoiceForm: state.clearTaxInvoiceForm,
    }));

  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    watch,
    setFocus,
    formState: { errors },
  } = useForm<TTaxInvoiceForm>({
    resolver: zodResolver(TaxInvoiceForm),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lsatInfrBizSVOList',
  });

  const dateValue = watch('wrtDt');

  const lsatInfrBizSVOList = watch('lsatInfrBizSVOList');
  const registrationNumber1ConsumerValue = watch(
    'registrationNumberConsumer.registrationNumber1Consumer',
  );
  const registrationNumber2ConsumerValue = watch(
    'registrationNumberConsumer.registrationNumber2Consumer',
  );
  const registrationNumber3ConsumerValue = watch(
    'registrationNumberConsumer.registrationNumber3Consumer',
  );

  useEffect(() => {
    if (taxInvoiceForm) {
      Object.entries(taxInvoiceForm).forEach(([key, value]) => {
        setValue(key as keyof TTaxInvoiceForm, value);
      });
    }
  }, [taxInvoiceForm, setValue]);

  useEffect(() => {
    const resident_number = user?.selectedCompany?.resident_number;
    const business_name = user?.selectedCompany?.name || '';
    let registrationNumber1Supplier = '';
    let registrationNumber2Supplier = '';
    let registrationNumber3Supplier = '';

    if (resident_number !== undefined) {
      registrationNumber1Supplier = resident_number.split('-')[0];
      registrationNumber2Supplier = resident_number.split('-')[1];
      registrationNumber3Supplier = resident_number.split('-')[2];
    }
    setValue('registrationNumber1Supplier', registrationNumber1Supplier);
    setValue('registrationNumber2Supplier', registrationNumber2Supplier);
    setValue('registrationNumber3Supplier', registrationNumber3Supplier);
    setValue('splrTnmNm', business_name);
  }, [user, setValue]);

  useEffect(() => {
    if (dateValue) {
      const month = dateValue.getMonth() + 1;
      fields.forEach((_field, index) => {
        setValue(
          `lsatInfrBizSVOList.${index}.lsatSplMm`,
          month.toString().padStart(2, '0'),
        );
      });
    }
  }, [dateValue, fields, setValue]);

  const { certReadChecked } = useTaxInvoiceCertStore((state) => ({
    certReadChecked: state.certReadChecked,
  }));

  useEffect(() => {
    if (
      (certReadChecked === null ||
        moment().diff(certReadChecked, 'days') > 7) &&
      data?.data?.hometax_sign_cert === ''
    ) {
      setCertModalOpen(true);
    }
  }, [certReadChecked, data?.data?.hometax_sign_cert]);

  useEffect(() => {
    if (
      registrationNumber3ConsumerValue &&
      registrationNumber3ConsumerValue.length === 5
    ) {
      setFocus('splrTnmNm');
    } else if (
      registrationNumber2ConsumerValue &&
      registrationNumber2ConsumerValue.length === 2
    ) {
      setFocus('registrationNumberConsumer.registrationNumber3Consumer');
    } else if (
      registrationNumber1ConsumerValue &&
      registrationNumber1ConsumerValue.length === 3
    ) {
      setFocus('registrationNumberConsumer.registrationNumber2Consumer');
    }
  }, [
    registrationNumber1ConsumerValue,
    registrationNumber2ConsumerValue,
    registrationNumber3ConsumerValue,
    setFocus,
  ]);

  // useEffect(() => {
  //   if (
  //     registrationNumber2ConsumerValue &&
  //     registrationNumber2ConsumerValue.length === 2
  //   ) {
  //     setFocus('registrationNumberConsumer.registrationNumber3Consumer');
  //   }
  // }, [registrationNumber2ConsumerValue, setFocus]);

  const {
    mutate: validateCompanyBizNo,
    isPending: isPendingValidateCompanyBizNo,
  } = useMutation<
    TValidateCompanyBizNoResponse,
    IApiError,
    TValidateCompanyBizNoRequest
  >({
    mutationFn: (formData) => {
      return api.post(API_URLS.VERIFY_BIZ_NO, formData);
    },
  });

  const validateBizNo = async () => {
    setConsumerBizNoErrMsg('');

    const isValid = await trigger('registrationNumberConsumer');

    if (!isValid) {
      setConsumerBizNoErrMsg('사업자 등록번호가 유효하지 않습니다.');
    } else {
      const resident_number = `${getValues(
        'registrationNumberConsumer.registrationNumber1Consumer',
      )}-${getValues(
        'registrationNumberConsumer.registrationNumber2Consumer',
      )}-${getValues(
        'registrationNumberConsumer.registrationNumber3Consumer',
      )}`;

      validateCompanyBizNo(
        { resident_number },
        {
          onSuccess: (res) => {
            console.log(res);

            const residentNumberWithoutHypen = `${getValues(
              'registrationNumberConsumer.registrationNumber1Consumer',
            )}${getValues(
              'registrationNumberConsumer.registrationNumber2Consumer',
            )}${getValues(
              'registrationNumberConsumer.registrationNumber3Consumer',
            )}`;

            const customer = customerData?.filter(
              (item) => item.txprDscmNoEncCntn == residentNumberWithoutHypen,
            );
            if (customer && customer[0]) {
              setValue('dmnrTnmNm', customer[0].tnmNm);
              setValue('dmnrRprsFnm', customer[0].rprsFnm);
              setValue('dmnrPfbAdr', customer[0].pfbAdr);
              setValue('dmnrBcNm', customer[0].bcNm);
              setValue('dmnrItmNm', customer[0].itmNm);
              setValue('dmnrMchrgEmlAdr', customer[0].mchrgEmlAdr);
            } else {
              setValue('dmnrTnmNm', '');
              setValue('dmnrRprsFnm', '');
              setValue('dmnrPfbAdr', '');
              setValue('dmnrBcNm', '');
              setValue('dmnrItmNm', '');
              setValue('dmnrMchrgEmlAdr', '');
            }

            setSnackBarType('info');
            setSnackBarContent('정상적인 사업자번호입니다.');
            setSnackBarOpen(true);
            setTimeout(() => {
              setSnackBarOpen(false);
            }, 3000);
          },
          onError: (err) => {
            console.log(err.response.data.message);
            setConsumerBizNoErrMsg(err.response.data.message);
          },
        },
      );
    }
  };

  const renderRegistrationErrorMsg = () => {
    let msg = '';
    const err1 =
      errors.registrationNumberConsumer?.registrationNumber1Consumer?.message;
    const err2 =
      errors.registrationNumberConsumer?.registrationNumber2Consumer?.message;
    const err3 =
      errors.registrationNumberConsumer?.registrationNumber3Consumer?.message;

    if (err1) {
      msg = err1;
    } else if (err2) {
      msg = err2;
    } else if (err3) {
      msg = err3;
    } else if (isNotEmpty(consumerBizNoErrMsg)) {
      msg = consumerBizNoErrMsg;
    }

    return (
      <p className={'error-message'} style={{ marginTop: '-4px' }}>
        {msg}
      </p>
    );
  };

  const renderErrMsgRow = () => {
    let msg = '';
    if (errors.dmnrTnmNm?.message) {
      msg = errors.dmnrTnmNm.message;
    } else if (errors.dmnrRprsFnm?.message) {
      msg = errors.dmnrRprsFnm.message;
    }

    return (
      <p className={'error-message'} style={{ marginTop: '-12px' }}>
        {msg}
      </p>
    );
  };

  const onSubmit: SubmitHandler<TTaxInvoiceForm> = (data) => {
    setTaxInvoiceForm(data);
    setDetailModalOpen(true);
  };

  return (
    <Container
      header={<Header setOpen={setOpen} />}
      headerTab={
        <HeaderTabs
          menus={TAX_INVOICE_MENU_LIST}
          activeHeaderTab={
            location.pathname.endsWith(TAX_INVOICE_MENU_TYPE.REGULAR)
              ? TAX_INVOICE_MENU_TYPE.REGULAR
              : TAX_INVOICE_MENU_TYPE.EXEMPT
          }
          onTabChange={() => {
            Object.entries(defaultTaxInvoiceForm).forEach(([key, value]) => {
              setValue(key as keyof TTaxInvoiceForm, value);
            });

            const resident_number = user?.selectedCompany?.resident_number;
            const business_name = user?.selectedCompany?.name || '';
            let registrationNumber1Supplier = '';
            let registrationNumber2Supplier = '';
            let registrationNumber3Supplier = '';

            if (resident_number !== undefined) {
              registrationNumber1Supplier = resident_number.split('-')[0];
              registrationNumber2Supplier = resident_number.split('-')[1];
              registrationNumber3Supplier = resident_number.split('-')[2];
            }
            setValue(
              'registrationNumber1Supplier',
              registrationNumber1Supplier,
            );
            setValue(
              'registrationNumber2Supplier',
              registrationNumber2Supplier,
            );
            setValue(
              'registrationNumber3Supplier',
              registrationNumber3Supplier,
            );
            setValue('splrTnmNm', business_name);
          }}
        />
      }
      sideBar={<SideBar />}
      modal={
        <>
          <Snackbar
            message={snackBarContent}
            open={snackBarOpen}
            type={snackBarType}
          />
          <TaxInvoiceCertDrawer
            open={certModalOpen}
            setOpen={setCertModalOpen}
            onOk={() => {
              navigate(URLS.HOMETAX_SYNC);
            }}
            onCancel={() => {
              setCertModalOpen(false);
            }}
          />
          <TaxInvoiceDetailDrawer
            open={detailModalOpen}
            setOpen={setDetailModalOpen}
            onCloseCallBack={() => {
              setDetailModalOpen(false);
              clearTaxInvoiceForm();
              setSnackBarType('info');
              const type = location.pathname.endsWith(
                TAX_INVOICE_MENU_TYPE.REGULAR,
              )
                ? '세금계산서'
                : '계산서';
              setSnackBarContent(`${type}가 발행되었습니다.`);
              setSnackBarOpen(true);
              setTimeout(() => {
                setSnackBarOpen(false);
              }, 3000);
            }}
            onErrorCallBack={() => {
              setSnackBarType('error');
              const type = location.pathname.endsWith(
                TAX_INVOICE_MENU_TYPE.REGULAR,
              )
                ? '세금계산서'
                : '계산서';
              setSnackBarContent(`${type}발행 실패.`);
              setSnackBarOpen(true);
              setTimeout(() => {
                setSnackBarOpen(false);
              }, 3000);
            }}
          />
          <CompanyDrawer open={open} setOpen={setOpen} />
        </>
      }
      footer={
        <Footer
          current={'taxInvoice'}
          onTabChange={() => {
            clearTaxInvoiceForm();
          }}
        />
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'tax-invoice-container'}>
          <div className={'tax-invoice-content'} style={{ marginTop: '24px' }}>
            <InputLabel text={'종류'} required>
              <Controller
                name={'etxivKndCd'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioButton
                    name={'etxivKndCd'}
                    options={
                      location.pathname.endsWith(TAX_INVOICE_MENU_TYPE.REGULAR)
                        ? etxivKndCdOptions
                        : etxivKndCdExemptOptions
                    }
                    defaultValue={value}
                    onChange={onChange}
                  />
                )}
              />
            </InputLabel>
            <div className={'flex-row'} style={{ gap: '12px' }}>
              {/*<button className={'tax-invoice-black-btn'}>거래처 관리</button>*/}
              <button
                className={'tax-invoice-black-btn'}
                type={'button'}
                onClick={() => {
                  if (isNotEmpty(data?.data?.hometax_sign_cert)) {
                    const formData = getValues();
                    setTaxInvoiceForm(formData);
                    navigate(URLS.CUSTOMER_SEARCH);
                  } else {
                    setCertModalOpen(true);
                  }
                }}
              >
                거래처 조회
              </button>
            </div>
            <div className={'container-red'}>
              <div className={'container-header'}>
                <p>공급자</p>
              </div>
              <div className={'container-content'}>
                <InputLabel
                  text={'등록번호'}
                  required
                  labelStyle={{ color: COLORS['redStrong'] }}
                >
                  <div className="email-verify-container">
                    <div className="one-third-flex-item">
                      <Input
                        register={register('registrationNumber1Supplier')}
                        disabled={true}
                        customClassName={'input-full-width'}
                        maxLength={3}
                        inputMode={'numeric'}
                        style={{ background: COLORS['gray0'] }}
                      />
                    </div>
                    <p>-</p>
                    <div className="one-third-flex-item">
                      <Input
                        register={register('registrationNumber2Supplier')}
                        disabled={true}
                        customClassName={'input-full-width'}
                        maxLength={2}
                        inputMode={'numeric'}
                        style={{ background: COLORS['gray0'] }}
                      />
                    </div>
                    <p>-</p>
                    <div className="one-third-flex-item">
                      <Input
                        register={register('registrationNumber3Supplier')}
                        disabled={true}
                        customClassName={'input-full-width'}
                        maxLength={5}
                        inputMode={'numeric'}
                        style={{ background: COLORS['gray0'] }}
                      />
                    </div>
                  </div>
                </InputLabel>
                <InputLabel
                  text={'상호'}
                  required
                  labelStyle={{ color: COLORS['redStrong'] }}
                  containerStyle={{ width: '100%', flex: 1 }}
                >
                  <Input
                    register={register('splrTnmNm')}
                    disabled={true}
                    inputMode={'numeric'}
                    style={{
                      background: COLORS['gray0'],
                    }}
                  />
                </InputLabel>
              </div>
            </div>
            <div className={'container-blue'}>
              <div className={'container-header'}>
                <p>공급받는자</p>
              </div>
              <div className={'container-content'}>
                <InputLabel
                  text={'등록번호'}
                  required
                  labelStyle={{ color: COLORS['blueContainer'] }}
                  error={renderRegistrationErrorMsg()}
                >
                  <div className="email-verify-container">
                    <div className="one-third-flex-item">
                      <Input
                        register={register(
                          'registrationNumberConsumer.registrationNumber1Consumer',
                        )}
                        isLoading={isPendingValidateCompanyBizNo}
                        customClassName={'input-full-width'}
                        maxLength={3}
                        inputMode={'numeric'}
                      />
                    </div>
                    <p>-</p>
                    <div className="one-third-flex-item">
                      <Input
                        register={register(
                          'registrationNumberConsumer.registrationNumber2Consumer',
                        )}
                        isLoading={isPendingValidateCompanyBizNo}
                        customClassName={'input-full-width'}
                        maxLength={2}
                        inputMode={'numeric'}
                      />
                    </div>
                    <p>-</p>
                    <div className="one-third-flex-item">
                      <Input
                        register={register(
                          'registrationNumberConsumer.registrationNumber3Consumer',
                        )}
                        isLoading={isPendingValidateCompanyBizNo}
                        customClassName={'input-full-width'}
                        maxLength={5}
                        inputMode={'numeric'}
                      />
                    </div>
                    <Button
                      style={{
                        minWidth: '52px',
                        width: '52px',
                        height: '44px',
                      }}
                      onClick={validateBizNo}
                      text={'확인'}
                      type={'button'}
                      isLoading={isPendingValidateCompanyBizNo}
                    />
                  </div>
                </InputLabel>
                <InputLabel
                  text={'종사업장 번호'}
                  labelStyle={{ color: COLORS['blueContainer'] }}
                >
                  <Input
                    register={register('dmnrMpbNo')}
                    customClassName={'input-full-width'}
                    inputMode={'numeric'}
                  />
                </InputLabel>
                <div className={'flex-row'} style={{ gap: '20px' }}>
                  <InputLabel
                    text={'상호'}
                    required
                    labelStyle={{ color: COLORS['blueContainer'] }}
                  >
                    <Input
                      register={register('dmnrTnmNm')}
                      style={{
                        width: '100%',
                        boxSizing: 'border-box',
                      }}
                    />
                  </InputLabel>
                  <InputLabel
                    text={'성명'}
                    required
                    labelStyle={{ color: COLORS['blueContainer'] }}
                  >
                    <Input
                      register={register('dmnrRprsFnm')}
                      inputMode={'text'}
                      style={{
                        width: '100%',
                        boxSizing: 'border-box',
                      }}
                    />
                  </InputLabel>
                </div>
                {renderErrMsgRow()}
                <InputLabel
                  text={'사업장'}
                  labelStyle={{ color: COLORS['blueContainer'] }}
                >
                  <div
                    className={'flex-row'}
                    style={{
                      gap: '10px',
                    }}
                  >
                    <Input
                      register={register('dmnrPfbAdr')}
                      customClassName={'input-full-width'}
                      style={{
                        width: '100%',
                        boxSizing: 'border-box',
                      }}
                    />
                    <button
                      className={'tax-invoice-black-btn'}
                      style={{ minWidth: '80px', height: '44px' }}
                      onClick={() => {
                        const formData = getValues();
                        setTaxInvoiceForm(formData);
                        navigate(URLS.ADDRESS_SEARCH);
                      }}
                    >
                      주소변경
                    </button>
                  </div>
                </InputLabel>
                <InputLabel
                  text={'업태'}
                  labelStyle={{ color: COLORS['blueContainer'] }}
                >
                  <div
                    className={'flex-row'}
                    style={{
                      gap: '10px',
                    }}
                  >
                    <Input
                      register={register('dmnrBcNm')}
                      customClassName={'input-full-width'}
                      style={{
                        width: '100%',
                        boxSizing: 'border-box',
                      }}
                    />
                    <button
                      className={'tax-invoice-black-btn'}
                      style={{ minWidth: '80px', height: '44px' }}
                      onClick={() => {
                        const formData = getValues();
                        setTaxInvoiceForm(formData);
                        navigate(URLS.INDUSTRY_SEARCH);
                      }}
                      type={'button'}
                    >
                      업태변경
                    </button>
                  </div>
                </InputLabel>
                <InputLabel
                  text={'종목'}
                  labelStyle={{ color: COLORS['blueContainer'] }}
                >
                  <Input register={register('dmnrItmNm')} />
                </InputLabel>
                <InputLabel
                  text={'이메일'}
                  labelStyle={{ color: COLORS['blueContainer'] }}
                >
                  <Input register={register('dmnrMchrgEmlAdr')} />
                </InputLabel>
                <InputLabel
                  text={'이메일'}
                  labelStyle={{ color: COLORS['blueContainer'] }}
                >
                  <Input register={register('dmnrMchrgEmlAdr2')} />
                </InputLabel>
              </div>
            </div>
          </div>
          <div className={'tax-invoice-divider'}></div>
          <div className={'tax-invoice-content'}>
            <div className={'tax-invoice-type-container'}>
              <InputLabel text={'작성일자'} required>
                <Controller
                  control={control}
                  name={'wrtDt'}
                  render={({ field: { value } }) => (
                    <div
                      className={'flex-row'}
                      style={{
                        gap: '10px',
                      }}
                    >
                      <ReactDatePicker
                        value={value}
                        onChange={(date) =>
                          setValue('wrtDt', date, {
                            shouldValidate: true,
                          })
                        }
                        className={'react-calendar'}
                        locale={ko}
                        ref={datePickerRef}
                      />
                      <button
                        className={'tax-invoice-black-btn'}
                        style={{
                          width: '44px',
                          height: '44px',
                        }}
                        type={'button'}
                        onClick={() => datePickerRef?.current?.setOpen(true)}
                      >
                        <img src={calendarMonth} alt={'+'} />
                      </button>
                    </div>
                  )}
                />
              </InputLabel>
            </div>
            <div className={'tax-invoice-description'}>
              <ul>
                <li>
                  아래 <b>품목</b>의 <span>월</span>은 상단 작성일자의{' '}
                  <span>월</span>이 자동 반영됩니다. 합계의 <b>계산</b>버튼은
                  금액을 공급가액과 세액으로 계산할 수 있습니다.
                </li>
                <li>품목은 최대 16개까지 추가, 삭제 가능</li>
              </ul>
            </div>
          </div>
          <div className={'tax-invoice-content'} style={{ gap: '24px' }}>
            {fields.map((field, index) => (
              <TaxInvoiceProduct
                key={field.id}
                index={index}
                onRemove={() => remove(index)}
                register={register}
                setValue={setValue}
                watch={watch}
                control={control}
                errors={errors}
              />
            ))}
            <button
              className={'tax-invoice-add-btn'}
              type={'button'}
              onClick={() => {
                if (getValues('lsatInfrBizSVOList').length < 15) {
                  append({
                    lsatSplMm: '',
                    lsatSplDd: '',
                    lsatNm: '',
                    lsatRszeNm: '',
                    lsatQty: '',
                    lsatUtprc: '',
                    lsatSplCft: '',
                    lsatTxamt: '',
                    lsatRmrkCntn: '',
                  });
                }
              }}
            >
              <img src={addBlue} alt="+" />
              품목추가
            </button>

            <div className={'tax-invoice-type-container'}>
              <InputLabel text={'종류'} required>
                <Controller
                  name="taxInvoiceTypeCd"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        name="taxInvoiceTypeCd"
                        options={taxInvoiceTypeOptions}
                        onChange={onChange}
                        value={value}
                      />
                    );
                  }}
                />
              </InputLabel>

              <Controller
                name={'recApeClCd'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioButton
                    name={'recApeClCd'}
                    options={[
                      { label: '이 금액을 청구함', value: '02' },
                      { label: '이 금액을 영수함', value: '01' },
                    ]}
                    defaultValue={value}
                    onChange={onChange}
                    labelDivContainer={{ padding: '0' }}
                  />
                )}
              />
            </div>
          </div>
          <div className={'tax-invoice-divider'}></div>
          <div className={'tax-invoice-content'}>
            <div className={'tax-invoice-type-container'}>
              {location.pathname.endsWith(TAX_INVOICE_MENU_TYPE.REGULAR) && (
                <InputLabel text={'합계금액'}>
                  <Input
                    value={
                      !lsatInfrBizSVOList
                        ? 0
                        : (
                            lsatInfrBizSVOList.reduce((sum, item) => {
                              const value = parseFloat(item.lsatSplCft) || 0;
                              return sum + value;
                            }, 0) +
                            lsatInfrBizSVOList.reduce((sum, item) => {
                              const value = parseFloat(item.lsatTxamt) || 0;
                              return sum + value;
                            }, 0)
                          ).toLocaleString()
                    }
                    disabled={true}
                    style={{
                      background: COLORS['gray0'],
                    }}
                  />
                </InputLabel>
              )}
              <InputLabel text={'공급가액'}>
                <Input
                  disabled={true}
                  value={
                    !lsatInfrBizSVOList
                      ? 0
                      : lsatInfrBizSVOList
                          .reduce((sum, item) => {
                            const value = parseFloat(item.lsatSplCft) || 0;
                            return sum + value;
                          }, 0)
                          .toLocaleString()
                  }
                  style={{
                    background: COLORS['gray0'],
                  }}
                />
              </InputLabel>
              {location.pathname.endsWith(TAX_INVOICE_MENU_TYPE.REGULAR) && (
                <InputLabel text={'세액'}>
                  <Input
                    value={
                      !lsatInfrBizSVOList
                        ? 0
                        : lsatInfrBizSVOList
                            .reduce((sum, item) => {
                              const value = parseFloat(item.lsatTxamt) || 0;
                              return sum + value;
                            }, 0)
                            .toLocaleString()
                    }
                    disabled={true}
                    style={{
                      background: COLORS['gray0'],
                    }}
                  />
                </InputLabel>
              )}
            </div>
          </div>
          <div className={'tax-invoice-content'}>
            <Button text={'발급하기'} type={'submit'} />
          </div>
        </div>
      </form>
    </Container>
  );
};

export default TaxInvoice;
