import { API_URLS, COLORS } from '@src/util/constants.ts';
import InputLabel from '@src/components/InputLabel.tsx';
import Input from '@src/components/Input.tsx';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@src/components/Button.tsx';
import Container from '@src/layouts/Container.tsx';
import DetailHeader from '@src/components/DetailHeader.tsx';
import { useMutation } from '@tanstack/react-query';
import { IApiError } from '@src/util/interfaces.ts';
import api from '@src/util/api.ts';
import { useState } from 'react';
import AddressSearchDetailDrawer from '@src/components/AddressSearchDetailDrawer.tsx';
import { useNavigate } from 'react-router-dom';

interface AddressSearchProps {}

const AddressForm = z.object({
  address: z.string(),
});

type TAddressForm = z.infer<typeof AddressForm>;

export type TAddressSearchResponse = {
  bdNm: string;
  roadAddr: string;
  jibunAddr: string;
};

type TAddressSearchApiResponse = {
  data: TAddressSearchResponse[];
};

const AddressSearch: React.FC<AddressSearchProps> = () => {
  const [addressList, setAddressList] = useState<TAddressSearchResponse[]>([]);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<TAddressSearchResponse>({
      bdNm: '',
      roadAddr: '',
      jibunAddr: '',
    });

  const { register, handleSubmit } = useForm<TAddressForm>({
    resolver: zodResolver(AddressForm),
  });

  const { mutate, isPending } = useMutation<
    TAddressSearchApiResponse,
    IApiError,
    TAddressForm
  >({
    mutationFn: (formData) => {
      return api.get(API_URLS.OPENAPI_ADDRESS, {
        params: {
          keyword: formData.address,
          confmKey: import.meta.env.ADDRESS_SEARCH_KEY,
          currentPage: 1,
          countPerPage: 100,
        },
      });
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<TAddressForm> = (data) => {
    mutate(data, {
      onSuccess: (res) => {
        setAddressList(res.data);
        console.log(res);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleCloseAndNavigateBack = () => {
    setDetailModalOpen(false);
    setTimeout(() => {
      navigate(-1);
    }, 0);
  };

  return (
    <Container
      header={<DetailHeader title={'주소조회'} />}
      modal={
        <AddressSearchDetailDrawer
          open={detailModalOpen}
          setOpen={setDetailModalOpen}
          selectedAddress={selectedAddress}
          onCloseAndNavigateBack={handleCloseAndNavigateBack}
        />
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'address-search-container'}>
          <div className={'address-search-content'}>
            <InputLabel text={'도로명 주소'} required>
              <Input
                register={register('address')}
                placeholder={'도로명을 입력하세요 (2글자 이상)'}
                isLoading={isPending}
                style={{
                  background: COLORS['gray0'],
                }}
              />
            </InputLabel>
            <Button text={'조회'} type={'submit'} isLoading={isPending} />
            {addressList.map((address, i) => (
              <div
                className={'address-search-list'}
                key={i}
                onClick={() => {
                  setSelectedAddress(address);
                  setDetailModalOpen(true);
                }}
              >
                <div className={'flex-row'}>{address.roadAddr}</div>
                <div className={'flex-row'} style={{ color: COLORS['gray4'] }}>
                  {address.jibunAddr}
                </div>
              </div>
            ))}
          </div>
        </div>
      </form>
    </Container>
  );
};

export default AddressSearch;
