import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Client from './pages/admin/client/Client.tsx';
import AdminLogin from './pages/admin/auth/AdminLogin.tsx';

function AdminApp() {
  //test
  const refreshToken = Cookies.get('refreshTokenAdmin');
  const navigate = useNavigate();

  useEffect(() => {
    if (!refreshToken) {
      navigate('/admin/login', { replace: true });
    } else {
      navigate('/admin/client', { replace: true });
    }
  }, []);

  return (
    <Routes>
      <Route path="/client" element={<Client />} />
      <Route path="/login" element={<AdminLogin />} />
    </Routes>
  );
}

export default AdminApp;
