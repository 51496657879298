import InputLabel from '@src/components/InputLabel.tsx';
import { COLORS, TAX_INVOICE_MENU_TYPE } from '@src/util/constants.ts';
import Input from '@src/components/Input.tsx';
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  FieldErrors,
} from 'react-hook-form';
import { TTaxInvoiceForm } from '@src/pages/tax-invoice/TaxInvoice.tsx';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { numberWithCommas } from '@src/util/functions.ts';

interface TaxInvoiceProductProps {
  index: number;
  onRemove: () => void;
  register: UseFormRegister<TTaxInvoiceForm>;
  setValue: UseFormSetValue<TTaxInvoiceForm>;
  watch: UseFormWatch<TTaxInvoiceForm>;
  control: Control<TTaxInvoiceForm>;
  errors: FieldErrors<TTaxInvoiceForm>;
}

const TaxInvoiceProduct: React.FC<TaxInvoiceProductProps> = ({
  index,
  onRemove,
  register,
  setValue,
  watch,
  control,
  errors,
}) => {
  const lsatSplCft = watch(`lsatInfrBizSVOList.${index}.lsatSplCft`);
  const location = useLocation();

  useEffect(() => {
    if (!isNaN(parseInt(lsatSplCft))) {
      setValue(
        `lsatInfrBizSVOList.${index}.lsatTxamt`,
        Math.floor(parseInt(lsatSplCft) * 0.1).toString(),
      );
    }
  }, [index, lsatSplCft, setValue]);

  return (
    <div className={'tax-invoice-product-container'}>
      <div className={'tax-invoice-product-header'}>
        <p>품목{index + 1}</p>
        <button
          onClick={onRemove}
          disabled={index === 0}
          style={{ color: index === 0 ? COLORS['gray4'] : COLORS['main'] }}
        >
          품목 삭제
        </button>
      </div>
      <div className={'tax-invoice-product-content'}>
        <div className={'flex-row'} style={{ gap: '20px' }}>
          <InputLabel text={'월'}>
            <Input
              register={register(`lsatInfrBizSVOList.${index}.lsatSplMm`)}
              disabled={true}
              style={{
                background: COLORS['gray0'],
                width: '100%',
                boxSizing: 'border-box',
              }}
            />
          </InputLabel>
          <InputLabel text={'일'}>
            <Input
              register={register(`lsatInfrBizSVOList.${index}.lsatSplDd`)}
              // isLoading={isPending}
              inputMode={'numeric'}
              style={{
                width: '100%',
                boxSizing: 'border-box',
              }}
            />
          </InputLabel>
        </div>

        <InputLabel text={'품목'}>
          <Input
            register={register(`lsatInfrBizSVOList.${index}.lsatNm`)}
            // isLoading={isPending}
            inputMode={'text'}
            style={{
              width: '100%',
              boxSizing: 'border-box',
            }}
          />
        </InputLabel>
        <div className={'flex-row'} style={{ gap: '20px' }}>
          <InputLabel text={'규격'}>
            <Input
              register={register(`lsatInfrBizSVOList.${index}.lsatRszeNm`)}
              // isLoading={isPending}
              style={{
                width: '100%',
                boxSizing: 'border-box',
              }}
            />
          </InputLabel>
          <InputLabel text={'수량'}>
            <Input
              register={register(`lsatInfrBizSVOList.${index}.lsatQty`)}
              // isLoading={isPending}
              inputMode={'numeric'}
              style={{
                width: '100%',
                boxSizing: 'border-box',
              }}
            />
          </InputLabel>
          <InputLabel text={'단가'}>
            <Input
              register={register(`lsatInfrBizSVOList.${index}.lsatUtprc`)}
              // isLoading={isPending}
              inputMode={'numeric'}
              style={{
                width: '100%',
                boxSizing: 'border-box',
              }}
            />
          </InputLabel>
        </div>
        <div className={'flex-row'} style={{ gap: '20px' }}>
          <InputLabel text={'공급가액'} required>
            <Controller
              name={`lsatInfrBizSVOList.${index}.lsatSplCft`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  type="text"
                  value={numberWithCommas(value)}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/,/g, '');
                    onChange(rawValue);
                  }}
                  inputMode={'numeric'}
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                  }}
                />
              )}
            />
          </InputLabel>
          {location.pathname.endsWith(TAX_INVOICE_MENU_TYPE.REGULAR) && (
            <InputLabel text={'세액'}>
              <Controller
                name={`lsatInfrBizSVOList.${index}.lsatTxamt`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="text"
                    value={numberWithCommas(value)}
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/,/g, '');
                      onChange(rawValue);
                    }}
                    inputMode={'numeric'}
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                    }}
                  />
                )}
              />
            </InputLabel>
          )}
          <InputLabel text={'비고'}>
            <Input
              register={register(`lsatInfrBizSVOList.${index}.lsatRmrkCntn`)}
              // isLoading={isPending}
              inputMode={'text'}
              style={{
                width: '100%',
                boxSizing: 'border-box',
              }}
            />
          </InputLabel>
        </div>
        {errors?.lsatInfrBizSVOList?.[index]?.lsatSplCft?.message && (
          <p className={'error-message'} style={{ marginTop: '-24px' }}>
            {errors?.lsatInfrBizSVOList?.[index]?.lsatSplCft?.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default TaxInvoiceProduct;
