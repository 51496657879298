import '@src/scss/main.scss';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Input from '@src/components/Input';
import RadioButton from '@src/components/RadioButton';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { passwordRegex, phoneRegex } from '@src/util/functions';
import api from '@src/util/api';
import {
  API_URLS,
  MANAGER_STATUS,
  URLS,
  EmailVerificationStatus,
} from '@src/util/constants';
import DetailHeader from '@src/components/DetailHeader';
import Container from '@src/layouts/Container';
import { useUserStore } from '@src/util/store';
import Modal from '@src/components/Modal';
import SideBar from '@src/components/SideBar';
import { z } from 'zod';
import InputLabel from '@src/components/InputLabel.tsx';
import { ZEmail } from '@src/util/zodSchema.ts';
import {
  TEmailRequestForm,
  TEmailVerificationForm,
  ZEmailVerification,
} from '@src/pages/auth/RegisterEmail.tsx';
import Button from '@src/components/Button.tsx';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import Snackbar from '@src/components/SnackBar.tsx';
import { zodResolver } from '@hookform/resolvers/zod';

interface ManagerProps {}

export const AddManagerFormBase = z
  .object({
    name: z.string().trim().min(1, { message: '이름을 입력해 주세요.' }),
    phone_number: z.string().regex(phoneRegex, '전화번호가 유효하지 않습니다.'),
    is_root_rights: z.string(),
    password: z.string().regex(passwordRegex, '비밀번호가 유효하지 않습니다.'),
    password_confirm: z.string(),
  })
  .extend({
    email: ZEmail,
  });

export const AddManagerFormClient = AddManagerFormBase.extend({
  emailVerification: ZEmailVerification,
}).refine((data) => data.password === data.password_confirm, {
  message: '비밀번호가 일치하지 않습니다.',
  path: ['password_confirm'],
});

export const AddManagerFormServer = AddManagerFormBase.omit({
  password_confirm: true,
  is_root_rights: true,
}).extend({
  is_root_rights: z.boolean(),
  company_id: z.number(),
});

export type TAddManagerFormClient = z.infer<typeof AddManagerFormClient>;
export type TAddManagerFormServer = z.infer<typeof AddManagerFormServer>;

const AddManager: React.FC<ManagerProps> = () => {
  const [emailVerificationStatus, setEmailVerificationStatus] = useState(
    EmailVerificationStatus.INITIAL,
  );
  const navigate = useNavigate();
  const [errorModal, setErrorModal] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [user] = useUserStore((state) => [state.user]);
  const company_id = user?.selectedCompany?.id;

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TAddManagerFormClient>({
    resolver: zodResolver(AddManagerFormClient),
    mode: 'onChange',
    defaultValues: {
      is_root_rights: 'false',
    },
  });

  const { mutate: mutateRequest, isPending: requestLoading } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TEmailRequestForm
  >({
    mutationFn: (formData) => {
      return api.post(API_URLS.SEND_EMAIL, formData);
    },
  });

  const {
    mutate: mutateVerify,
    isPending: verifyLoading,
    isError: isVerifyError,
    error: verifyError,
  } = useMutation<IApiPOSTSuccess, IApiError, TEmailVerificationForm>({
    mutationFn: (formData) => {
      return api.post(API_URLS.SEND_EMAIL_COMPLETE, {
        email: formData.email,
        value: formData.value,
      });
    },
  });

  const { mutate } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TAddManagerFormServer
  >({
    mutationFn: (formData) => {
      return api.post(API_URLS.MANAGER, formData);
    },
  });

  const emailValue = watch('email');
  const emailVerificationValue = watch('emailVerification');

  const verifyEmailRequest = () => {
    setEmailVerificationStatus(EmailVerificationStatus.REQUESTED);
    mutateRequest(
      {
        email: emailValue,
      },
      {
        onSuccess: (response) => {
          console.log(response);
          setSnackbarOpen(true);
        },
        onError: (error) => {
          setErrorModal(error?.response?.data?.message);
        },
        onSettled: () => {
          setTimeout(() => setSnackbarOpen(false), 3000);
        },
      },
    );
  };

  const verifyEmail = () => {
    mutateVerify(
      {
        email: emailValue,
        value: emailVerificationValue,
      },
      {
        onSuccess: (response) => {
          console.log(response);
          setEmailVerificationStatus(EmailVerificationStatus.VERIFIED);
        },
        onError: (error) => {
          setErrorModal(error?.response?.data?.message);
          console.log(error);
        },
      },
    );
  };

  const onSubmit: SubmitHandler<TAddManagerFormClient> = (data) => {
    const formData = {
      name: data.name,
      email: data.email,
      password: data.password,
      phone_number: data.phone_number.replace(/-/g, ''),
      is_root_rights: data.is_root_rights === 'true',
      company_id: company_id ?? 0,
    };

    mutate(formData, {
      onSuccess: (response) => {
        console.log(response);
        setModalOpen(true);
      },
      onError: (error) => {
        setErrorModal(error?.response?.data?.message);
      },
    });
  };

  return (
    <Container
      header={<DetailHeader title={'담당자 등록'} />}
      sideBar={<SideBar />}
      modal={
        <>
          <Snackbar
            message="이메일 인증이 요청되었습니다."
            open={snackbarOpen}
            type={'info'}
          />
          <Modal
            isOpen={isModalOpen}
            onOk={() => {
              setModalOpen(false);
              navigate(URLS.MY_PAGE + `?query=${MANAGER_STATUS.MANAGER}`);
            }}
            title={'담당자 등록 완료'}
            content={'담당자가 등록되었습니다.'}
            okButton={'확인'}
            onClose={() => {
              setModalOpen(false);
            }}
          />
        </>
      }
    >
      <form className="add-manager-container" onSubmit={handleSubmit(onSubmit)}>
        <div className={'add-manager-content'}>
          <div className={'single-input-container'}>
            <InputLabel text={'권한'} required>
              <Controller
                name={'is_root_rights'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioButton
                    name={'is_root_rights'}
                    options={[
                      { label: '총괄 관리자', value: 'true' },
                      { label: '일반 관리자', value: 'false' },
                    ]}
                    defaultValue={value}
                    onChange={onChange}
                  />
                )}
              />
            </InputLabel>
          </div>
          <div className={'single-input-container'}>
            <InputLabel
              text={'이름'}
              required
              error={
                errors.name && (
                  <div className="error-message">{errors.name?.message}</div>
                )
              }
            >
              <Input
                register={register('name')}
                name={'name'}
                placeholder={'이름을 입력하세요'}
              />
            </InputLabel>
          </div>
          <div className="email-input-container">
            <InputLabel text={'이메일'} required>
              <div className="email-verify-container">
                <Input
                  placeholder="이메일"
                  register={register('email')}
                  isLoading={false}
                  disabled={
                    emailVerificationStatus === EmailVerificationStatus.VERIFIED
                  }
                />
                <Button
                  onClick={() => {
                    verifyEmailRequest();
                  }}
                  text={emailVerificationStatus}
                  customClassName="button-secondary"
                  disabled={
                    requestLoading ||
                    !emailValue ||
                    !!errors.email ||
                    emailVerificationStatus === EmailVerificationStatus.VERIFIED
                  }
                  style={{
                    width: '96px',
                    alignSelf: 'stretch',
                    whiteSpace: 'nowrap',
                  }}
                />
              </div>
            </InputLabel>
            {emailVerificationStatus === EmailVerificationStatus.REQUESTED && (
              <div className="email-verify-container">
                <Input
                  placeholder="인증번호 입력"
                  register={register('emailVerification')}
                  isLoading={false}
                />
                <Button
                  onClick={() => {
                    verifyEmail();
                  }}
                  text={'인증번호 확인'}
                  customClassName="button-secondary"
                  disabled={
                    !emailVerificationValue || !!errors.emailVerification
                  }
                  isLoading={verifyLoading}
                  type={'button'}
                  style={{
                    width: '96px',
                    alignSelf: 'stretch',
                    whiteSpace: 'nowrap',
                  }}
                />
              </div>
            )}
            {isVerifyError && (
              <div className="error-message">
                {verifyError?.response.data.message}
              </div>
            )}
          </div>
          <InputLabel
            text={'비밀번호'}
            required
            description={
              '영문자(대,소문자), 숫자를 포함하여 최소 8자 이상 15자 이하로 작성 해야 합니다.'
            }
            error={
              errors.password ? (
                <p className={'error-message'}>{errors.password.message}</p>
              ) : errors.password_confirm ? (
                <p className={'error-message'}>
                  {errors.password_confirm.message}
                </p>
              ) : (
                <></>
              )
            }
          >
            <Input
              placeholder="비밀번호"
              register={register('password')}
              isLoading={false}
              type={'password'}
            />
            <Input
              placeholder="비밀번호 확인"
              register={register('password_confirm')}
              isLoading={false}
              type={'password'}
            />
          </InputLabel>

          <InputLabel
            text={'휴대폰 번호'}
            required
            error={
              errors.phone_number && (
                <p className={'error-message'}>{errors.phone_number.message}</p>
              )
            }
          >
            <Input
              placeholder="휴대폰 번호"
              register={register('phone_number')}
              isLoading={false}
              inputMode={'tel'}
            />
          </InputLabel>

          <div className={'button-bottom-default-wrapper w-full'}>
            <div className={'button-size-wrapper'}>
              <Link
                className={'button-left-default'}
                to={URLS.MY_PAGE + `?query=${MANAGER_STATUS.MANAGER}`}
              >
                취소
              </Link>
              <button className={'button-right-default'} type={'submit'}>
                확인
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={errorModal != ''}
        onOk={() => {
          setErrorModal('');
          // navigate("/login", { replace: true });
        }}
        title={'ERROR'}
        content={errorModal}
        okButton={'확인'}
        onClose={() => {
          setErrorModal('');
        }}
      />
    </Container>
  );
};

export default AddManager;
